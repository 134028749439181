import { IonButton, IonCol, IonContent, IonDatetime, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonToggle } from '@ionic/react';
import CustomDate from './common/CustomDate';
import './ExploreContainer.css';
import API from '../utils/API';
import { useRef } from 'react';
import { useState } from 'react';
import SearchUser from './common/SearchUser';
interface ContainerProps {
    name: string;
}

const CreateDepartments: React.FC<ContainerProps> = ({ name }) => {
    const [dept, setDept] = useState({
        deptName: '',
    });

    const handleInputChange = (event: any) => {
        setDept((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const createNewDepartment = () => {
        API().post('/enterprise/createDepartment', { "data": dept }).then((data) => {

        }, e => {
            console.log(e);
        });
    }
    return (
        <>
            <IonRow>
                <IonCol size-md="12" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Department Name</IonLabel>
                        <IonInput name="holidayName" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="d-flex justify-content-center mt-4">
                <IonCol size="12" size-sm >
                    <IonButton shape="round" fill="outline" onClick={createNewDepartment}>Create Department</IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default CreateDepartments;
