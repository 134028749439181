import { IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import { CustomRoutes } from '../../../utils/CustomRoutes';

const JobsPage: React.FC = () => {
    const history = useHistory();
    const goToViewInterviews = () => {
        history.push(CustomRoutes.viewInterview);
    }
    let orgPageTitle = "Watch Interviews"
    return (
        <IonPage>
            
        </IonPage>
    );
};

export default JobsPage;
