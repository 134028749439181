import { IonBackButton, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { createOutline, pin, walk, warning, wifi, wine } from 'ionicons/icons';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CreateHolidays from '../../components/holidays/CreateHolidays';
import ExploreContainer from '../../components/ExploreContainer';
import { CustomRoutes } from '../../utils/CustomRoutes';

const EmployeeLeavesPage: React.FC = () => {
  const { name } = useParams<{ name: string; }>();
  const history = useHistory();

  const routeChange = (path: string) => {
    history.push(path);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-md="6" size-sm="12" size-xs="12" size='12'>
              <IonCard>
                <IonItemGroup>
                  <IonItemDivider>
                    <IonLabel color="primary">January</IonLabel>
                  </IonItemDivider>
                  <IonItem>
                    <IonLabel className="w-25">Jan 1</IonLabel>
                    <IonLabel className="w-75">Happy New year</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Jan 17</IonLabel>
                    <IonLabel>Martin Luther King Jr. Day</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Jan 31
                      <IonBadge className="mb-2" color="warning">Optional</IonBadge>
                    </IonLabel>
                    <IonLabel>Kansas Day</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Jan 31
                      <IonBadge className="mb-2" color="warning">Optional</IonBadge>
                    </IonLabel>
                    <IonLabel>Kansas Day</IonLabel>
                  </IonItem>
                </IonItemGroup>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default EmployeeLeavesPage;