import { Col, Container, Row, Spinner } from "reactstrap";

const ComponentError: React.FC<{ show: boolean }> = ({ show }) => {
  return (
    <>{
      show ?
        <div className="d-flex justify-content-center my-5">
          Something went wrong. Please refresh page or contact Us
        </div> : null
    }
    </>
  );
};

export default ComponentError;