import { IonAccordion, IonAccordionGroup, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import './../ExploreContainer.css';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import API from '../../utils/API';
import CustomDate from '../common/CustomDate';
import SearchUser from '../common/SearchUser';
import SelectBuyer from '../common/SelectBuyer';
import SelectCostCenter from '../common/SelectCostCenter';
import SelectProject from '../common/SelectProject';
import SelectSupplier from '../common/SelectSupplier';
import FileUpload from '../common/FileUpload';
import moment from 'moment';
interface ContainerProps {
    name: string;
}

const EmergencyContact: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [employee, setEmployee] = useState<any>({
        _id: id,
    });

    const handleInputChange = (event: any) => {
        setEmployee((values: any) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const addEmployeePOC = () => {
        console.log(employee);
        API().post('/employee/saveEmployee', { "req": employee }).then((data: any) => {
            console.log(data);
            setEmployee((values: any) => ({
                ...values,
                id: data.data._id
            }));
            history.push(`/admin/create-employee/${data.data.data._id}`);
        }, e => {
            console.log(e);
        });
    }

    useEffect(() => {
        (async () => {
            if (id) {
                const { data, status, statusText, headers, request, config } = await API().post('/employee/getEmployeeByID', { "req": employee });
                console.log(data);
                console.log(data + "1" + status + "1" + statusText + "1" + headers + "1" + request + "1" + config);
                await setEmployee((values: any) => ({
                    ...values,
                    ...data
                }));
            }
        })();
    }, [id])

    return (
        <IonGrid>
            <IonRow>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">First Name</IonLabel>
                        <IonInput name="emergencyContactFirstName" value={employee?.emergencyContactFirstName} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">Last Name</IonLabel>
                        <IonInput name="emergencyContactLastName" value={employee?.emergencyContactLastName} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">Phone</IonLabel>
                        <IonInput name="emergencyContactPhone" value={employee?.emergencyContactPhone} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput name="emergencyContactEmail" value={employee?.emergencyContactEmail} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">Address</IonLabel>
                        <IonInput name="emergencyContactAddressFirstLine" value={employee?.emergencyContactAddressFirstLine} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">City</IonLabel>
                        <IonInput name="emergencyContactCity" value={employee?.emergencyContactCity} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">State</IonLabel>
                        <IonInput name="emergencyContactState" value={employee?.emergencyContactState} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">Zip</IonLabel>
                        <IonInput name="emergencyContactZIP" value={employee?.emergencyContactZIP} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">Country</IonLabel>
                        <IonInput name="emergencyContactCountry" value={employee?.emergencyContactCountry} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
            </IonRow>

            <IonRow className="d-flex justify-content-center mt-4">
                <IonCol size-md="4" size="12" size-sm="6" >
                    <IonButton shape="round" fill="outline" onClick={addEmployeePOC}>Save Employee</IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default EmergencyContact;
