import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { addCircle, addCircleOutline, arrowForwardCircle, createOutline, pin, walk, warning, wifi, wine } from 'ionicons/icons';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { CustomRoutes } from '../../../utils/CustomRoutes';

const SuppliersCard: React.FC = () => {
    const { name } = useParams<{ name: string; }>();
    const history = useHistory();

    const routeChange = (path: string) => {
        history.push(path);
    }
    return (
        <IonCol size-md="4" size-sm="6" size-xs="12">
            <IonCard>
                <IonCardHeader color="primary">
                    <IonCardTitle className="">Suppliers
                        {/* <IonIcon className="" icon={arrowForwardCircle}></IonIcon> */}
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="d-flex my-2" onClick={() => { routeChange(CustomRoutes.dashboardHolidays) }}>
                    <IonIcon className="" size='large' color="primary" icon={arrowForwardCircle}></IonIcon>
                    <IonLabel className="pt-1" style={{ fontSize: '16px' }} color="primary">Dashboard</IonLabel>
                </IonCardContent>
                <IonRow className="mb-2">
                    <IonCol onClick={() => { routeChange(CustomRoutes.createSupplier) }}>
                        <IonLabel className="mx-4 py-2" style={{ fontSize: '18px' }} color="primary">
                            <IonIcon className="" font-size="16" color="primary" icon={addCircleOutline} />Add</IonLabel>
                    </IonCol>
                    <IonCol onClick={() => { routeChange(CustomRoutes.viewSupplier) }}>
                        <IonLabel className="mx-3 py-2" style={{ fontSize: '18px' }} color="primary">
                            <IonIcon className="" font-size="16" color="primary" icon={createOutline} slot="start" />View</IonLabel>
                    </IonCol>
                </IonRow>
            </IonCard>
        </IonCol>
    );
};
export default SuppliersCard;