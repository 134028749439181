import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonReorder, IonReorderGroup, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import ComponentLoading from '../common/ComponentLoading';
import { add, createOutline, reorderFourOutline, trashOutline } from 'ionicons/icons';
import Toaster from '../../utils/Toaster';
import useAxios from 'axios-hooks';
import CreatableSelect from 'react-select/creatable';
import { LanguageTech } from '../../utils/LanguageTech';
import NoDataPresent from '../common/NoDataPresent';
import API from '../../utils/API';
import { ToasterMessage } from '../../utils/ToasterMessages';
import { useHistory } from 'react-router';
import Select from 'react-select'
import { CustomRoutes } from '../../utils/CustomRoutes';
interface ContainerProps {
    name: string;
    selectedHoliday: any;
    reloadView: boolean;
}


const ViewAllMyQuestions: React.FC<{}> = () => {
    let orgPageTitle = "View All Global Questions";
    const userId = sessionStorage.getItem('userId');
    const history = useHistory();
    const [showToaster] = Toaster();
    const [skill, setSkill] = useState();
    const [interviewSet, setInterviewSet] = useState();

    const routeChange = (path: string) => {
        history.push(path);
    }

    const [fetchedInterviewQuestions, setFetchedInterviewQuestions] = useState({
        questions: []
    });

    const [questionSet, setQuestionSet] = useState([{
        "languagesTools": [],
        "_id": "6330933b733830002356d9d9",
        "interviewQuestionsSetIDs": [
            "6305cf5fae4501c639a9fb4b",
            "6305cf5fae4501c639a9fb4c",
            "6305cf5fae4501c639a9fb4a"
        ],
        "interviewQuestionsSetName": "HTML Set",
        "createdby": "",
        "createdAt": "2022-09-25T17:43:23.235Z",
        "updatedAt": "2022-09-25T17:43:23.235Z",
        "__v": 0
    }]);


    const onToolsChange = (event: any) => {
        setSkill(event.value);
        console.log(event);
        let newBody = {
            req: {
                primaryLang: event.value.toLowerCase(),
                ids: [],
            }
        };
        refetch({ data: newBody });
    };

    const onDelete = (event: any) => {
        // refetch({ data: event });
        API().delete('/interviewQuestions/deleteInterviewQuestions',
            {
                data: {
                    req: {
                        ...event
                    }
                }
            }
        ).then((data) => {
            refetch();
            showToaster(ToasterMessage.interviewQuestion.deletedQuestionSuccess, 'success', 'top');
        })
    };

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/interviewQuestions/searchMyQuestions',
        method: 'POST',
        data: {
            req: {
                primaryLang: '',
                ids: [],
            }
        }

    });

    const [{ data: allQSets, loading: loadingAllQSets, error: errorallQSets }, refetchAllQSets] = useAxios({
        url: 'interviewQuestionsSet/getAllMyInterviewQuestionsSets',
        method: 'GET'
    });

    useEffect(() => {
        data && setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...data]
        }));
    }, [data]);

    useEffect(() => {
        allQSets && setQuestionSet([...allQSets]);
        setQuestionSet((prevItems) => {
            return prevItems.map((item) => {
                return { ...item, value: item.interviewQuestionsSetName, label: item.interviewQuestionsSetName };
            });
        });
        console.log(questionSet);
    }, [allQSets]);

    return (
        <IonContent fullscreen>
            <IonHeader collapse="condense">
                <IonToolbar color="primary">
                    <IonTitle size="large">{orgPageTitle}</IonTitle>
                    <IonTitle>Done</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonItemGroup>
                <IonRow className='m-2'>
                    {/* <IonCol class='col-lg-6'>
                        <label color='primary'>
                            Pick an Interview Set OR
                        </label>
                        <Select

                            className='py-2 my-2'
                            styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            placeholder="Select an interview Set"
                            options={[...questionSet]}
                            isMulti={false}
                            // defaultValue={[...interview.languagesTools]}
                            onChange={onInterviewSetChange}
                        />
                    </IonCol> */}
                    <IonCol class='col-lg-6'>
                        <label color='primary'>
                            Select Tools and Technologies
                        </label>

                        <Select
                            className='py-2 my-2'
                            styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            placeholder="Select Tools and Technologies"
                            options={[...LanguageTech.languages]}
                            isMulti={false}
                            // defaultValue={[...interview.languagesTools]}
                            onChange={onToolsChange}
                        />
                    </IonCol>
                    <IonCol class='col-lg-6'>
                        <IonFab className='m-3' style={{ 'display': 'flex', position: 'absolute', right: '0', cursor: 'pointer' }} onClick={() => { history.push(CustomRoutes.addQuestion) }}>
                            <IonText style={{ 'display': 'flex', marginTop: '1em', marginRight: '1em' }} color={'primary'}>Create Question</IonText>
                            <IonFabButton>
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </IonFab>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <hr className='m-0'></hr>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol class='col-lg-12' className='mr-4 pr-4'>
                        {/* {(loading || loadingAllQSets) &&
                            < ComponentLoading show={true}></ComponentLoading>
                        }
                        {fetchedInterviewQuestions?.questions && fetchedInterviewQuestions.questions.map((interviewQuestion: any, index: any) => {
                            return (
                                <IonItem key={interviewQuestion._id}>
                                    <IonLabel className="w-75">{interviewQuestion.question}</IonLabel>
                                </IonItem>
                            );
                        })} */}
                        {(loading || loadingAllQSets) ?
                            < ComponentLoading show={true}></ComponentLoading>
                            : <> {fetchedInterviewQuestions?.questions?.length > 0
                                ? fetchedInterviewQuestions.questions.map((interviewQuestion: any, index: any) => {
                                    return (
                                        <IonItem key={interviewQuestion._id}>
                                            {userId == interviewQuestion.createdby && <IonIcon color='primary' icon={createOutline} slot="start" onClick={() => routeChange(`/admin/edit-my-question/${interviewQuestion._id}`)} />}
                                            <IonLabel className="w-75">{interviewQuestion.question}</IonLabel>
                                            <IonBadge slot="start">{interviewQuestion.primaryLang}</IonBadge>
                                            <IonBadge slot="end">{interviewQuestion.difficulty}</IonBadge>
                                            {userId == interviewQuestion.createdby && <IonIcon color='warning' icon={trashOutline} slot="end" onClick={() => onDelete(interviewQuestion)} />}

                                        </IonItem>
                                    );
                                }) : <NoDataPresent show={true} />}</>
                        }
                    </IonCol>
                </IonRow>
            </IonItemGroup>
        </IonContent >
    );
};

export default ViewAllMyQuestions;
