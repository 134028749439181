import { IonAccordion, IonAccordionGroup, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import './../ExploreContainer.css';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import API from '../../utils/API';
import CustomDate from '../common/CustomDate';
import SearchUser from '../common/SearchUser';
import SelectBuyer from '../common/SelectBuyer';
import SelectCostCenter from '../common/SelectCostCenter';
import SelectProject from '../common/SelectProject';
import SelectSupplier from '../common/SelectSupplier';
import Select from 'react-select';
import moment from 'moment';
import { DepartmentType, EmploymentTypes, WorkAuthorization } from '../../utils/LanguageTech';
interface ContainerProps {
    name: string;
}

const JobPositionDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [employee, setEmployee] = useState<any>({
        _id: id,
        // startDate: '',
        // firstName: '',
        // lastName: '',
        // email: '',
        // phone: '',
        // addressFirstLine: '',
        // city: '',
        // state: '',
        // zip: '',
        // country: '',

        // salary: '',
        // perHourBillingRate: ''

        // employmentType: '',
        // departmentType: '',

        // managerID: '',
        // managerName: '',

        // projectId: '',
        // projectName: '',

        // buyerId: '',
        // buyerName: '',

        // supplierId: '',
        // supplierName: '',

        // costCenterId: '',
        // costCenterName: '',

        // emergencyContactFirstName: '',
        // emergencyContactLastName: '',
        // emergencyContactEmail: '',
        // emergencyContactPhone: '',
        // emergencyContactAddressFirstLine: '',
        // emergencyContactAddressSecondLine: '',
        // emergencyContactCity: '',
        // emergencyContactState: '',
        // emergencyContactZIP: '',
        // emergencyContactCountry: '',
    });

    const getDate = (date: string) => { // the callback. Use a better name
        console.log(date);
        setEmployee((values: any) => ({
            ...values,
            'startDate': date,
        }));
    };

    const getEmployee = (manager: any) => { // the callback. Use a better name
        setEmployee((values: any) => ({
            ...values,
            'managerId': manager?._id,
            'managerName': manager?.firstName + ', ' + manager?.lastName
        }));
    };

    const getProject = (project: any) => { // the callback. Use a better name
        setEmployee((values: any) => ({
            ...values,
            'projectId': project?._id,
            'projectName': project?.name
        }));
    };

    const getBuyer = (buyer: any) => { // the callback. Use a better name
        console.log(buyer);
        setEmployee((values: any) => ({
            ...values,
            'buyerId': buyer?._id,
            'buyerName': buyer?.name,

        }));
    };

    const getSupplier = (supplier: any) => { // the callback. Use a better name
        setEmployee((values: any) => ({
            ...values,
            'supplierId': supplier?._id,
            'supplierName': supplier?.name
        }));
    };

    const getCostCenter = (costCenter: any) => { // the callback. Use a better name
        setEmployee((values: any) => ({
            ...values,
            'costCenterId': costCenter?._id,
            'costCenterName': costCenter?.name,
        }));
    };

    const handleInputChange = (event: any) => {
        setEmployee((values: any) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const handleDropDownChange = (event: any, name: any) => {
        setEmployee((values: any) => ({
            ...values,
            [name]: event,
        }));
    };

    const fileUploadEvent = (publicURL: any) => {
        console.log(publicURL);
        setEmployee((values: any) => ({
            ...values,
            'profileImageUrl': publicURL.url,
        }));
    }
    // const createNewOrganization = () => {
    //     console.log(employee);
    //     API().post('/employee/saveEmployee', { "req": employee }).then((data: any) => {
    //         setEmployee((values:any) => ({
    //             ...values,
    //             id: data._id
    //         }));
    //     }, e => {
    //         console.log(e);
    //     });
    // }

    // const addEmployeeAddress = () => {
    //     console.log(employee);
    //     API().post('/employee/saveEmployee', { "req": employee }).then((data) => {

    //     }, e => {
    //         console.log(e);
    //     });
    // }
    const addEmployeePOC = () => {
        console.log(employee);
        API().post('/employee/saveEmployee', { "req": employee }).then((data: any) => {
            console.log(data);
            setEmployee((values: any) => ({
                ...values,
                id: data.data._id
            }));
            history.push(`/admin/create-employee/${data.data.data._id}`);
        }, e => {
            console.log(e);
        });
    }

    useEffect(() => {
        (async () => {
            if (id) {
                const { data, status, statusText, headers, request, config } = await API().post('/employee/getEmployeeByID', { "req": employee });
                console.log(data);
                console.log(data + "1" + status + "1" + statusText + "1" + headers + "1" + request + "1" + config);
                await setEmployee((values: any) => ({
                    ...values,
                    ...data
                }));
            }
        })();
    }, [id])

    return (
        <IonGrid>
            <IonRow>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonLabel position="stacked">Employment Type</IonLabel>
                    <Select
                        className='pt-2 z-index-10'
                        options={EmploymentTypes}
                        value={{ label: employee.employmentType }}
                        onChange={(newValue: any) => handleDropDownChange(newValue.label, 'employmentType')}
                    />
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonLabel position="stacked">Department</IonLabel>
                    <Select
                        className='pt-2 z-index-10'
                        options={DepartmentType}
                        value={{ label: employee.departmentType }}
                        onChange={(newValue: any) => handleDropDownChange(newValue.label, 'departmentType')}
                    />
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonLabel position="stacked">Work Authorization</IonLabel>
                    <Select
                        className='pt-2 z-index-10'
                        options={WorkAuthorization}
                        value={{ label: employee.workAuthorization }}
                        onChange={(newValue: any) => handleDropDownChange(newValue.label, 'workAuthorization')}
                    />
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">Salary</IonLabel>
                        <IonInput name="salary" value={employee?.salary} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="6">
                    <IonItem>
                        <IonLabel position="floating">Per Hour Billing Rate</IonLabel>
                        <IonInput name="perHourBillingRate" value={employee?.perHourBillingRate} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>

                <IonCol size-md="4" size="12" size-sm="6">
                    <SearchUser name={employee.managerName || 'Select Manager'} getEmployee={getEmployee} ></SearchUser>
                </IonCol>

                <IonCol size-md="4" size="12" size-sm="6">
                    <SelectProject name={employee.projectName || 'Select Project'} getProject={getProject}></SelectProject>
                </IonCol>

                <IonCol size-md="4" size="12" size-sm="6">
                    {employee?.buyerName ? <SelectBuyer name={employee?.buyerName || 'Select Buyer OR Client'} getBuyer={getBuyer} ></SelectBuyer> :
                        <SelectBuyer name={'Select Buyer OR Client'} getBuyer={getBuyer} ></SelectBuyer>}
                </IonCol>

                <IonCol size-md="4" size="12" size-sm="6">
                    {employee.supplierName ? <SelectSupplier name={employee.supplierName || 'Select Supplier OR Consultancy'} getSupplier={getSupplier}></SelectSupplier> :
                        <SelectSupplier name={'Select Supplier OR Consultancy'} getSupplier={getSupplier}></SelectSupplier>}
                </IonCol>

                <IonCol size-md="4" size="12" size-sm="6">
                    <SelectCostCenter name={employee.costCenterName || 'Select Cost Center'} getCostCenter={getCostCenter}></SelectCostCenter>
                </IonCol>
            </IonRow>
            <IonRow className="d-flex justify-content-center mt-4">
                <IonCol size="12" size-sm >
                    <IonButton shape="round" fill="outline" onClick={addEmployeePOC}>Save Employee</IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default JobPositionDetails;
