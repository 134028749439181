import { IonButton, IonCol, IonContent, IonDatetime, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow } from '@ionic/react';
//import './ExploreContainer.css';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import API from '../../utils/API';
import Toaster from '../../utils/Toaster';
import { OrganizationMessages } from '../../utils/ToasterMessages';
import { useHistory, useParams } from 'react-router';
import { CustomRoutes } from '../../utils/CustomRoutes';
interface ContainerProps {
    name: string;
}

const CreateBuyerSupplier: React.FC<ContainerProps> = ({ name }) => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    let customRoute = CustomRoutes.viewBuyer;
    console.log(name);
    let isBuyer = false;
    let isSupplier = false;
    if (name === 'Supplier') {
        customRoute = CustomRoutes.viewSupplier;
        isSupplier = true;
    } else if (name === 'Buyer') {
        isBuyer = true;
    }
    const [showToaster] = Toaster();
    const [orgData, setOrgData] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        type: name,

        isBuyer: isBuyer,
        isSupplier: isSupplier,

        adminFirstName: "",
        adminLastName: "",
        adminPhone: "",
        adminEmail: "",
        adminAddress: "",
        adminCountry: "",
        adminCity: "",
        adminState: "",
        adminZip: "",
    });

    const getDate = (date: string) => { // the callback. Use a better name
        setOrgData((values) => ({
            ...values,
            'startDate': date,
        }));
    };

    const handleInputChange = (event: any) => {
        setOrgData((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const createNewOrganization = () => {
        console.log(orgData);
        if (id) {
            API().put('/client/updateClientBS', { "req": orgData }).then((data) => {
                showToaster(OrganizationMessages.createdSuccessfully, 'success', 'bottom');
                history.push(customRoute);
            }, e => {
                console.log(e);
                showToaster(OrganizationMessages.error, 'warning', 'bottom');
            });
        } else {
            API().post('/client/saveClientBS', { "req": orgData }).then((data) => {
                showToaster(OrganizationMessages.createdSuccessfully, 'success', 'bottom');
                history.push(customRoute);
            }, e => {
                console.log(e);
                showToaster(OrganizationMessages.error, 'warning', 'bottom');
            });
        }
    }

    const getClientBS = () => {
        API().post('/client/getClientBSByID', {
            req: {
                "id": id
            }
        }).then(data => {
            setOrgData(data.data)
            console.log(orgData);
        })
    }

    useEffect(() => {
        getClientBS();
    }, [])

    return (
        <IonContent>
            {/* <IonInfiniteScroll>
                <IonInfiniteScrollContent> */}
            <IonGrid>
                <IonRow>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Name</IonLabel>
                            <IonInput name="name" value={orgData.name} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Phone</IonLabel>
                            <IonInput name="phone" value={orgData.phone} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Email</IonLabel>
                            <IonInput name="email" value={orgData.email} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Address</IonLabel>
                            <IonInput name="address" value={orgData.address} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} City</IonLabel>
                            <IonInput name="city" value={orgData.city} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} State</IonLabel>
                            <IonInput name="state" value={orgData.state} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Zip</IonLabel>
                            <IonInput name="zip" value={orgData.zip} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Country</IonLabel>
                            <IonInput name="country" value={orgData.country} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="m-3 pt-3" size-sm>
                        {/* <IonItem> */}
                        {/* <IonItemDivider> */}
                        <IonLabel color="primary">
                            Point Of Contact Details
                        </IonLabel>
                        {/* </IonItemDivider> */}
                        {/* </IonItem> */}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">First Name</IonLabel>
                            <IonInput name="adminFirstName" value={orgData.adminFirstName} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Last Name</IonLabel>
                            <IonInput name="adminLastName" value={orgData.adminLastName} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Phone</IonLabel>
                            <IonInput name="adminPhone" value={orgData.adminPhone} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Email</IonLabel>
                            <IonInput name="adminEmail" value={orgData.adminEmail} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Address</IonLabel>
                            <IonInput name="adminAddress" value={orgData.adminAddress} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">City</IonLabel>
                            <IonInput name="adminCity" value={orgData.adminCity} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">State</IonLabel>
                            <IonInput name="adminState" value={orgData.adminState} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Zip</IonLabel>
                            <IonInput name="adminZip" value={orgData.adminZip} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Country</IonLabel>
                            <IonInput name="adminCountry" value={orgData.adminCountry} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow className="d-flex justify-content-center mt-4">
                    <IonCol size="12" size-sm >
                        <IonButton shape="round" fill="outline" onClick={createNewOrganization}>Create {name}</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            {/* </IonInfiniteScrollContent>
            </IonInfiniteScroll> */}
        </IonContent>
    );
};

export default CreateBuyerSupplier;
