import CustomDate from '../common/CustomDate';
import './../ExploreContainer.css';
import API from '../../utils/API';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import SearchUser from '../common/SearchUser';
import BasicTable from '../common/tables/BasicTable';
import GridTable from '@nadavshaar/react-grid-table';
import '../react-table-reusable/'
import { IonCol, IonFab, IonFabButton, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import { add, addCircleOutline, addOutline, createOutline, pencilOutline, personCircleOutline, trashOutline } from 'ionicons/icons';
import { CustomRoutes } from '../../utils/CustomRoutes';
import { useHistory } from 'react-router';
import moment from 'moment';
interface ContainerProps {
    name: string;
}

const ViewProjectTask: React.FC<ContainerProps> = ({ name }) => {
    const [projectTasks, setProjectTask] = useState([]);
    const history = useHistory();

    function handleEditClick(data: any): void {
        history.push(`/admin/edit-project/${data._id}`)
    }

    const getProjectTask = () => {
        API().get('/projectTask/getAllProjectTasks').then((data) => {
            console.log(data.data);
            setProjectTask(data.data);
        }, e => {
            console.log(e);
        });

        console.log(projectTasks);
    }

    // custom cell component
    const Username = ({ tableManager, value, field, data, column, colIndex, rowIndex }: any) => {
        return (
            <>
                <IonRow>
                    <IonCol>
                        <div className='rgt-text-truncate' style={{ marginLeft: 10, cursor: 'pointer' }}>{value}</div>
                    </IonCol>
                    <IonCol>
                        <IonIcon color='primary' size='medium' style={{ fontSize: '1.5rem', cursor: 'pointer' }} icon={createOutline} onClick={() => handleEditClick(data)} />
                    </IonCol>
                </IonRow>
            </>
        )
    };

    const DateCell = ({ tableManager, value, field, data, column, colIndex, rowIndex }: any) => {
        const formattedDate = moment(value).format('MM-DD-YYYY');
        return (
            <div>{formattedDate}</div>
        );
    }

    const columns = [
        {
            id: 1,
            field: 'title',
            label: 'Project Title',
            cellRenderer: Username
        },
        // { id: 'delete', name: 'del', render: ({ row }: any) => <button onClick={() => handleDeleteClick(row)}>Delete</button> },
        {
            id: 2,
            field: 'status',
            label: 'Project Status',
        },
        {
            id: 2.5,
            field: 'buyerClientName',
            label: 'Buyer or Client',
        },
        {
            id: 3,
            field: 'startDate',
            label: 'Projet Start Date',
            sort: ({ a, b, isAscending }: any) => {
                let aa = a.split('/').reverse().join(),
                    bb = b.split('/').reverse().join();
                return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
            },
            cellRenderer: DateCell
        },
        {
            id: 4,
            field: 'endDate',
            label: 'Project End Date',
            cellRenderer: DateCell
            // getValue: ({ value }: any) => value.x + value.y
        }
    ];



    useEffect(() => {
        getProjectTask();

    }, []);
    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol offset="9" className='mb-4 pb-4'>
                        <IonFab style={{ 'display': 'flex' }} onClick={() => { history.push(CustomRoutes.createProjectTask) }}>
                            <IonText style={{ 'display': 'flex', marginTop: '1em', marginRight: '1em' }} color={'primary'}>Add Project</IonText>
                            <IonFabButton>
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </IonFab>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className='mt-4'>
                        <GridTable columns={columns} rows={projectTasks}></GridTable></IonCol>
                </IonRow>
            </IonGrid>
            {/* <IonIcon icon={addCircleOutline} style={{ fontSize: '3rem', color: '#ff3860' }} /> */}
            {/* <BasicTable name={'View Projects'} headers={[
                { key: 'projectTaskName', label: 'projectTaskName' },
                { key: 'key2', label: 'label2' },
            ]} tableRows={[...projectTasks]}
            ></BasicTable> */}
        </>
    );
};

export default ViewProjectTask;
