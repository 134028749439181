import { IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory, useLocation, useParams } from 'react-router';
import AddQuestionToInterview from '../../components/interview/AddQuestionToInterview';
import ViewInterview from '../../components/interview/ViewInterview';
import ViewInterviewV2 from '../../components/interview/ViewInterviewV2';
import WatchInterviews from '../../components/interview/WatchInterviews';
import { CustomRoutes } from '../../utils/CustomRoutes';

const AddQuestionToInterviewPage: React.FC = () => {
  const history = useHistory();
  const goToViewInterviews = () => {
    history.push(CustomRoutes.viewInterview);
  }
  let orgPageTitle = "Watch Interviews"
  return (
    <IonPage>
     <AddQuestionToInterview></AddQuestionToInterview>
    </IonPage>
  );
};

export default AddQuestionToInterviewPage;
