import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonRange, IonRouterLink, IonRow, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import API from '../../../utils/API';
import CustomDate from '../../common/CustomDate';
import SearchUser from '../../common/SearchUser';
import SelectBuyer from '../../common/SelectBuyer';
import SelectCostCenter from '../../common/SelectCostCenter';
import SelectProject from '../../common/SelectProject';
import SelectSupplier from '../../common/SelectSupplier';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw } from 'draft-js'
import 'draft-js/dist/Draft.css';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { LanguageTech } from '../../../utils/LanguageTech';
import { CustomRoutes } from '../../../utils/CustomRoutes';
import { RangeValue } from '@ionic/core';

interface ContainerProps {
    name: string;
}

const CreateJob: React.FC<ContainerProps> = ({ name }) => {
    const params: any = useParams();
    const history = useHistory();
    const empID = params?.empID;
    // const [salary, setSalary] = useState<{ lower: number, upper: number }>({ lower: 1000, upper: 100000 });
    // const [perHourRate, setPerHourRateState] = useState<{ lower: number, upper: number }>({ lower: 50, upper: 100 });
    const richTextEditorChange = (event: any) => {
        // const plainText = event.getCurrentContent().getPlainText() // for plain text
        const rteContent = convertToRaw(event.getCurrentContent()) // for rte content with text formating
        console.log(rteContent);
        rteContent && setJob((values: any) => ({
            ...values,
            'detailedJobDescription': JSON.stringify(rteContent),
        }));
    };
    const defaultTheme = createTheme();

    Object.assign(defaultTheme, {
        overrides: {
            MUIRichTextEditor: {
                root: {
                    margin: 2,
                    padding: "0.75em",
                    width: "100%",
                    borderRadius: "0.75em",
                    border: "1px solid #757575",

                },
                editor: {
                    innerHeight: 10,
                    minHeight: "4em",
                    borderTop: "1px solid #757575",
                }
            }
        }
    })

    const [job, setJob] = useState<any>({
        // jobTitle:String,
        // uniqueTitle:String,
        // detailedJobDescription
        // role:String,
        // recruiterId
        // orgId
        // jobTags:[],
        // jobType:String,
        // minExperience:String,
        // maxExperience:String,
        minSalary: 50000,
        maxSalary: 100000,
        minPerHourRate: 50,
        maxPerHourRate: 100,
        // region:String,
        // location:String,
        // city:String,
        // state:String,
        // country:String,
        // keyResponsibilities:String,
        // requiredSkills:String,
        // department:String,
        // companyLocation:String,
        // companyName:String,
        // companyID:String,
        // //Accepting Application, conducting Interviews, Hold, Closed, 
        // jobStatus:String,
        // jobAnalytics:String
        // jobSkills:String
    });

    const setSalaryRange = (sal: any) => {
        console.log(sal);
        if (sal.lower !== job.minSalary || sal.upper !== job.maxSalary) {
            setJob((values: any) => ({
                ...values,
                minSalary: sal.lower,
                maxSalary: sal.upper
            }));
        }
    }

    const setPerHourRate = (hourRate: any) => {
        console.log(hourRate);
        if (hourRate.lower !== job.minPerHourRate || hourRate.upper !== job.maxPerHourRate) {
            setJob((values: any) => ({
                ...values,
                minPerHourRate: hourRate.lower,
                maxPerHourRate: hourRate.upper
            }));
        }
    }

    const handleInputChange = (event: any) => {
        setJob((values: any) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const addjobPOC = () => {
        console.log(job);
        API().post('/job/savejob', { "req": job }).then((data: any) => {
            setJob((values: any) => ({
                ...values,
                id: data.data._id
            }));
        }, e => {
            console.log(e);
        });
    }

    const onToolsChange = (event: any) => {
        let jobSkillsArray = event.map((item: any) => { return item.value.toLowerCase(); });
        setJob((values: any) => ({
            ...values,
            jobSkills: jobSkillsArray
        }))
    };
    useEffect(() => {
        (async () => {
            if (empID) {
                const { data, status, statusText, headers, request, config } = await API().post('/job/getjobByID', { "req": job });
                console.log(data);
                console.log(data + "1" + status + "1" + statusText + "1" + headers + "1" + request + "1" + config);
                await setJob((values: any) => ({
                    ...values,
                    ...data
                }));
            }
        })();
        console.log(job);
    }, [empID])

    return (
        <IonContent>
            {/* <IonInfiniteScroll>
                <IonInfiniteScrollContent> */}
            <IonGrid>
                <IonRow>
                    <IonCol md-size="4" size="12" size-sm="4">
                        <IonItem>
                            <IonLabel position="floating">Job Title</IonLabel>
                            <IonInput name="jobTitle" value={job?.jobTitle} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol md-size="4" size="12" size-sm="4">
                        <IonItem>
                            <IonLabel position="floating">Job Location</IonLabel>
                            <IonInput name="jobLocation" value={job?.jobLocation} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol md-size="4" size="12" size-sm="4">
                        <IonItem className="pt-3">
                            <IonLabel>Job Type</IonLabel>
                            <IonSelect value={job.employmentType || 'FullTime'} okText="Select" name="employmentType" cancelText="Dismiss" onIonChange={handleInputChange}>
                                <IonSelectOption value="FullTime">Full Time</IonSelectOption>
                                <IonSelectOption value="W2">W2</IonSelectOption>
                                <IonSelectOption value="PartTime">Part Time</IonSelectOption>
                                <IonSelectOption value="Contract">Contract</IonSelectOption>
                                {/* <IonSelectOption value="IndependentContractor">Independent Contractor</IonSelectOption> */}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    {/* <IonCol md-size="4" size="12" size-sm="4">
                        <IonItem>
                            <IonLabel position="floating">Salary Range or Per Hour Wage</IonLabel>
                            <IonInput name="payRangeDetails" value={job?.payRangeDetails} onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol> */}

                    <IonCol md-size="12" size="12" size-sm="12">
                        <CreatableSelect
                            styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            className="mt-3"
                            placeholder="Select Tools and Technologies"
                            options={[...LanguageTech.languages]}
                            isMulti={true}
                            onChange={onToolsChange}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>

                    <IonCol md-size="4" size="12" size-sm="4">
                        <IonItem className="my-3">
                            <IonLabel>Department Type</IonLabel>
                            <IonSelect value={job.departmentType} okText="Select" name="departmentType" cancelText="Dismiss" onIonChange={handleInputChange}>
                                <IonSelectOption value="DEPT_IT">IT</IonSelectOption>
                                <IonSelectOption value="DEPT_HR">HR</IonSelectOption>
                                <IonSelectOption value="DEPT_SALES">Sales</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        {/* <IonLabel>{job.jobType == '' ? 'job Type' : job.jobType }</IonLabel> */}
                    </IonCol>
                    <IonCol md-size="4" size="12" size-sm="4">
                        <IonItem className="my-3">
                            <IonLabel>Rmore or Onsite</IonLabel>
                            <IonSelect value={job.isRemoteType} okText="Select" name="isRemoteType" cancelText="Dismiss" onIonChange={handleInputChange}>
                                <IonSelectOption value="REMOTE">Remote</IonSelectOption>
                                <IonSelectOption value="ONSITE">Onsite</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                    <IonCol md-size="4" size="12" size-sm="4">
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Allow Easy Apply</IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol md-size="4" size="12" size-sm="4" className='pl-2'>
                        <>
                            <IonLabel className='pl-2'>
                                Salary
                                <IonText> Min: {job.minSalary} $</IonText>
                                <IonText> Max: {job.maxSalary} $</IonText>
                            </IonLabel>
                            <IonRange
                                className='mt-2'
                                step={1000}
                                onIonChange={({ detail }) => setSalaryRange(detail.value)}
                                dualKnobs={true}
                                min={1000}
                                max={1000000}
                                value={{
                                    lower: job.minSalary,
                                    upper: job.maxSalary,
                                }}
                            ></IonRange>
                        </>
                    </IonCol>
                    <IonCol md-size="4" size="12" size-sm="4" className='pl-2'>
                        <>
                            <IonLabel className='pl-2'>
                                Per Hour
                                <IonText> Min: {job.minPerHourRate} $</IonText>
                                <IonText> Max: {job.maxPerHourRate} $</IonText>
                            </IonLabel>
                            <IonRange
                                className='mt-2'
                                step={1}
                                onIonChange={({ detail }) => setPerHourRate(detail.value)}
                                dualKnobs={true}
                                min={1}
                                max={1000}
                                value={{
                                    lower: job.minPerHourRate,
                                    upper: job.maxPerHourRate,
                                }}
                            ></IonRange>
                        </>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-sm="12">
                        <IonText className='ml-4' color="">
                            <h5>Job Position Detailed Description</h5>
                            <a color='primary'>List of Examples</a>
                        </IonText>
                        <ThemeProvider theme={defaultTheme}>
                            <MUIRichTextEditor
                                onChange={richTextEditorChange}
                                label="Please enter your Job Description here with Roles, Summary, Responsibilities, Qualifications, Mandatory Skills and Good To Have skills "
                            />
                        </ThemeProvider>
                    </IonCol>
                </IonRow>

                <IonRow className="d-flex justify-content-center mt-4">
                    <IonCol size="12" size-sm >
                        <IonButton shape="round" fill="outline" onClick={() => { history.push(CustomRoutes.viewAllJobs) }}>View My Jobs</IonButton>
                        <IonButton shape="round" fill="outline" onClick={addjobPOC}>Create job</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            {/* </IonInfiniteScrollContent>
            </IonInfiniteScroll> */}
        </IonContent >
    );
};

export default CreateJob;
