export let LanguageTech = {
    "languages": [
        { value: "HTML", label: "HTML", id: 1 },
        { value: "CSS", label: "CSS", id: 2 },
        { value: "JS", label: "JavaScript", id: 3 },
        { value: "TypeScript", label: "TypeScript", id: 4 },
        { value: "Bootstrap", label: "Bootstrap", id: 5 },
        { value: "Angular", label: "Angular", id: 6 },
        { value: "React", label: "React", id: 7 },
        { value: "Redux", label: "Redux", id: 8 },
        { value: "Axios", label: "Axios", id: 9 },
        { value: "JQuery", label: "JQuery", id: 10 },
        { value: "SpringBoot", label: "SpringBoot", id: 11 },
        { value: "SpringMVC", label: "SpringMVC", id: 11 },
        { value: "Angular1", label: "Angular1", id: 11 },
        { value: "Angular2+", label: "Angular2+", id: 11 },
    ]
}

export const difficultyLevel = {
    "levels": [
        { value: "1", label: "1", id: 1 },
        { value: "2", label: "2", id: 2 },
        { value: "3", label: "3", id: 3 },
        { value: "4", label: "4", id: 4 },
        { value: "5", label: "5", id: 5 },
    ]
}

export const ProjectType = [
    { value: "Not Started", label: "Not Started", id: 1 },
    { value: "In Progress", label: "In Progress", id: 2 },
    { value: "On Hold", label: "On Hold", id: 3 },
    { value: "Completed", label: "Completed", id: 4 }
]

export const DepartmentType = [
    { "label": "Marketing", "value": "marketing", "id": 1 },
    { "label": "Sales", "value": "sales", "id": 2 },
    { "label": "Human Resources", "value": "hr", "id": 3 },
    { "label": "Finance", "value": "finance", "id": 4 },
    { "label": "Information Technology", "value": "it", "id": 5 },
    { "label": "Customer Service", "value": "customer-service", "id": 6 },
    { "label": "Operations", "value": "operations", "id": 7 },
    { "label": "Production", "value": "production", "id": 8 },
    { "label": "Quality Assurance", "value": "quality-assurance", "id": 9 },
    { "label": "Research and Development", "value": "r&d", "id": 10 },
    { "label": "Legal", "value": "legal", "id": 11 },
    { "label": "Public Relations", "value": "pr", "id": 12 },
    { "label": "Facilities Management", "value": "facilities-management", "id": 13 },
    { "label": "Logistics", "value": "logistics", "id": 14 },
    { "label": "Training and Development", "value": "training", "id": 15 }
]

export const EmploymentTypes = [
    { label: 'Full-time', value: 'full-time', id: 1 },
    { label: 'Part-time', value: 'part-time', id: 2 },
    { label: 'Contract', value: 'contract', id: 3 },
    { label: 'Temporary', value: 'temporary', id: 4 },
    { label: 'Seasonal', value: 'seasonal', id: 5 },
    { label: 'Internship', value: 'internship', id: 6 },
    { label: 'Apprenticeship', value: 'apprenticeship', id: 7 },
    { label: 'Freelance', value: 'freelance', id: 8 },
    { label: 'Remote', value: 'remote', id: 9 },
    { label: 'On-call', value: 'on-call', id: 10 }
]

export const WorkAuthorization = [
    { value: 'us_citizen', label: 'US citizen', id: 1 },
    { value: 'permanent_resident', label: 'Permanent resident', id: 2 },
    { value: 'h1b_visa', label: 'H-1B visa', id: 3 },
    { value: 'l1_visa', label: 'L-1 visa', id: 4 },
    { value: 'o1_visa', label: 'O-1 visa', id: 5 },
    { value: 'tn_visa', label: 'TN visa', id: 6 },
    { value: 'e1_visa', label: 'E-1 visa', id: 7 },
    { value: 'e2_visa', label: 'E-2 visa', id: 8 },
    { value: 'e3_visa', label: 'E-3 visa', id: 9 },
    { value: 'f1_visa_opt', label: 'F-1 visa (with OPT)', id: 10 },
    { value: 'j1_visa_work_auth', label: 'J-1 visa (with work authorization)', id: 11 },
    { value: 'm1_visa_practical_training', label: 'M-1 visa (with practical training)', id: 12 },
    { value: 'b1_visa_business_visitor', label: 'B-1 visa (with business visitor status)', id: 13 },
    { value: 'asylum', label: 'Asylum', id: 14 },
    { value: 'refugee_status', label: 'Refugee status', id: 15 },
    { value: 'daca', label: 'Deferred Action for Childhood Arrivals (DACA)', id: 16 },
    { value: 'tps', label: 'Temporary Protected Status (TPS)', id: 17 },
    { value: 't_visa_trafficking_victim', label: 'Victim of Trafficking (T visa)', id: 18 },
    { value: 'u_visa_crime_victim', label: 'Victim of Crime (U visa)', id: 19 },
];
