import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { addCircle, addCircleOutline, arrowForwardCircle, createOutline, pin, walk, warning, wifi, wine } from 'ionicons/icons';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import BuyersCard from '../../components/common/cards/BuyersCard';
import CostCentersCard from '../../components/common/cards/CostCentersCard';
import DepartmentsCard from '../../components/common/cards/DepartmentsCard';
import EmailCentersCard from '../../components/common/cards/EmailCentersCard';
import EmployeesCard from '../../components/common/cards/EmployeesCard';
import HolidaysCard from '../../components/common/cards/HolidaysCard';
import InterviewCard from '../../components/common/cards/InterviewCard';
import JobsCard from '../../components/common/cards/JobsCard';
import LeavesCard from '../../components/common/cards/LeavesCard';
import ProjectsCard from '../../components/common/cards/ProjectsCard';
import SuppliersCard from '../../components/common/cards/SuppliersCard';
import { CustomRoutes } from '../../utils/CustomRoutes';

const SuperAdminIndex: React.FC = () => {
  const { name } = useParams<{ name: string; }>();
  const history = useHistory();

  const routeChange = (path: string) => {
    history.push(path);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <EmployeesCard></EmployeesCard>
            <HolidaysCard></HolidaysCard>
            <DepartmentsCard></DepartmentsCard>
            <ProjectsCard></ProjectsCard>
            <SuppliersCard></SuppliersCard>
            <BuyersCard></BuyersCard>
            <CostCentersCard></CostCentersCard>
            <JobsCard></JobsCard>
            <LeavesCard></LeavesCard>
            <InterviewCard></InterviewCard>
            
            <EmailCentersCard></EmailCentersCard>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default SuperAdminIndex;