import { IonAccordion, IonAccordionGroup, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonDatetime, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import './../ExploreContainer.css';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import API from '../../utils/API';
import CustomDate from '../common/CustomDate';
import SearchUser from '../common/SearchUser';
import SelectBuyer from '../common/SelectBuyer';
import SelectCostCenter from '../common/SelectCostCenter';
import SelectProject from '../common/SelectProject';
import SelectSupplier from '../common/SelectSupplier';
import FileUpload from '../common/FileUpload';
import moment from 'moment';
import { add } from 'ionicons/icons';
import { CustomRoutes } from '../../utils/CustomRoutes';
interface ContainerProps {
    name: string;
}

const ExperienceDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [experiences, setExpoeriences] = useState([
        {
            position: '',
            company: '',
            experienceType: '',
            startDate: '',
            endDate: '',
            address: {
                street: '',
                city: '',
                state: '',
                country: '',
                zip: '',
            },
            description: '',
            achievements: '',
            responsibilities: '',
            supervisorName: '',
            supervisorPosition: '',
            supervisorEmail: '',
            supervisorPhone: '',
        }]);

    const handleAddExpoerience = () => {
        setExpoeriences([...experiences,
        {
            position: '',
            company: '',
            experienceType: '',
            startDate: '',
            endDate: '',
            address: {
                street: '',
                city: '',
                state: '',
                country: '',
                zip: '',
            },
            description: '',
            achievements: '',
            responsibilities: '',
            supervisorName: '',
            supervisorPosition: '',
            supervisorEmail: '',
            supervisorPhone: '',
        }]);
    };

    const removeExpoerience = (indexToBeRemoved: number) => {
        const updatedExpoerience = experiences.filter((item, index) => index !== indexToBeRemoved);
        setExpoeriences(updatedExpoerience);
    }

    const handleInputChange: any = (event: any, index: any) => {
        console.log(event);
        console.log(index);
        const { name, value } = event.target;
        const newExpoeriences: any = [...experiences];
        newExpoeriences[index][name] = value;
        setExpoeriences(newExpoeriences);
    };

    const saveExpoerience = () => {
        console.log(experiences);
        API().post('/experience/saveExpoerience', { "req": experiences }).then((data: any) => {
            console.log(data);
            // setExpoerience((values: any) => ({
            //     ...values,
            //     id: data.data._id
            // }));
            history.push(`/admin/create-experience/${data.data.data._id}`);
        }, e => {
            console.log(e);
        });
    }

    useEffect(() => {
        (async () => {
            if (id) {
                const { data, status, statusText, headers, request, config } = await API().post('/experience/getExpoerienceByID', { "req": experiences });
                console.log(data);
                console.log(data + "1" + status + "1" + statusText + "1" + headers + "1" + request + "1" + config);
                // await setExpoerience((values: any) => ({
                //     ...values,
                //     ...data
                // }));
            }
        })();
    }, [id])

    return (<>
        <IonGrid>
            {experiences.map((experience, index) => (
                <IonCard>
                    <IonCardHeader>
                        <IonRow>
                            <IonCol size-sm="9">
                                {/* <IonItem> */}
                                {/* <IonItemDivider> */}
                                <IonCardTitle className='pt-2' color="primary">
                                    Experience Details
                                </IonCardTitle>
                                {/* </IonItemDivider> */}
                                {/* </IonItem> */}
                            </IonCol>
                            <IonCol size-sm="3">
                                <IonButton shape="round" fill="outline" onClick={() => removeExpoerience(index)}>Remove</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>

                    <IonRow className='m-2'>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Position</IonLabel>
                                <IonInput
                                    name="position"
                                    value={experience.position}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Company</IonLabel>
                                <IonInput
                                    name="company"
                                    value={experience.company}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Employment Type</IonLabel>
                                <IonInput
                                    name="experienceType"
                                    value={experience.experienceType}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="stacked">Start Date</IonLabel>
                                <IonInput
                                    type="date"
                                    name="startDate"
                                    value={experience.startDate}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="stacked">End Date</IonLabel>
                                <IonInput
                                    type="date"
                                    name="endDate"
                                    value={experience.endDate}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className='m-2'>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Street</IonLabel>
                                <IonInput
                                    name="street"
                                    value={experience.address.street}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">City</IonLabel>
                                <IonInput
                                    name="city"
                                    value={experience.address.city}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">State</IonLabel>
                                <IonInput
                                    name="state"
                                    value={experience.address.state}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Country</IonLabel>
                                <IonInput
                                    name="country"
                                    value={experience.address.country}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Zip Code</IonLabel>
                                <IonInput
                                    name="zip"
                                    value={experience?.address?.zip}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>

                    </IonRow>
                    <IonRow className='m-2'>
                        <IonCol size-md="12" size="12" size-sm="12">
                            <IonItem>
                                <IonLabel position="floating">Responsibilities</IonLabel>
                                <IonTextarea
                                    name="responsibilities"
                                    value={experience?.responsibilities}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Supervisor Name</IonLabel>
                                <IonInput
                                    name="supervisorName"
                                    value={experience?.supervisorName}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Supervisor Title</IonLabel>
                                <IonInput
                                    name="supervisorPosition"
                                    value={experience?.supervisorPosition}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Supervisor Email</IonLabel>
                                <IonInput
                                    name="supervisorEmail"
                                    type="email"
                                    value={experience?.supervisorEmail}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Supervisor Phone</IonLabel>
                                <IonInput
                                    name="supervisorPhone"
                                    type="tel"
                                    value={experience?.supervisorPhone}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonCard>
            ))}
            <IonRow className='m-2'>
                <IonCol>
                    <IonButton shape="round" fill="outline" onClick={() => saveExpoerience()}>Save Expoerience</IonButton>
                </IonCol>
                <IonCol push="4" className='mb-4 pb-4'>
                    <IonFab onClick={() => handleAddExpoerience()}>
                        <IonFabButton>
                            <IonIcon icon={add}></IonIcon>
                        </IonFabButton>
                    </IonFab>
                </IonCol>
            </IonRow>
        </IonGrid>

    </>);
};

export default ExperienceDetails;
