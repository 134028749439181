import { IonButton, IonCol, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import ComponentLoading from '../common/ComponentLoading';
import { add, trashOutline } from 'ionicons/icons';
import Toaster from '../../utils/Toaster';
import useAxios from 'axios-hooks';
import { useHistory } from 'react-router';
import { CustomRoutes } from '../../utils/CustomRoutes';
import API from '../../utils/API';
import ModalWithProps from '../common/YesNoModal';
import { ToasterMessage } from '../../utils/ToasterMessages';
import moment from 'moment';

const MyQuestionsSets: React.FC<{}> = () => {
    const userId = sessionStorage.getItem("userId");
    let orgPageTitle = "Question Sets Created By Me";
    const history = useHistory();
    const [showToaster] = Toaster();
    const interviewSetName: any = useRef();

    const searchInterviewQuestionSet = async () => {
        console.log(interviewSetName);
        // let inteviewQuestionSetIds = interview.interviewQuestions.map((question: any) => { return question._id.toString() });
    }

    const deleteQuestionSet = (id) => {
        API().delete('interviewQuestionsSet/deleteInterviewQuestionsSet', {
            data: {
                req: {
                    id: id
                }
            }
        }).then(data => {
            showToaster(ToasterMessage.myQuestionSet.deletedMyQuestionSetSuccess, 'success', 'top');
            refetchAllQSets();
        }).catch(e => {
            showToaster(ToasterMessage.myQuestionSet.deletedMyQuestionSetUnsuccessful, 'warning', 'top');
        })
    }

    const [{ data: allMyQSets, loading: loadingAllQSets, error: errorallQSets }, refetchAllQSets] = useAxios({
        url: 'interviewQuestionsSet/getAllInterviewQuestionsSets',
        method: 'GET'
    });

    useEffect(() => {

    }, [allMyQSets]);
    if (loadingAllQSets) {
        return (
            <ComponentLoading show={true}></ComponentLoading>
        )
    }

    return (
        <IonContent fullscreen>
            {/* <IonHeader collapse="condense">
                <IonToolbar color="primary">
                    <IonTitle size="large">{orgPageTitle}</IonTitle>
                    <IonTitle>Done</IonTitle>
                </IonToolbar>
            </IonHeader> */}
            <IonItemGroup>
                <IonRow class='m-2'>
                    <IonCol class='col-lg-6'>
                        <IonItem className='mb-2'>
                            <IonLabel position="floating">Interview Questions Set Name</IonLabel>
                            <IonInput name={"interviewSetName"} type="text" ref={interviewSetName} ></IonInput>
                        </IonItem>
                        <IonButton expand="block" shape="round" className='mt-3' fill="outline" color='primary' onClick={searchInterviewQuestionSet}>Search Interview Question Set</IonButton>
                    </IonCol>
                    <IonCol class='col-lg-6'>
                        <IonFab className='m-3' style={{ 'display': 'flex', position: 'absolute', right: '0', cursor: 'pointer' }} onClick={() => { history.push(CustomRoutes.createInterviewQuestionsSet) }}>
                            <IonText style={{ 'display': 'flex', marginTop: '1em', marginRight: '1em' }} color={'primary'}>Create Question Set</IonText>
                            <IonFabButton>
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </IonFab>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <hr className='m-0'></hr>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol class='col-lg-12'>
                        {allMyQSets.map((myQSet: any, index: any) => {
                            return (
                                <IonItem key={myQSet._id}>
                                    {/* <IonLabel className="w-25">{holiday.holidayDate}
                                            {holiday.optional ? <IonBadge className="mb-2" color="warning">Optional</IonBadge> : null}
                                        </IonLabel> */}
                                    {/* {userId == interviewQuestion.createdby && <IonButton onClick={() => selectQuestion(interviewQuestion)} style={{ marginRight: '0.5rem' }}>Clone</IonButton>} */}

                                    <IonLabel className="w-75">{myQSet.interviewQuestionsSetName}</IonLabel>
                                    <IonLabel className="w-75">{myQSet._id}</IonLabel>
                                    <IonLabel className="w-75">{moment(myQSet.createdAt).format('MM-DD-YYYY')}</IonLabel>
                                    <IonButton onClick={() => history.push(CustomRoutes.createInterviewQuestionsSet + '/' + myQSet._id)} style={{ marginRight: '0.5rem' }}>View</IonButton>
                                    <IonButton onClick={() => history.push(CustomRoutes.createInterviewQuestionsSet + '/' + myQSet._id)} style={{ marginRight: '0.5rem' }}>Edit</IonButton>
                                    {/* <IonIcon style={{ cursor: 'pointer' }} icon={trashOutline} color='warning' onClick={() => deleteQuestionSet(interviewQuestion._id)}></IonIcon> */}
                                    <ModalWithProps
                                        messageTitle='Delete Question Set'
                                        messageBody='Are You Sure Want To Delete'
                                        ele={<IonIcon style={{ cursor: 'pointer' }} icon={trashOutline} color='warning' ></IonIcon>}
                                        onConfirm={() => deleteQuestionSet(myQSet._id)}
                                    ></ModalWithProps>
                                </IonItem>
                            );
                        })}
                    </IonCol>
                </IonRow>
            </IonItemGroup>
        </IonContent >
    );
};

export default MyQuestionsSets;
