export const superAdminEmails = {
    supportEmail: 'superadmin3434@yopmail.com'
};

export const orgEmails = {
    supportEmail: 'support3434@yopmail.com'
};

export const ToasterMessage = {
    setPassword: {
        passwordMismatch: 'Password and Confirm Password should be same',
        emptyPassword: 'Password is Empty',
        emptyConfirmPassword: 'Confirm Password is Empty',
        emptyEmail: 'Email is Empty',
        emptyConfirmEmail: 'Confirm Email is Empty',
        emptyUserEmail: 'User Email is Empty',
        incorrectEmail: 'Please check your email',
        passwordLengthError: 'Password length should be more than 8',
        oneLowerCase: 'password should have alteast one lowercase alphabet',
        oneUpperCase: 'password should have alteast one uppercase alphabet',
        oneSpecialCharecter: 'password should have alteast one special Character',
        updatedSuccessfully: 'Password updated successfully, Please login with new password',
    },
    register: {
        verifyEmail: "Please verify your email before Login",
        verificationDone: "Email Verified Successfully, Please Login"
    },
    login: {
        checkPassword: 'Please check your password once',
    },
    passwordReset: {
        pleseCheckEmail: 'Please check your email for password reset instructions'
    },
    holidays: {
        createdHolidaySuccess: 'Create new holiday successfully',
        createdHolidayFailure: 'Unable to create Holiday, Please try Again',
        deletedSuccess: 'Holiday deleted Successfully',
        deletedUnsuccessful: 'Holiday not deleted, you can reach support team'

    },
    interview: {
        createdInterviewSuccess: 'Created Interview Successfully',
        createdInterviewFailure: 'Unable to create Interview, Please try Again',
        changedStatusSuccess: 'Interview Status Changed to',
        deletedSuccess: 'Interview deleted Successfully',
        deletedUnsuccessful: 'Interview not deleted, you can reach support team',
        copiedURL: 'Candidates Interview URL copied to Clipboard Successfully',
        validation: {
            candidateNameRequired: "Please enter Candidate Name",
            candidateEmail: "Please enter Candidate Email",
            candidate: "Please enter Candidate Name",
            candidateNameRequired: "Please enter Candidate Name",
            candidateNameRequired: "Please enter Candidate Name",
        }
    },
    interviewStatus: {
        APPROVED: 'approved',
        REJECTED: 'rejected',
        SHORTLISTED: 'shortlisted',
        STARTED: 'started',
        NOT_YET_STARTED: 'Not Yet Started',
        ENDED: 'ended',
    },
    interviewStatusColor: {
        'approved': 'success',
        'rejected': 'danger',
        'shortlisted': 'warning',
        'started': '',
        'Not Yet Started': 'secondary',
        'ended': 'primary',
    },
    myQuestionSet: {
        createdMyQuestionSetSuccess: 'Created My Question Set Successfully',
        createdMyQuestionSetFailure: 'Unable to create My Question Set, Please try Again',
        deletedMyQuestionSetSuccess: 'My Question Set deleted Successfully',
        deletedMyQuestionSetUnsuccessful: 'My Question Set not deleted, you can reach support team'
    },
    interviewQuestion: {
        createdInterviewQuestionSuccess: 'Created Interview Question Successfully',
        createdInterviewQuestionFailure: 'Unable to create Interview Question, Please try Again',
        deletedQuestionSuccess: 'Interview Question deleted Successfully',
        updateQuestionSuccess: 'Interview Question updated Successfully',
        deletedQuestionUnsuccessful: 'Interview Question not deleted, you can reach support team'
    },
    job: {
        createdJobSuccess: 'Created Job Successfully',
        createdJobFailure: 'Unable to create Job, Please try Again',
        deletedSuccess: 'Job deleted Successfully',
        deletedUnsuccessful: 'Job not deleted, you can reach support team'
    }
};

export const OrganizationMessages = {
    orgCreated: 'Organization successfully created, Please ask concerned person to set up password',
    orgCreationError: 'Organization creation failure, Please take a screen shot and forward to ' + orgEmails.supportEmail,
    createdSuccessfully: 'Created Successfully',
    error: 'Some unexpected error occurred, please try again or reach support' + orgEmails.supportEmail,
};