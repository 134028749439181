import { IonButton, IonCol, IonContent, IonDatetime, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow } from '@ionic/react';
import useAxios from 'axios-hooks';
import { METHODS } from 'http';
//import './ExploreContainer.css';
import { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { EDIT_INTERVIEW, DELETE_INTERVIEW } from '../../../redux/interview/interviewTypes';
import API from '../../../utils/API';
import { CustomRoutes } from '../../../utils/CustomRoutes';
import Toaster from '../../../utils/Toaster';
import { ToasterMessage } from '../../../utils/ToasterMessages';
import BasicCards from '../../common/tables/BasicCards';
import JobsCards from '../../common/tables/JobsCards';
import SearchingTable from '../../common/tables/SearchingTable';
interface ContainerProps {
    name: string;
}

const SearchJobsComponent: React.FC<ContainerProps> = ({ name }) => {
    console.log(name);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showToaster] = Toaster()

    const [searchText, setSearchText] = useState("");
    // const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [state, setState] = useState(Date.now());
    const getSearchText = (searchText: string) => { // the callback. Use a better name
        console.log(searchText);
        setCurrentPage(1);
        setSearchText(searchText);
        refetch();
    };

    const pageChanged = (pageNumber: number) => {
        console.log(pageNumber);
        setCurrentPage(pageNumber);
    }

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/job/searchJobs', method: 'POST', data: {
            "req": {
                "searchText": searchText,
                "employmentType": "",
                "status": "",
                "skills": [],
                page: currentPage,
                limit: 10
            }
        }
    });

    const deleteInterview = (id: string) => {
        API().delete('/job/deleteJob', {
            data: {
                "req": {
                    "id": id
                }
            }
        }).then(
            (data => {
                showToaster(ToasterMessage.job.deletedSuccess, 'success', 'top');
                refetch();
            })
        ).catch((err) => {
            showToaster(ToasterMessage.job.deletedUnsuccessful, 'warning', 'top');
        });
    }


    const selectedRow = (data: any) => {
        if (data.type == 'edit') {
            dispatch({ type: EDIT_INTERVIEW, payload: data.data });
            history.push(CustomRoutes.createInterview);
        } else if (data.type == "cancel") {
            console.log(data.data._id);
            dispatch({ type: DELETE_INTERVIEW, payload: data.data });
            deleteInterview(data.data._id);
        }

        console.log(data);
    }

    // const getAllinterviews = () => {

    //     // API().post('/client/searchClientByName', {
    //     //     "req": {
    //     //         "name": searchText,
    //     //         page: currentPage,
    //     //         limit: 3
    //     //     }
    //     // }).then((data) => {
    //     //     console.log(data.data);
    //     //     setinterviews(data.data.data);
    //     //     setTotalPages(getTotalPages(data.data.totalCount, data.data.limit));
    //     // }, e => {
    //     //     console.log(e);
    //     // });
    // }


    useEffect(() => {
        setState(Date.now());
    }, [data?.totalCount]);

    return (
        <IonContent>
            {/* <IonInfiniteScroll>
                <IonInfiniteScrollContent> */}
            <IonGrid>
                <IonRow>
                    <IonCol size="12" size-sm="12">
                        <SearchingTable searchPlaceholder={"Search Jobs"} getSearchText={getSearchText}></SearchingTable>
                        {data?.data.length && <>
                            <JobsCards
                                key={state}
                                name={'View Projects'}
                                searchPlaceholderText={'Search Jobs'}
                                headers={[
                                    { key: 'Job Title', label: 'jobTitle' },
                                    { key: 'candidate name', label: 'candidateName' },
                                    { key: 'job role', label: 'jobRole' },
                                    // { key: 'languages|tools', label: 'languagesTools' },
                                    { key: 'edit', label: 'Select' },
                                    { key: 'cancel', label: 'Select' },
                                ]}
                                tableRows={data?.data}
                                limit={data?.limit}
                                currentPageProp={currentPage}
                                totalCount={data?.totalCount}
                                pageChanged={pageChanged}
                                isLoading={loading}
                                hasError={error}
                                selectedData={selectedRow}

                            ></JobsCards>
                        </>
                        }
                    </IonCol>
                </IonRow>
            </IonGrid>
            {/* </IonInfiniteScrollContent>
            </IonInfiniteScroll> */}
        </IonContent >
    );
};

export default SearchJobsComponent;
