import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonReorder, IonReorderGroup, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import ComponentLoading from '../common/ComponentLoading';
import { reorderFourOutline } from 'ionicons/icons';
import Toaster from '../../utils/Toaster';
import useAxios from 'axios-hooks';
import CreatableSelect from 'react-select/creatable';
import { LanguageTech } from '../../utils/LanguageTech';
import { useHistory } from 'react-router';
import { CustomRoutes } from '../../utils/CustomRoutes';
import API from '../../utils/API';
import { url } from 'inspector';
interface ContainerProps {
    name: string;
    selectedHoliday: any;
    reloadView: boolean;
}


const ViewAllGlobalQuestionSets: React.FC<{}> = () => {
    let orgPageTitle = "Add Questions To Interview";
    const history = useHistory();
    const [showToaster] = Toaster();
    const interviewSetName: any = useRef();
    const [interview, setInterview] = useState({
        interviewQuestions: [],
        languagesTools: []
    });
    const [fetchedInterviewQuestions, setFetchedInterviewQuestions] = useState({
        questions: []
    });

    const [interviewQuestionsStore, setinterviewQuestionsStore] = useState({
        questions: []
    });

    const searchInterviewQuestionSet = async () => {
        console.log(interviewSetName);
        let inteviewQuestionSetIds = interview.interviewQuestions.map((question: any) => { return question._id.toString() });
        API().post('/interviewQuestionsSet/saveInterviewQuestionsSet', {
            req: {
                interviewQuestionsSetName: interviewSetName.current.value,
                interviewQuestionsSetIDs: inteviewQuestionSetIds,
                languagesTools: interview.languagesTools,
                "createdby": sessionStorage.getItem('userId'),
                "updatedby": sessionStorage.getItem('userId')
            }
        }).then((data: any) => {
            console.log(data);
        }).catch((e: any) => {
            console.log(e);
        })

    }

    const selectQuestion = (interviewQuestion: any) => {
        setInterview((values: any) => ({
            ...values,
            interviewQuestions: [...values.interviewQuestions, interviewQuestion]
        }));
        setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...values.questions.filter(
                (question: any) => { return question._id != interviewQuestion._id }
            )]
        }));
        // console.log(fetchedInterviewQuestions);
    }

    const onToolsChange = (event: any) => {
        let toolsArray = event.map((item: any) => { return item.value.toLowerCase(); });
        let selectedInterviewQuestionsID = interview.interviewQuestions.map((item: any) => { return item._id })
        console.log(toolsArray);
        if (toolsArray.length) {
            setFetchedInterviewQuestions((values) => ({
                ...values,
                questions: interviewQuestionsStore.questions
                    .filter(
                        (question: any) => {
                            console.log(question.primaryLang.toLowerCase());
                            return toolsArray.includes(question.primaryLang.toLowerCase())
                        })
                    .filter(
                        (question: any) => { return !selectedInterviewQuestionsID.includes(question._id) }
                    )
            }))
        } else {
            setFetchedInterviewQuestions((values) => ({
                ...values,
                questions: interviewQuestionsStore.questions
            }))
        }
    };

    const [{ data: allQSets, loading: loadingAllQSets, error: errorallQSets }, refetchAllQSets] = useAxios({
        url: 'interviewQuestionsSet/getAllInterviewQuestionsSets',
        method: 'GET'
    });

    useEffect(() => {
        allQSets && setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...allQSets]
        }));
    }, [allQSets]);
    if (loadingAllQSets) {
        return (
            <ComponentLoading show={true}></ComponentLoading>
        )
    }

    return (
        <IonContent fullscreen>
            {/* <IonHeader collapse="condense">
                <IonToolbar color="primary">
                    <IonTitle size="large">{orgPageTitle}</IonTitle>
                    <IonTitle>Done</IonTitle>
                </IonToolbar>
            </IonHeader> */}
            <IonItemGroup>
                <IonRow class='mt-1'>
                    <IonCol class='offset-lg-3 col-lg-6'>
                        <IonItem className='mb-2'>
                            <IonLabel position="floating">Interview Questions Set Name</IonLabel>
                            <IonInput name={"interviewSetName"} type="text" ref={interviewSetName} ></IonInput>
                        </IonItem>
                        <CreatableSelect
                            className='py-2'
                            styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            placeholder="Select Tools and Technologies"
                            options={[...LanguageTech.languages]}
                            isMulti={true}
                            // defaultValue={[...interview.languagesTools]}
                            onChange={onToolsChange}
                        />
                        <IonButton expand="block" shape="round" className='mt-3' fill="outline" color='primary' onClick={searchInterviewQuestionSet}>Search Interview Question Set</IonButton>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <hr className='m-0'></hr>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol class='col-lg-12'>
                        {fetchedInterviewQuestions?.questions && fetchedInterviewQuestions.questions.map((interviewQuestion: any, index: any) => {
                            return (
                                <IonItem key={interviewQuestion._id}>
                                    {/* <IonLabel className="w-25">{holiday.holidayDate}
                                            {holiday.optional ? <IonBadge className="mb-2" color="warning">Optional</IonBadge> : null}
                                        </IonLabel> */}
                                    <IonButton onClick={() => selectQuestion(interviewQuestion)} style={{ marginRight: '0.5rem' }}>Clone</IonButton>

                                    <IonLabel className="w-75">{interviewQuestion.interviewQuestionsSetName}</IonLabel>

                                    <IonButton onClick={() => selectQuestion(interviewQuestion)} style={{ marginRight: '0.5rem' }}>Edit</IonButton>
                                    {/* <IonIcon icon={trashOutline} onClick={() => deleteHoliday(holiday.holidayName)}></IonIcon> */}
                                </IonItem>
                            );
                        })}
                    </IonCol>
                </IonRow>
            </IonItemGroup>
        </IonContent >
    );
};

export default ViewAllGlobalQuestionSets;
