const uuid = () => {
    return (
        "rgt-" +
        ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (a) =>
            (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
        )
    );
};

export default uuid;
