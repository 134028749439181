import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonItemDivider, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { text } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CreateOrganization from '../../components/CreateOrganization';
import API from '../../utils/API';
import Toaster from '../../utils/Toaster';
import { ToasterMessage } from '../../utils/ToasterMessages';

const SetPassword: React.FC = () => {
  const { email, verificationId } = useParams<{ email: string, verificationId: string }>();
  const history = useHistory();
  const [showToaster] = Toaster();
  const [passwordData, setPasswordData] = useState({
    password: '',
    confirmPassword: '',
    email: email,
    verificationId: verificationId
  });

  const handleInputChange = (event: any) => {
    setPasswordData((values) => ({
      ...values,
      [event.target.name]: event?.target.value
    }));
  }

  console.log(email);

  const setPassword = () => {
    console.log(passwordData);
    if (passwordData.password === '') {
      showToaster(ToasterMessage.setPassword.emptyPassword, 'warning', 'top');
      return;
    }
    if (passwordData.confirmPassword === '') {
      showToaster(ToasterMessage.setPassword.emptyConfirmPassword, 'warning', 'top');
      return;
    }
    if (passwordData.password !== passwordData.confirmPassword) {
      showToaster(ToasterMessage.setPassword.passwordMismatch, 'warning', 'top');
      return;
    }

    API().post('/enterprise/userc', { "data": passwordData }).then((data) => {
      showToaster(ToasterMessage.setPassword.updatedSuccessfully, 'success', 'top');
    }, e => {
      console.log(e);
    })

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary" className="d-flex justify-content-center align-items-center">
          <IonTitle>Set Password</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{email}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            {/* <IonCol size-lg="3" size-md="4" size-sm="6" size="12"></IonCol> */}
            <IonCol size-md="4" size-sm="6" size="12"></IonCol>
            <IonCol className="d-flex flex-column mt-5" size-md="4" size-sm="6" size="12">
              <IonItemDivider color="primary">
                <IonTitle className="my-3">Set Password</IonTitle>
              </IonItemDivider>
              <IonItem>
                <IonInput value={email} disabled></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" onIonInput={handleInputChange} name="password"></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Confirm Password</IonLabel>
                <IonInput type="password" onIonInput={handleInputChange} name="confirmPassword"></IonInput>
              </IonItem>
              <IonButton className="mt-5" onClick={setPassword} color="primary">Submit</IonButton>
              <Link style={{ textDecoration: 'none' }} className="mt-4 pl-4" to={`/login`}><IonLabel>Login Here</IonLabel></Link>
           
            </IonCol>
            <IonCol size-md="4" size-sm="6" size="12"></IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SetPassword;
