import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonItemDivider, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { text } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CreateOrganization from '../CreateOrganization';
import API from '../../utils/API';
import Toaster from '../../utils/Toaster';
import { ToasterMessage } from '../../utils/ToasterMessages';

const VerificationEmail: React.FC = () => {
  const { email, verificationId } = useParams<{ email: string, verificationId: string }>();
  const history = useHistory();
  const [showToaster] = Toaster();
  const [passwordData, setPasswordData] = useState({
    email,
    idr: verificationId
  });

  const handleInputChange = (event: any) => {
    setPasswordData((values) => ({
      ...values,
      [event.target.name]: event?.target.value
    }));
  }

  console.log(email);

  // const setPassword = () => {
  //   console.log(passwordData);
  //   if (passwordData.password === '') {
  //     showToaster(ToasterMessage.setPassword.emptyPassword, 'warning', 'top');
  //     return;
  //   }
  //   if (passwordData.confirmPassword === '') {
  //     showToaster(ToasterMessage.setPassword.emptyConfirmPassword, 'warning', 'top');
  //     return;
  //   }
  //   if (passwordData.password !== passwordData.confirmPassword) {
  //     showToaster(ToasterMessage.setPassword.passwordMismatch, 'warning', 'top');
  //     return;
  //   }

    

  // }

  useEffect(() => {
    API().post('/enterprise/doVerification', { "data": passwordData }).then((data) => {
      showToaster(ToasterMessage.register.verificationDone, 'success', 'top');
      setTimeout(() => {
        history.push('/login');
      }, 2000)
    }).catch(e => {
      console.log(e.response.data.message);
      if(e?.response?.data?.message){
        showToaster(e.response.data.message, 'warning', 'top');
      }
      if(e?.response?.data?.message == 'Already verified'){
        history.push("/login");
      }
    });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary" className="d-flex justify-content-center align-items-center">
          <IonTitle>Email Verification</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{email}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
         
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default VerificationEmail;
