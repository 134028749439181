
import { useHistory, useParams } from 'react-router';
import CreateOrganization from '../../components/CreateOrganization';
import {IonPage,IonInput,IonLabel,IonButton, IonItem, IonItemDivider, IonGrid, IonRow, IonCol, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonDatetime, IonIcon, IonCard, IonPicker, IonToggle } from '@ionic/react';
import {Link} from 'react-router-dom';
import { chatbubble, attach } from 'ionicons/icons'
import { Data } from './DummyData';
import { useState } from 'react';
import { types } from 'util';



const ViewTimesheetPage: React.FC = () => {

  
  
  const [details, setDetails] = useState(Data);

  const history = useHistory();
  
    const routeChange = () =>{ 
      let path = `/employee/timesheets/create-time-sheet`;
      history.push(path);
    }

    const routeHandler = () => {
      let path = `/employee/timesheets/approved-sheet`;
      history.push(path);
    }

    const nextRoute = () => {
      let path = `/employee/timesheets/rejected-sheet`;
      history.push(path);
    }


  return (
    
      <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot='end' >
            <IonButton color='primary' onClick={routeChange}>ADD WORK</IonButton>
            <IonButton color='primary' onClick={routeHandler}>APPROVED</IonButton>
            <IonButton color='primary' onClick={nextRoute}>REJECTED</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    
      <IonContent fullscreen>
      {details.map((detail) => (
          <IonToolbar color="primary">
            <IonTitle>{detail.title}</IonTitle>
            <IonCard className="p-3">
            <IonRow>
              <IonCol size='4'>{detail.type}</IonCol>
              <IonCol size='4'>{detail.company}</IonCol>
              <IonCol size='4'>{detail.designation}</IonCol>
            </IonRow>
              <IonRow>
                <IonCol size-sm="1.5" size-xs="3" className='mt-2'>
                  <IonLabel>{detail.day[0]}</IonLabel>
                  <div>{detail.time[3]}</div>
                </IonCol>
                <IonCol size-sm="1.5" size-xs="3" className='mt-2'>
                  <IonLabel>{detail.day[1]}</IonLabel>
                  <div>{detail.time[3]}</div>
                </IonCol>
                <IonCol size-sm="1.5" size-xs="3" className='mt-2'>
                  <div>{detail.day[2]}</div>
                  <div>{detail.time[3]}</div>
                </IonCol>
                <IonCol size-sm="1.5" size-xs="3" className='mt-2'>
                  <div>{detail.day[3]}</div>
                  <div>{detail.time[3]}</div>
                </IonCol>
                <IonCol size-sm="1.5" size-xs="3" className='mt-2'>
                  <div>{detail.day[4]}</div>
                  <div>{detail.time[3]}</div>
                </IonCol>
                <IonCol size-sm="1.5" size-xs="3" className='mt-2'>
                  <div>{detail.day[5]}</div>
                  <div>{detail.time[4]}</div>
                </IonCol>
                <IonCol size-sm="1.5" size-xs="3" className='mt-2' >
                  <div>{detail.day[6]}</div>
                  <div>{detail.time[4]}</div>
                </IonCol>
                <IonCol size-sm="1.5" size-xs="3" className='mt-2'>
                  <div>Total</div>
                  <div>{detail.total}</div>
                </IonCol>  
              </IonRow>
              <IonRow>
                <IonButton className='mt-3' color='primary'>
                  <IonIcon icon={chatbubble}/>
                </IonButton>
                <IonButton className='mt-3' color='primary'>
                
                  <IonIcon icon={attach}/>
                
                </IonButton>
                <IonItem className='mt-3'>
                <IonToggle color="primary"/>
                <IonLabel>WORKED</IonLabel>
                </IonItem>
                <IonButton className='mt-3' color='primary'>Submit</IonButton>
              </IonRow> 
              
            </IonCard>
          </IonToolbar>
        
      ))}
      </IonContent>
      
    </IonPage>
  );
};

export default ViewTimesheetPage;
