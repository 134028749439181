import { IonButton, IonCard, IonCardHeader, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonToggle } from '@ionic/react';
import CustomDate from '../common/CustomDate';
import API from '../../utils/API';
import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import SearchUser from '../common/SearchUser';
import ComponentLoading from '../common/ComponentLoading';
import AxiosRestCall from '../../hooks/apis/AxiosRestCall';
import Toaster from '../../utils/Toaster';
import { ToasterMessage } from '../../utils/ToasterMessages';
interface ContainerProps {
    name: string;
    editHoliday: string;
    updateRefreshView: any;
}

const CreateHolidays: React.FC<ContainerProps> = ({ name, editHoliday, updateRefreshView }) => {
    const [loading, setLoading] = useState(false);
    const [optionalChecked, setOptionalChecked] = useState(false);
    const [holidayDetails, setHolidayDetails] = useState({
        holidayDate: '',
        holidayName: '',
        optional: false
    });
    const getDate = (date: string) => { // the callback. Use a better name
        console.log(date);
        setHolidayDetails((values) => ({
            ...values,
            holidayDate: date,
        }));
    };
    const [showToaster] = Toaster();
    const handleInputChange = (event: any) => {
        console.log(optionalChecked);
        setHolidayDetails((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const createHolidays = () => {
        holidayDetails.optional = optionalChecked;
        setLoading(true);
        API().post('/holidays/saveHolidays', { "req": holidayDetails }).then(
            (data => {
                showToaster(ToasterMessage.holidays.createdHolidaySuccess, 'success', 'top');
            })
        ).catch((err) => {
            console.log(err);
            showToaster(err.response.data.message || ToasterMessage.holidays.createdHolidayFailure, 'warning', 'top');
        }).finally(() => {
            setLoading(false);
            setOptionalChecked(false);
            updateRefreshView();
        });
    };
    useEffect(() => {
        console.log(editHoliday);
        if (editHoliday) {
            API().get('/holidays/getHolidayByName/' + editHoliday).then((data:any) => {
                setHolidayDetails(data.data);
            }).finally(() => {
            })
        }
    }, [editHoliday]);
    if (loading) {
        return (
            <>
                <IonCardHeader color="primary">
                    <IonCardTitle className=""> Create Holidays
                    </IonCardTitle>
                </IonCardHeader>
                <ComponentLoading show={true}></ComponentLoading>
            </>
        )
    }

    return (
        <>
            <IonCardHeader color="primary">
                <IonCardTitle className="">Create Holidays
                </IonCardTitle>
            </IonCardHeader>
            <IonRow>
                <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Holiday Name</IonLabel>
                        <IonInput value={holidayDetails.holidayName} name="holidayName" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="6" size="12" size-sm="12">
                    <CustomDate name={'Select Holiday Date'} setDate={holidayDetails.holidayDate} getDate={getDate} ></CustomDate>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel>Optional Holiday</IonLabel>
                        <IonToggle checked={optionalChecked} name="optional" onClick={() => setOptionalChecked(!optionalChecked)} color="primary" />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="d-flex justify-content-center mt-4">
                <IonCol size="12" size-sm >
                    <IonButton shape="round" fill="outline" disabled={loading} onClick={createHolidays} >Add Holiday</IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default CreateHolidays;
