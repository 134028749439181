import { IonBackButton, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { createOutline, pin, walk, warning, wifi, wine } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CreateHolidays from '../../components/holidays/CreateHolidays';
import ViewHolidays from '../../components/holidays/ViewHolidays';
import API from '../../utils/API';

const AdminHolidayPage: React.FC = () => {
  const { name } = useParams<{ name: string; }>();
  const [editHolidayName, setEditHoliday] = useState('');
  const [refreshView, setRefreshView] = useState(false);
  const history = useHistory();

  const routeChange = (path: string) => {
    history.push(path);
  }

  const getSelectedHolidayName = (holidayName: string) => {
    setEditHoliday(holidayName);
  }

  const updateRefreshView = () => {
    setRefreshView(!refreshView);
  }
 
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">  
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Admin Holiday Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">Admin Holiday Dashboard</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-md="6" size-sm="12" size-xs="12" size='12'>
              <IonCard>
                <ViewHolidays name={''} reloadView={refreshView} selectedHoliday={getSelectedHolidayName}></ViewHolidays>
              </IonCard>
            </IonCol>
            <IonCol size-md="6" size-sm="12" size-xs="12" size='12'>
              <IonCard>
                <CreateHolidays name="Create Holidays" updateRefreshView={updateRefreshView}  editHoliday={editHolidayName}></CreateHolidays>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default AdminHolidayPage;