import { useState } from "react";
import { IonCard, IonCardContent, IonIcon } from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import Dropzone from "react-dropzone";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Cloudinary } from "@cloudinary/base";

const keys = {
    "YOUR_CLOUDINARY_CLOUD_NAME": "dpei4n4kd",
    "YOUR_CLOUDINARY_API_KEY": "182445716632865",
    "YOUR_CLOUDINARY_API_SECRET": "MSMdZ1as8CRp9OFG7YQ0W56R0jQ",
    "CLOUDINARY_URL": "cloudinary://182445716632865:MSMdZ1as8CRp9OFG7YQ0W56R0jQ@dpei4n4kd"
}

const cloudinary = new Cloudinary({
    cloud: {
        cloudName: process.env.YOUR_CLOUDINARY_CLOUD_NAME || keys.YOUR_CLOUDINARY_CLOUD_NAME,
        apiKey: process.env.YOUR_CLOUDINARY_API_KEY || keys.YOUR_CLOUDINARY_API_KEY,
        apiSecret: process.env.YOUR_CLOUDINARY_API_SECRET || keys.YOUR_CLOUDINARY_API_SECRET,
    },
});

const FileUpload = ({ onImageUpload }: any) => {
    const [image, setImage] = useState();
    const [uploading, setUploading] = useState(false);

    const handleDrop = async (acceptedFiles: any) => {
        const file = acceptedFiles[0];
        const fileName = uuidv4();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "ptyhu21n56guhds");

        try {
            setUploading(true);
            const res = await axios.post(
                `https://api.cloudinary.com/v1_1/${process.env.YOUR_CLOUDINARY_CLOUD_NAME || keys.YOUR_CLOUDINARY_CLOUD_NAME}/image/upload`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            const cloudinaryImage = cloudinary.image(res.data.public_id);

            // setImage(cloudinaryImage);
            setUploading(false);
            console.log(res);
            onImageUpload(res.data);
        } catch (err) {
            console.error(err);
            setUploading(false);
        }
    };

    return (
        <IonCard>
            <IonCardContent>
                <Dropzone onDrop={handleDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {image ? (
                                image
                            ) : (
                                <>
                                    <IonIcon color="primary" icon={addCircleOutline} />
                                    <span>Drag & drop here, or click to select a file</span>
                                </>
                            )}
                        </div>
                    )}
                </Dropzone>
                {uploading && <p>Uploading image...</p>}
            </IonCardContent>
        </IonCard>
    );
};

// const EmployeeCard = ({ employee }) => {
//   const handleImageUpload = (publicId) => {
//     // Do something with the public ID of the uploaded image
//     console.log(`Image uploaded with public ID: ${publicId}`);
//   };

//   return (
//     <IonCard>
//       <IonCardContent>
//         <h2>{employee.name}</h2>
//         <p>{employee.position}</p>
//         <ImageUploader onImageUpload={handleImageUpload} />
//       </IonCardContent>
//     </IonCard>
//   );
// };

export default FileUpload;
