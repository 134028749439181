import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonReorder, IonReorderGroup, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import ComponentLoading from '../common/ComponentLoading';
import { reorderFourOutline } from 'ionicons/icons';
import Toaster from '../../utils/Toaster';
import useAxios from 'axios-hooks';
import CreatableSelect from 'react-select/creatable';
import { LanguageTech } from '../../utils/LanguageTech';
import Select from 'react-select'
interface ContainerProps {
    name: string;
    selectedHoliday: any;
    reloadView: boolean;
}


const ViewAllGlobalQuestions: React.FC<{}> = () => {
    let orgPageTitle = "View All Global Questions";
    const [showToaster] = Toaster();

    const [fetchedInterviewQuestions, setFetchedInterviewQuestions] = useState({
        questions: []
    });

    const [questionSet, setQuestionSet] = useState([{
        "languagesTools": [],
        "_id": "6330933b733830002356d9d9",
        "interviewQuestionsSetIDs": [
            "6305cf5fae4501c639a9fb4b",
            "6305cf5fae4501c639a9fb4c",
            "6305cf5fae4501c639a9fb4a"
        ],
        "interviewQuestionsSetName": "HTML Set",
        "createdby": "",
        "createdAt": "2022-09-25T17:43:23.235Z",
        "updatedAt": "2022-09-25T17:43:23.235Z",
        "__v": 0
    }]);


    const onToolsChange = (event: any) => {
        console.log(event);
        let newBody = {
            req: {
                primaryLang: event.value.toLowerCase(),
                ids: [],
            }
        };
        refetch({ data: newBody });
    };

    const cloneAsMyQuestionSet = () => {
        
    }

    const onInterviewSetChange = (event: any) => {
        console.log(event);
        let newBody = {
            req: {
                primaryLang: '',
                ids: event.interviewQuestionsSetIDs
            }
        };
        refetch({ data: newBody });
    };

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/interviewQuestions/searchQuestions',
        method: 'POST',
        data: {
            req: {
                primaryLang: 'html',
                ids: [],
            }
        }

    });

    const [{ data: allQSets, loading: loadingAllQSets, error: errorallQSets }, refetchAllQSets] = useAxios({
        url: 'interviewQuestionsSet/getAllInterviewQuestionsSets',
        method: 'GET'
    });

    useEffect(() => {
        data && setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...data]
        }));
    }, [data]);

    useEffect(() => {
        allQSets && setQuestionSet([...allQSets]);
        setQuestionSet((prevItems) => {
            return prevItems.map((item) => {
                return { ...item, value: item.interviewQuestionsSetName, label: item.interviewQuestionsSetName };
            });
        });
        console.log(questionSet);
    }, [allQSets]);

    return (
        <IonContent fullscreen>
            {/* <IonHeader collapse="condense">
                <IonToolbar color="primary">
                    <IonTitle size="large">{orgPageTitle}</IonTitle>
                    <IonTitle>Done</IonTitle>
                </IonToolbar>
            </IonHeader> */}
            <IonItemGroup>
                <IonRow className='m-3'>
                    <IonCol sizeSm='6' sizeXs='12' >
                        <label color='primary'>
                            Pick an Interview Set OR
                        </label>
                        <Select

                            className='py-2 my-2'
                            styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            placeholder="Select an interview Set"
                            options={[...questionSet]}
                            isMulti={false}
                            // defaultValue={[...interview.languagesTools]}
                            onChange={onInterviewSetChange}
                        />
                    </IonCol>
                    <IonCol sizeSm='6' sizeXs='12'>
                        <label color='primary'>
                            Select Tools and Technologies
                        </label>

                        <Select
                            className='py-2 my-2'
                            styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            placeholder="Select Tools and Technologies"
                            options={[...LanguageTech.languages]}
                            isMulti={false}
                            // defaultValue={[...interview.languagesTools]}
                            onChange={onToolsChange}
                        />
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        {/* <IonToolbar className='xs'>
                            <IonButtons slot="start">
                                <IonButton color='primary' onClick={cloneAsMyQuestionSet}>Clone Questions as My Question Set</IonButton>
                            </IonButtons>

                        </IonToolbar> */}
                        <hr className='m-0'></hr>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol class='col-lg-12'>
                        {(loading || loadingAllQSets) &&
                            < ComponentLoading show={true}></ComponentLoading>
                        }
                        {fetchedInterviewQuestions?.questions && fetchedInterviewQuestions.questions.map((interviewQuestion: any, index: any) => {
                            return (
                                <IonItem key={interviewQuestion._id}>
                                    <IonLabel className="w-75">{interviewQuestion.question}</IonLabel>
                                </IonItem>
                            );
                        })}
                    </IonCol>
                </IonRow>
            </IonItemGroup>
        </IonContent >
    );
};

export default ViewAllGlobalQuestions;
