import { IonAvatar, IonButton, IonButtons, IonCard, IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonMenuButton, IonModal, IonRow, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from '@ionic/react';
import { useRef, useState } from 'react';

interface ContainerProps {
    searchPlaceholder?: string;
    getSearchText?: any;
}

const ionMediumColor = '#92949c';

const SearchingTable: React.FC<ContainerProps> = ({ searchPlaceholder, getSearchText }) => {
    const seatchInputRef:any = useRef();
    const handleInputChange = (event: any) => {
        getSearchText(seatchInputRef.current.value);
    };

    return (
        <IonRow className="mb-2 ml-12 ion-text-center">
            <IonCol size='12'>
                <IonItem>
                    <IonLabel position="floating">{searchPlaceholder}</IonLabel>
                    {/* <IonInput name="searchText" onIonInput={handleInputChange}></IonInput> */}
                    <IonInput ref={seatchInputRef} name="searchText"></IonInput>
                    <IonButton className="mt-4" onClick={handleInputChange} slot="end">search</IonButton>
                </IonItem>
            </IonCol>
        </IonRow>
    );
};

export default SearchingTable;
