import { IonButton, IonButtons, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import API from '../../utils/API';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import ComponentLoading from '../common/ComponentLoading';
import Toaster from '../../utils/Toaster';
import { ToasterMessage } from '../../utils/ToasterMessages';
import ViewQuestions from './ViewQuestions';
import CreatableSelect from 'react-select/creatable';
import { LanguageTech } from '../../utils/LanguageTech';
import { useSelector, useStore } from 'react-redux';
import { CustomRoutes } from '../../utils/CustomRoutes';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { updateInterview } from '../../redux/interview/interviewActions';
import { ADD_MULTIPLE_QUESTIONS, CHANGE_INPUT_FIELD, CLEAR_INTERVIEW, ON_TOOLS_CHANGE, SET_EXPIRE_DATE, UPDATE_INTERVIEW } from '../../redux/interview/interviewTypes';
import ComponentLoadingAI from '../common/ComponentLoadingAI';
interface ContainerProps {
}

const modelStyle = {
    width: '100px !important',
    height: '100% !important'
}

const CreateInterview: React.FC<ContainerProps> = ({ }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loadingAI, setLoadingAI] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);
    const reactSelect = useRef();
    const defaultInterviewData = useSelector((state: any) => state.interview);
    // const [interview, setInterview] = useState(defaultInterviewData);
    const history = useHistory();

    const [showToaster] = Toaster();
    const handleInputChange = (event: any) => {
        // console.log(optionalChecked);
        console.log(event.target.value);
        dispatch({ type: CHANGE_INPUT_FIELD, payload: { name: event.target.name, value: event.target.value } });
    };
    const onToolsChange = (event: any) => {
        console.log(event);
        dispatch({ type: ON_TOOLS_CHANGE, payload: event });
    };

    const clearCandidate = () => {
        dispatch({ type: CLEAR_INTERVIEW, payload: '' });
    }

    const checkValidations = () => {
        if (!defaultInterviewData.candidateName) {
            showToaster(ToasterMessage.interview.validation.candidateNameRequired, 'warning', 'top');
            return true;
        }

        if (!defaultInterviewData.candidateEmail) {
            showToaster(ToasterMessage.interview.validation.candidateEmail, 'warning', 'top');
            return true;
        }
        return false;
    }


    const generateInterviewQuestions = () => {
        console.log(defaultInterviewData);
        if (checkValidations()) {
            return;
        }
        setLoadingAI(true);
        API().post('/interviewQuestions/generateAIQuestions', {
            req: defaultInterviewData
        }).then((data) => {
            let questions = data?.data?.questionSetAI?.interviewQuestions;
            if (questions) {
                dispatch({ type: ADD_MULTIPLE_QUESTIONS, payload: questions });
            }
            history.replace(CustomRoutes.addQuestionsToInterview);
        }).finally(() => {
            setLoadingAI(false);
        })
    }

    const addInterviewQuestions = () => {
        dispatch({ type: UPDATE_INTERVIEW, payload: defaultInterviewData });
        history.replace(CustomRoutes.addQuestionsToInterview);
    }

    const clearInterviewStore = () => {
        dispatch({ type: CLEAR_INTERVIEW, payload: '' });
        dispatch({ type: ON_TOOLS_CHANGE, payload: [] });
        history.push(CustomRoutes.createInterview);
    }

    const createInterview = () => {
        // interview.optional = optionalChecked;
        dispatch({ type: SET_EXPIRE_DATE, payload: '' });
        setLoading(true);
        API().post('/interview/saveInterview', { "req": defaultInterviewData }).then(
            (data => {
                showToaster(ToasterMessage.interview.createdInterviewSuccess, 'success', 'top');
                clearInterviewStore();
            })
        ).catch((err) => {
            console.log(err);
            showToaster(err.response || ToasterMessage.interview.createdInterviewSuccess, 'warning', 'top');
        }).finally(() => {
            setLoading(false);
            // setOptionalChecked(false);
            // updateRefreshView();
        });
    };
    useEffect(() => {
        // console.log(editHoliday);
        // if (editHoliday) {
        //     API().get('/holidays/getHolidayByName/' + editHoliday).then((data: any) => {
        //         setInterview(data.data);
        //     }).finally(() => {
        //     })
        // }
    }, [defaultInterviewData]);

    if (loading) {
        return (
            <>
                {/* <IonCardHeader color="primary">
                    <IonCardTitle className=""> Schedule Virtual Interview</IonCardTitle>
                    <IonButton onClick={clearCandidate}>Clear</IonButton>
                </IonCardHeader> */}
                <ComponentLoading show={true}></ComponentLoading>
            </>
        )
    }

    if (loadingAI) {
        return (
            <>
                <ComponentLoadingAI show={true}></ComponentLoadingAI>
            </>
        )
    }

    return (
        <IonContent>
            <IonCardHeader>
                {/* <IonCardTitle className="">
                    Schedule Virtual Interview
                </IonCardTitle> */}
                <IonButton class='ion-float-right' fill="solid" color="warning " onClick={clearCandidate}>Clear Data</IonButton>

            </IonCardHeader>
            <IonRow>
                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Candidate Name</IonLabel>
                        <IonInput value={defaultInterviewData.candidateName} name="candidateName" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Candidate Email</IonLabel>
                        <IonInput value={defaultInterviewData.candidateEmail} name="candidateEmail" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Interview Time in Min</IonLabel>
                        <IonInput value={defaultInterviewData.interviewTime} name="interviewTime" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>

                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Job ID</IonLabel>
                        <IonInput value={defaultInterviewData.jobId} name="jobId" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Job Title</IonLabel>
                        <IonInput value={defaultInterviewData.jobRole} name="jobRole" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="12" className='mt-4' style={{ "zIndex": "100" }}>
                    {/* <IonItem>
                        <IonLabel position="floating">Languages and Tools</IonLabel>
                        <IonInput value={interview.languagesTools} name="candidateEmail" onIonInput={handleInputChange}></IonInput>
                    </IonItem> */}
                    <CreatableSelect
                        placeholder="Select Tools and Technologies"
                        options={[...LanguageTech.languages]}
                        isMulti={true}
                        value={defaultInterviewData.languagesTools}
                        onChange={onToolsChange}
                    />
                </IonCol>
                <IonCol size-md="12" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Job Description</IonLabel>
                        <IonTextarea value={defaultInterviewData.jobDescription} onIonInput={handleInputChange} name="jobDescription" rows={3} placeholder="Please add your job description here ..."></IonTextarea>
                    </IonItem>
                </IonCol>
                <IonCol size-md="12" size="12" size-sm="12">
                    {showQuestions && <ViewQuestions name={''} selectedHoliday={undefined} reloadView={false}></ViewQuestions>}
                </IonCol>
                {/* <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel>Need to interview by person?</IonLabel>
                        <IonToggle checked={optionalChecked} name="optional" onClick={() => setOptionalChecked(!optionalChecked)} color="primary" />
                    </IonItem>
                </IonCol>
                <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Interviewer Name </IonLabel>
                        <IonInput value={interview.interviewerName} name="interviewerName" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Interviewer Email </IonLabel>
                        <IonInput value={interview.interviewerEmail} name="interviewerEmail" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol> */}
            </IonRow>

            <IonRow className="d-flex justify-content-center mt-4">
                <IonCol size="12" size-sm >
                    <IonButton shape="round" fill="outline" id="select-add-questions-panel" onClick={() => generateInterviewQuestions()} >Generate Interview Questions with AI (Beta)</IonButton>
                    <IonButton shape="round" fill="outline" id="select-add-questions-panel" onClick={addInterviewQuestions} >Add Interview Questions Manually</IonButton>
                    {/* {!showQuestions && <IonButton shape="round" fill="outline" disabled={loading} onClick={() => { setShowQuestions(true) }} >Show Interview Questions</IonButton>}
                    {showQuestions && <IonButton shape="round" fill="outline" disabled={loading} onClick={() => { setShowQuestions(false) }} >Hide Interview Questions</IonButton>} */}
                    <IonButton shape="round" fill="outline" disabled={defaultInterviewData?.interviewQuestions?.length == 0} onClick={createInterview} >Schedule Interview</IonButton>
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol size='12' size-sm>

                </IonCol>
            </IonRow>
        </IonContent >
    );
};

export default CreateInterview;
