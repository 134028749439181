import { IonBadge, IonButton, IonButtons, IonCard, IonCardHeader, IonCardTitle, IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonModal, IonPage, IonReorder, IonReorderGroup, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from '@ionic/react';
import CustomDate from '../common/CustomDate';
import API from '../../utils/API';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import SearchUser from '../common/SearchUser';
import AxiosRestCall from '../../hooks/apis/AxiosRestCall';
import ComponentLoading from '../common/ComponentLoading';
import { createOutline, pizza, remove, reorderFourOutline, save, searchOutline, trashOutline } from 'ionicons/icons';
import { ToasterMessage } from '../../utils/ToasterMessages';
import Toaster from '../../utils/Toaster';
import useAxios from 'axios-hooks';
import CreatableSelect from 'react-select/creatable';
import { LanguageTech } from '../../utils/LanguageTech';
import { useSelector } from 'react-redux';
import { addQuestion } from '../../redux/interview/interviewActions';
import { useDispatch } from 'react-redux';
import { ADD_MULTIPLE_QUESTIONS, ADD_QUESTION, REMOVE_ALL, REMOVE_QUESTION } from '../../redux/interview/interviewTypes';
import { useHistory } from 'react-router';
import { CustomRoutes } from '../../utils/CustomRoutes';
import Select, { OptionsOrGroups } from 'react-select'
import CreateQuestionModal from '../common/CreateQuestionModal';
import MergeTwoArrayFunc from '../../hooks/apis/MergeTwoArrayFunc';

interface ContainerProps {
    name: string;
    selectedHoliday: any;
    reloadView: boolean;
}

const AddQuestionToInterview: React.FC<{}> = () => {
    let orgPageTitle = "Add Questions To Interview";
    const dispatch = useDispatch();
    const history = useHistory();
    const [showToaster] = Toaster();
    const interview = useSelector((state: any) => state.interview);
    // const [interview, setInterview] = useState({
    //     interviewQuestions: [],
    // });
    const [fetchedInterviewQuestions, setFetchedInterviewQuestions] = useState<{ questions: Array<Set<{ question: string }>> }>({
        questions: []
    });
    // const [interview, setInterview] = useState(defaultInterviewData);

    const editHoliday = (holidayName: string) => {
        // selectedHoliday(holidayName);
    }
    const [interviewQuestionsStore, setinterviewQuestionsStore] = useState<{ questions: Array<Set<{ question: string }>> }>({
        questions: []
    });
    // const [options, setOptions] = useState([]);

    const save = () => {
        history.push(CustomRoutes.createInterview);
    }

    const getUiqueQuestionSet = (array1, array2) => {
        const combinedArray = [...array1, ...array2];
        const uniqueArray = Object.values(
            combinedArray.reduce((acc, obj) => {
                acc[obj.question] = obj;
                return acc;
            }, {})
        );
        return combinedArray;
    }

    function removeDuplicatesByProperty(array, property) {
        const uniqueArray = [];
        const valuesSet = new Set();

        for (const obj of array) {
            const propertyValue = obj[property];
            if (!valuesSet.has(propertyValue)) {
                valuesSet.add(propertyValue);
                uniqueArray.push(obj);
            }
        }

        return uniqueArray;
    }

    const removeAll = () => {

        setFetchedInterviewQuestions((values) => ({
            ...values,
            questions: interviewQuestionsStore.questions
        }))
        dispatch({ type: REMOVE_ALL, payload: [] });
        // setInterview((values: any) => ({
        //     ...values,
        //     interviewQuestions: []
        // }))
    }

    const selectQuestion = (interviewQuestion: any) => {
        dispatch({ type: ADD_QUESTION, payload: interviewQuestion });

        // setInterview((values: any) => ({
        //     ...values,
        //     interviewQuestions: [...values.interviewQuestions, interviewQuestion]
        // }));
        setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...values.questions.filter(
                (question: any) => { return question.question != interviewQuestion.question }
            )]
        }));
        // console.log(fetchedInterviewQuestions);
    }

    const removeQuestion = (interviewQuestion: any) => {
        dispatch({ type: REMOVE_QUESTION, payload: interviewQuestion });
        // setInterview((values: any) => ({
        //     ...values,
        //     interviewQuestions: [...values.interviewQuestions.filter(
        //         (question: any) => { return question.question != interviewQuestion.question }
        //     )]
        // }));
        setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...values.questions, interviewQuestion]
        }));
    }

    const reorderQuestions = (event: any) => {
        console.log(event);
        event.detail.complete();
    }

    const onToolsChange = (event: any) => {
        let toolsArray = event.map((item: any) => { return item.value.toLowerCase(); });
        let selectedInterviewQuestionsID = interview.interviewQuestions.map((item: any) => { return item?.question })
        console.log(toolsArray);
        if (toolsArray.length) {
            setFetchedInterviewQuestions((values) => ({
                ...values,
                questions: interviewQuestionsStore.questions
                    .filter(
                        (question: any) => {
                            console.log(question.primaryLang.toLowerCase());
                            return toolsArray.includes(question.primaryLang.toLowerCase())
                        })
                    .filter(
                        (question: any) => { return !selectedInterviewQuestionsID.includes(question.question) }
                    )
            }))
        } else {
            setFetchedInterviewQuestions((values) => ({
                ...values,
                questions: interviewQuestionsStore.questions
            }))
        }
    };

    const onQuestionsSetChange = (event: any) => {
        console.log(event);
        let allQSet = allQSets.filter((allQSet: any) => {
            return event.label == allQSet.interviewQuestionsSetName
        });
        // let mergedQuestionsArray = 
        dispatch({ type: ADD_MULTIPLE_QUESTIONS, payload: MergeTwoArrayFunc(interview.interviewQuestions, allQSet[0]?.interviewQuestions) })
        allQSet[0].interviewQuestions.forEach((element: any) => {
            // let questionTemp: any = fetchedInterviewQuestions.questions.filter((question: any) => { return question.question == element.question })
            // console.log(questionTemp)
            // dispatch({ type: ADD_QUESTION, payload: questionTemp[0] });
            // setInterview((values: any) => ({
            //     ...values,
            //     interviewQuestions: [...values.interviewQuestions, questionTemp[0]]
            // }));
            // if (questionTemp[0]?.question) {
            setFetchedInterviewQuestions((values: any) => ({
                ...values,
                questions: [...values.questions.filter(
                    (question: any) => { return question.question != element.question }
                )]
            }));
            // }
        });
        console.log(interview);
        console.log(fetchedInterviewQuestions);

        // dispatch({ type: ADD_MULTIPLE_QUESTIONS, payload: interview.interviewQuestions });
    }

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/interviewQuestions/getAllInterviewQuestions',
        method: 'GET',
    });

    const [{ data: allQSets, loading: allQSetLoading, error: allQSetError }] = useAxios({
        url: '/interviewQuestionsSet/getAllInterviewQuestionsSets',
        method: 'GET',
    });

    useEffect(() => {

        data && setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...removeDuplicatesByProperty(data, 'question')]
        }));
        data && setinterviewQuestionsStore((values: any) => ({
            ...values,
            questions: [...removeDuplicatesByProperty(data, 'question')]
        }));

        // let optionsArray: any = [];
        // allQSets && allQSets.forEach((question: any) => {
        //     optionsArray.push({ label: question.interviewQuestionsSetName, value: question.interviewQuestionsSetName, vid: question.question })
        // });
        // setOptions((values) => ({
        //     ...values,
        //     ...optionsArray
        // }));
        // console.log(options);

    }, [data]);

    if (loading || allQSetLoading) {
        return (
            <ComponentLoading show={true}></ComponentLoading>
        )
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    {/* <IonButtons slot="start">
                        <IonButton color='' onClick={removeAll}>Remove ALL</IonButton>
                    </IonButtons> */}
                    <IonTitle>{orgPageTitle}</IonTitle>
                    {/* <IonButtons slot="end">
                        <IonButton color='' onClick={save}>Done Selection</IonButton>
                    </IonButtons> */}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar color="">
                        <IonTitle size="large">{orgPageTitle}</IonTitle>
                        <IonTitle>Done</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonItemGroup>
                    <IonRow class='mt-3'>
                        <IonCol class='offset-lg-3 col-lg-6'>
                            <CreatableSelect
                                styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                }}
                                placeholder="Select Tools and Technologies"
                                options={[...LanguageTech.languages]}
                                isMulti={true}
                                onChange={onToolsChange}
                            />

                            <Select styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                                className='mt-1'
                                options={[...allQSets.map((question: any) => {
                                    return { label: question.interviewQuestionsSetName, value: question.interviewQuestionsSetName, vid: question.question }
                                })]}
                                placeholder="Select Interview Questions Set"
                                onChange={onQuestionsSetChange} />

                            <IonItem>
                                <IonLabel position="floating">Search Question</IonLabel>
                                <IonInput name='Search Question' ></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol class='col-lg-6'>

                            <IonToolbar color="primary" className='xs'>
                                <IonButtons slot="start">
                                    <IonButton fill='outline' shape='round' onClick={removeAll}>Remove ALL</IonButton>
                                </IonButtons>

                                <IonTitle>Selected Questions</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton fill='outline' shape='round' onClick={save}>Done Selection</IonButton>
                                    {/* <CreateQuestionModal
                                        messageTitle='Create New Question'
                                        messageBody=""
                                        ele={<IonButton fill='outline' className='mb-2' shape='round' onClick={removeAll}>Add Question</IonButton>}
                                        onConfirm={() => { }}
                                    ></CreateQuestionModal> */}
                                </IonButtons>
                                {/* <IonButtons slot="end">
                                    <IonButton color='primary' onClick={SaveInterviewQuestions}>Create Interview Questions Set</IonButton>
                                </IonButtons> */}
                            </IonToolbar>
                            <IonReorderGroup onIonItemReorder={reorderQuestions} disabled={false}>
                                {interview?.interviewQuestions.length > 0 && interview.interviewQuestions.map((interviewQuestion: any, index: any) => {
                                    return (
                                        interviewQuestion?.question && <IonItem key={interviewQuestion.question}>
                                            {/* <IonLabel className="w-25">{holiday.holidayDate}
                                            {holiday.optional ? <IonBadge className="mb-2" color="warning">Optional</IonBadge> : null}
                                        </IonLabel> */}
                                            <IonReorder slot="start">
                                                <IonIcon icon={reorderFourOutline} />
                                            </IonReorder>
                                            <IonText className="w-100"> {interviewQuestion.question}</IonText>
                                            <IonButton color='warning' onClick={() => removeQuestion(interviewQuestion)} style={{ marginRight: '0.5rem' }}>Remove</IonButton>


                                            {/* <IonIcon icon={trashOutline} onClick={() => deleteHoliday(holiday.holidayName)}></IonIcon> */}
                                        </IonItem>
                                    );
                                })}
                            </IonReorderGroup>
                        </IonCol>

                        <IonCol class='col-lg-6'>

                            <IonToolbar color="primary" className='xs'>
                                <IonTitle>All Questions</IonTitle>
                                {/* <IonButtons slot="end">
                                    <IonButton color='primary' onClick={SaveInterviewQuestions}>Create Interview Questions Set</IonButton>
                                </IonButtons> */}

                                {/* <IonLabel className="w-25">{holiday.holidayDate}
                                            {holiday.optional ? <IonBadge className="mb-2" color="warning">Optional</IonBadge> : null}
                                        </IonLabel> */}
                                {/* <IonIcon icon={trashOutline} onClick={() => deleteHoliday(holiday.holidayName)}></IonIcon> */}
                            </IonToolbar>
                            {fetchedInterviewQuestions?.questions && fetchedInterviewQuestions.questions.map((interviewQuestion: any, index: any) => {
                                // console.log(interviewQuestion);
                                return (
                                    interviewQuestion?.question && <IonItem key={interviewQuestion.question}>
                                        <IonButton onClick={() => selectQuestion(interviewQuestion)} style={{ marginRight: '0.5rem' }}>Pick</IonButton>
                                        <IonText className="w-100">{interviewQuestion.question}</IonText>
                                    </IonItem>
                                );
                            })}
                        </IonCol>
                    </IonRow>
                </IonItemGroup>
            </IonContent>
        </IonPage>

    );
};

export default AddQuestionToInterview;
