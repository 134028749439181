export const EDIT_INTERVIEW = "EDIT_INTERVIEW";
export const DELETE_INTERVIEW = "DELETE_INTERVIEW";
export const WATCH_INTERVIEW = "WATCH_INTERVIEW";
export const ADD_QUESTION = "ADD_QUESTION";
export const REMOVE_QUESTION = "REMOVE_QUESTION";
export const REMOVE_ALL = "REMOVE_ALL";
export const UPDATE_INTERVIEW = "UPDATE_INTERVIEW";
export const CLEAR_INTERVIEW = "CLEAR_INTERVIEW";
export const CHANGE_INPUT_FIELD = "CHANGE_INPUT_FIELD";
export const ON_TOOLS_CHANGE = "ON_TOOLS_CHANGE";
export const SET_EXPIRE_DATE = "SET_EXPIRE_DATE";
export const ADD_MULTIPLE_QUESTIONS = "ADD_MULTIPLE_QUESTIONS";


