import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonItemDivider, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { text } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CreateOrganization from '../CreateOrganization';
import API from '../../utils/API';
import Toaster from '../../utils/Toaster';
import { ToasterMessage } from '../../utils/ToasterMessages';
import { Link } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const [showToaster] = Toaster();
  const history = useHistory();
  const [passwordData, setPasswordData] = useState({
    email: '',
    confirmEmail: '',
  });

  const handleInputChange = (event: any) => {
    setPasswordData((values) => ({
      ...values,
      [event.target.name]: event?.target.value
    }));
  }

  const forgotPassword = () => {
    console.log(passwordData);
    if (passwordData.email === '') {
      showToaster(ToasterMessage.setPassword.emptyPassword, 'warning', 'top');
      return;
    }
    if (passwordData.confirmEmail === '') {
      showToaster(ToasterMessage.setPassword.emptyConfirmPassword, 'warning', 'top');
      return;
    }
    if (passwordData.email !== passwordData.confirmEmail) {
      showToaster(ToasterMessage.setPassword.passwordMismatch, 'warning', 'top');
      return;
    }

    API().post('/enterprise/userh', { "data": passwordData }).then((data) => {
      showToaster(ToasterMessage.passwordReset.pleseCheckEmail, 'success', 'top');
    }, e => {
      console.log(e);
      showToaster(ToasterMessage.passwordReset.pleseCheckEmail, 'success', 'top');
    })

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary" className="d-flex justify-content-center align-items-center">
          <IonTitle>Timesheets</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            {/* <IonCol size-lg="3" size-md="4" size-sm="6" size="12"></IonCol> */}
            <IonCol size-md="4" size-sm="6" size="12"></IonCol>
            <IonCol className="d-flex flex-column mt-5" size-md="4" size-sm="6" size="12">
              <IonItemDivider color="primary">
                <IonTitle className="my-3">Forgot Password</IonTitle>
              </IonItemDivider>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput onIonInput={handleInputChange} name="email"></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Confirm Email</IonLabel>
                <IonInput onIonInput={handleInputChange} name="confirmEmail"></IonInput>
              </IonItem>
              <IonButton className="mt-5" onClick={forgotPassword} color="primary">Submit</IonButton>

              <Link style={{ textDecoration: 'none' }} className="mt-4 pl-4" to={`/login`}><IonLabel>Login Here</IonLabel></Link>
            </IonCol>
            <IonCol size-md="4" size-sm="6" size="12"></IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
