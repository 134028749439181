import { IonButton, IonButtons, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import API from '../../utils/API';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import ComponentLoading from '../common/ComponentLoading';
import Toaster from '../../utils/Toaster';
import { ToasterMessage } from '../../utils/ToasterMessages';
import ViewQuestions from './ViewQuestions';
import CreatableSelect from 'react-select/creatable';
import { LanguageTech, difficultyLevel } from '../../utils/LanguageTech';
import { useHistory } from 'react-router';
interface ContainerProps {
    isModel: boolean,
    closeModel?: any
}

const modelStyle = {
    width: '100px !important',
    height: '100% !important'
}

const AddQuestion: React.FC<ContainerProps> = ({ isModel, closeModel }) => {
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState({
        "question": "",
        "answer": "",
        "difficulty": "1",
        "keywords": [],
        "primaryLang": "",
        "videoURL": "",
        "createdby": sessionStorage.getItem('userId'),
        "updatedby": sessionStorage.getItem('userId')
    });
    // const [interview, setInterview] = useState(question);
    const history = useHistory();

    const [showToaster] = Toaster();
    const handleInputChange = (event: any) => {
        setQuestion((values: any) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));

        console.log(event.target.value);
    };

    const onToolsChange = (event: any) => {
        setQuestion((values: any) => ({
            ...values,
            primaryLang: event.label
        }))
    };
    const onDifficultyChange = (event: any) => {
        setQuestion((values: any) => ({
            ...values,
            difficulty: event.label
        }))
    };


    const addInterviewQuestions = async () => {
        // interview.optional = optionalChecked;
        setLoading(true);
        await API().post('/interviewQuestions/saveInterviewQuestions', { "req": question }).then(
            (data => {
                showToaster(ToasterMessage.interviewQuestion.createdInterviewQuestionSuccess, 'success', 'top');
                setQuestion({
                    "question": "",
                    "answer": "",
                    "difficulty": "1",
                    "keywords": [],
                    "primaryLang": "",
                    "videoURL": "",
                    "createdby": sessionStorage.getItem('userId'),
                    "updatedby": sessionStorage.getItem('userId')
                });
                if (isModel) {
                    closeModel("close");
                }
            })
        ).catch((err) => {
            console.log(err);
            showToaster(err.response || ToasterMessage.interviewQuestion.createdInterviewQuestionFailure, 'warning', 'top');
        }).finally(() => {
            setLoading(false);
            // setOptionalChecked(false);
            // updateRefreshView();
        });
    };

    if (loading) {
        return (
            <>
                <IonCardHeader color="primary">
                    <IonCardTitle className="">Create Your Own Question</IonCardTitle>
                </IonCardHeader>
                <ComponentLoading show={true}></ComponentLoading>
            </>
        )
    }

    return (
        <IonContent>
            <IonRow className='p-4'>
                <IonCol size-md="12" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Interview Question</IonLabel>
                        <IonTextarea value={question.question} name="question" onIonInput={handleInputChange} rows={2} placeholder="Please add your interview Question here ..."></IonTextarea>
                    </IonItem>
                </IonCol>
                <IonCol size-md="12" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Answer</IonLabel>
                        <IonTextarea value={question.answer} name="answer" onIonInput={handleInputChange} rows={4} placeholder="Please add your answer here ..."></IonTextarea>
                    </IonItem>
                </IonCol>

                <IonCol size-md="6" size="12" size-sm="12" className='mt-4' style={{ "zIndex": "100" }}>
                    <IonLabel position="floating">Skills</IonLabel>
                    <CreatableSelect
                        placeholder="Select Tools and Technologies"
                        options={[...LanguageTech.languages]}
                        isMulti={false}
                        onChange={onToolsChange}
                    />
                </IonCol>

                <IonCol size-md="6" size="12" size-sm="12" className='mt-4' style={{ "zIndex": "100" }}>
                    <IonLabel position="floating">Difficulty Level</IonLabel>
                    <CreatableSelect
                        placeholder="Select Tools and Technologies"
                        options={[...difficultyLevel.levels]}
                        isMulti={false}
                        onChange={onDifficultyChange}
                    />
                </IonCol>
                {/* <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel>Need to interview by person?</IonLabel>
                        <IonToggle checked={optionalChecked} name="optional" onClick={() => setOptionalChecked(!optionalChecked)} color="primary" />
                    </IonItem>
                </IonCol>
                <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Interviewer Name </IonLabel>
                        <IonInput value={interview.interviewerName} name="interviewerName" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Interviewer Email </IonLabel>
                        <IonInput value={interview.interviewerEmail} name="interviewerEmail" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol> */}
            </IonRow>

            <IonRow className="d-flex justify-content-center mt-2">
                <IonCol size="12" size-sm >
                    <IonButton shape="round" fill="outline" id="select-add-questions-panel" onClick={addInterviewQuestions} >Create Interview Question</IonButton>
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol size='12' size-sm>

                </IonCol>
            </IonRow>
        </IonContent >
    );
};

export default AddQuestion;
