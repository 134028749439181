import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory } from 'react-router';
import { CustomRoutes } from '../../../utils/CustomRoutes';
import CreateJob from '../components/CreateJobsComponent';
import SearchJobsComponent from '../components/SearchJobsComponent';

const SearchJobsPage: React.FC = () => {
    const history = useHistory();
    const goToViewInterviews = () => {
        history.push(CustomRoutes.viewInterview);
    }
    let pageTitle = "Jobs Board"
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{pageTitle}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar color="primary">
                        <IonTitle size="large">Create {pageTitle}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <SearchJobsComponent name={pageTitle}></SearchJobsComponent>
            </IonContent>
        </IonPage>
    );
};

export default SearchJobsPage;
