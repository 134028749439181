import { Card, Col, Container, Row, Spinner } from "reactstrap";

const NoDataPresent: React.FC<{ show: boolean }> = ({ show }) => {
  return (
    <>{
      show ?
        <div className="d-flex justify-content-center my-5">
          <Card className="m-5 p-5">Please change Filters, Empty Data or refresh screen</Card>

        </div> : null
    }
    </>
  );
};

export default NoDataPresent;