import { IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import useAxios from 'axios-hooks';
//import './ExploreContainer.css';
import { useEffect } from 'react';
import { useState } from 'react';
import BasicTable from '../common/tables/BasicTable';
import SearchingTable from '../common/tables/SearchingTable';
import { CustomRoutes } from '../../utils/CustomRoutes';
import GridTable from '@nadavshaar/react-grid-table';
import { useHistory } from 'react-router';
import { add, createOutline } from 'ionicons/icons';
import moment from 'moment';
import API from '../../utils/API';
import ComponentLoading from '../common/ComponentLoading';
import ComponentError from '../common/ComponentError';
interface ContainerProps {
    name: string;
}

const ViewBuyersV3: React.FC<ContainerProps> = ({ name }) => {
    const [searchText, setSearchText] = useState("");
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);

    const handleEditClick = (data: any) => {
        history.push(`/admin/create-buyer/${data._id}`)
    }

    // const [{ data, loading, error }, refetch] = useAxios({
    //     url: '/client/searchClientByName', method: 'POST', data: {
    //         "req": {
    //             "name": searchText,
    //             page: currentPage,
    //             //isSupplier: true,
    //             isBuyer: true,
    //         }
    //     }
    // });

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/client/getAllBuyers', method: 'GET'
    });



    // custom cell component
    const Username = ({ tableManager, value, field, data, column, colIndex, rowIndex }: any) => {
        return (
            <>
                <IonRow>
                    <IonCol>
                        <div className='rgt-text-truncate' style={{ marginLeft: 10, cursor: 'pointer' }}>{value}</div>
                    </IonCol>
                    <IonCol>
                        <IonIcon color='primary' size='medium' style={{ fontSize: '1.5rem', cursor: 'pointer' }} icon={createOutline} onClick={() => handleEditClick(data)} />
                    </IonCol>
                </IonRow>
            </>
        )
    };

    const DateCell = ({ tableManager, value, field, data, column, colIndex, rowIndex }: any) => {
        const formattedDate = moment(value).format('MM-DD-YYYY');
        return (
            <div>{formattedDate}</div>
        );
    }

    const columns = [
        {
            id: 1,
            field: 'name',
            label: 'Name',
            cellRenderer: Username
        },
        // { id: 'delete', name: 'del', render: ({ row }: any) => <button onClick={() => handleDeleteClick(row)}>Delete</button> },
        {
            id: 2,
            field: 'email',
            label: 'Email',
        },
        {
            id: 3,
            field: 'phone',
            label: 'Phone',
        },
        {
            id: 4,
            field: 'city',
            label: 'City',
            // getValue: ({ value }: any) => value.x + value.y
        }
    ];



    useEffect(() => {
        // getAllBuyers();
    }, []);

    if (loading) {
        return (
            <ComponentLoading key={12} show={true}></ComponentLoading>
        )
    }
    if (error) {
        return (
            <ComponentError key={13} show={true}></ComponentError>
        )
    }

    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol offset="9" className='mb-4 pb-4'>
                        {/* <IonText className='mt-4 pt-4'>Add Buyer</IonText> */}
                        <IonFab style={{ 'display': 'flex' }} title='Add Buyer' onClick={() => { history.push(CustomRoutes.createBuyer) }}>
                            <IonText style={{ 'display': 'flex', marginTop: '1em', marginRight: '1em' }} color={'primary'}>Add Buyer</IonText>
                            <IonFabButton>
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </IonFab>
                    </IonCol>
                    {/* <IonCol size="auto" className='mb-4 pb-4'>
                        <IonFab onClick={() => { history.push(CustomRoutes.createBuyer) }}>
                            <IonFabButton>
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </IonFab>
                    </IonCol> */}
                    {/* <IonCol push="4" className='mb-4 pb-4'>
                        <IonFab onClick={() => { history.push(CustomRoutes.createBuyer) }}>
                            <IonFabButton>
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </IonFab>
                    </IonCol> */}
                </IonRow>
                <IonRow>
                    <IonCol className='mt-4'>
                        <GridTable columns={columns} rows={data}></GridTable></IonCol>
                </IonRow>
            </IonGrid>
        </IonContent >
    );
};

export default ViewBuyersV3;
