import { IonButton, IonCard, IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow } from '@ionic/react';
import useAxios from 'axios-hooks';
import { METHODS } from 'http';
//import './ExploreContainer.css';
import { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { DELETE_INTERVIEW, EDIT_INTERVIEW } from '../../redux/interview/interviewTypes';
import API from '../../utils/API';
import { CustomRoutes } from '../../utils/CustomRoutes';
import Toaster from '../../utils/Toaster';
import { OrganizationMessages, ToasterMessage } from '../../utils/ToasterMessages';
import BasicCards from '../common/tables/BasicCards';
import BasicTable from '../common/tables/BasicTable';
import CustomPagination from '../common/tables/CustomPagenation';
import SearchingTable from '../common/tables/SearchingTable';
import { checkmarkOutline } from 'ionicons/icons';
import Select from 'react-select';
import ComponentLoading from '../common/ComponentLoading';
interface ContainerProps {
    name: string;
}

const ViewInterviewV2: React.FC<ContainerProps> = ({ name }) => {
    console.log(name);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showToaster] = Toaster();
    let jobPositions = [];

    const [searchText, setSearchText] = useState("");
    const [interviewData, setInterviewData] = useState<any>({
        data: []
    });
    const [filter, setFilter] = useState<any>("");
    // const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [state, setState] = useState(Date.now());
    const getSearchText = (searchText: string) => { // the callback. Use a better name
        console.log(searchText);
        setCurrentPage(1);
        setSearchText(searchText);
        refetch();
    };

    const pageChanged = (pageNumber: number) => {
        console.log(pageNumber);
        setCurrentPage(pageNumber);
    }

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/interview/searchInterviewByCandidateName', method: 'POST', data: {
            "req": {
                "searchText": searchText,
                page: currentPage,
                limit: 10,
                status: ''
            }
        }
    });

    const deleteInterview = (id: string) => {
        API().delete('/interview/deleteInterview', {
            data: {
                "req": {
                    "id": id
                }
            }
        }).then(
            (data => {
                showToaster(ToasterMessage.interview.deletedSuccess, 'success', 'top');
                refetch();
            })
        ).catch((err) => {
            showToaster(ToasterMessage.interview.deletedUnsuccessful, 'warning', 'top');
            refetch();
        });
    }


    const statusSelected = (param) => {
        setFilter(param);
        refetch({
            data: {
                req: {
                    "searchText": searchText,
                    page: currentPage,
                    limit: 10,
                    status: param,
                }
            }
        })
    }
    const selectedRow = (data: any) => {
        if (data.type == 'edit') {
            dispatch({ type: EDIT_INTERVIEW, payload: data.data });
            history.push(CustomRoutes.createInterview);
        } else if (data.type == "cancel") {
            console.log(data.data._id);
            deleteInterview(data.data._id);
        }
    }

    useEffect(() => {
        setState(Date.now());
        if (data) {
            setInterviewData(data);
        }
        console.log(interviewData);
    }, [data?.totalCount]);

    if (loading) {
        return (
            <ComponentLoading show></ComponentLoading>
        )
    }

    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol size-md="12" size-sm="12">
                        <SearchingTable searchPlaceholder={"Search interviews"} getSearchText={getSearchText}></SearchingTable>
                    </IonCol>
                    {/* <IonCol size-md="4" size-sm="12">
                        <IonCard className='p-2'>
                            <Select
                                className='pt-2 z-index-10'
                                options={ }
                                onChange={(newValue: any) => handleDropDownChange(newValue.label, 'employmentType')}
                            />
                        </IonCard>
                    </IonCol> */}
                    <IonCol size-md="12" size-sm="12">
                        <IonCard className='p-2'>
                            <IonChip outline={true} color="success" onClick={() => statusSelected(ToasterMessage.interviewStatus.APPROVED)}>
                                {filter == ToasterMessage.interviewStatus.APPROVED && <IonIcon icon={checkmarkOutline} />}
                                <IonLabel>Approved</IonLabel>
                            </IonChip>
                            <IonChip outline={true} color="warning" onClick={() => statusSelected(ToasterMessage.interviewStatus.SHORTLISTED)}>
                                {filter == ToasterMessage.interviewStatus.SHORTLISTED && <IonIcon icon={checkmarkOutline} />}
                                <IonLabel>Shortlisted</IonLabel>
                            </IonChip>
                            <IonChip outline={true} color="danger" onClick={() => statusSelected(ToasterMessage.interviewStatus.REJECTED)}>
                                {filter == ToasterMessage.interviewStatus.REJECTED && <IonIcon icon={checkmarkOutline} />}
                                <IonLabel>Rejected</IonLabel>
                            </IonChip>
                            <IonChip outline={true} color="primary" onClick={() => statusSelected(ToasterMessage.interviewStatus.ENDED)}>
                                {filter == ToasterMessage.interviewStatus.ENDED && <IonIcon icon={checkmarkOutline} />}
                                <IonLabel>Ready To Watch</IonLabel>
                            </IonChip>
                            <IonChip outline={true} color="secondary" onClick={() => statusSelected(ToasterMessage.interviewStatus.NOT_YET_STARTED)}>
                                {filter == ToasterMessage.interviewStatus.NOT_YET_STARTED && <IonIcon icon={checkmarkOutline} />}
                                <IonLabel>Not Yet Started</IonLabel>
                            </IonChip>
                            <IonChip outline={true} color="secondary" onClick={() => statusSelected('')}>
                                {filter == '' && <IonIcon icon={checkmarkOutline} />}
                                <IonLabel>Reset All</IonLabel>
                            </IonChip>
                        </IonCard>
                    </IonCol>
                    <IonCol size-md="12" size-sm="12">
                        {interviewData?.data.length && <>
                            <BasicCards
                                key={state}
                                name={'View Projects'}
                                searchPlaceholderText={'Search interviews'}
                                headers={[
                                    { key: 'candidate email', label: 'candidateEmail' },
                                    { key: 'candidate name', label: 'candidateName' },
                                    { key: 'job role', label: 'jobRole' },
                                    // { key: 'languages|tools', label: 'languagesTools' },
                                    { key: 'edit', label: 'Select' },
                                    { key: 'cancel', label: 'Select' },
                                ]}
                                tableRows={data?.data}
                                limit={data?.limit}
                                currentPageProp={currentPage}
                                totalCount={data?.totalCount}
                                pageChanged={pageChanged}
                                isLoading={loading}
                                hasError={error}
                                selectedData={selectedRow}

                            ></BasicCards>
                        </>
                        }
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent >
    );
};

export default ViewInterviewV2;
