import { IonButton, IonCol, IonContent, IonDatetime, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow } from '@ionic/react';
import CustomDate from './common/CustomDate';
import './ExploreContainer.css';
import API from '../utils/API';
import { useRef } from 'react';
import { useState } from 'react';
import Toaster from '../utils/Toaster';
import { OrganizationMessages } from '../utils/ToasterMessages';
interface ContainerProps {
    name: string;
}

const CreateOrganization: React.FC<ContainerProps> = ({ name }) => {
    console.log(name);
    let isBuyer = false;
    let isSupplier = false;
    if(name === 'Supplier'){
        isBuyer = true;
    } else if(name === 'Buyer'){
        isSupplier = true;
    }
    const [showToaster] = Toaster();
    const [orgData, setOrgData] = useState({
        organizationName: '',
        orgAddressFirstLine: '',
        orgCity: '',
        orgState: '',
        orgZip: '',
        orgCountry: '',
        orgPhone: '',
        orgEmail: '',
        type:name,

        startDate: '',

        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        addressFirstLine: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    });

    const getDate = (date: string) => { // the callback. Use a better name
        setOrgData((values) => ({
            ...values,
            'startDate': date,
        }));
    };

    const handleInputChange = (event: any) => {
        setOrgData((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const createNewOrganization = () => {
        console.log(orgData);
        API().post('/enterprise/createOrganization', { "data": orgData }).then((data) => {
            showToaster(OrganizationMessages.orgCreated, 'success', 'bottom');
        }, e => {
            console.log(e);
            showToaster(OrganizationMessages.orgCreationError, 'warning', 'bottom');
        });
    }
    return (
        <IonContent>
            {/* <IonInfiniteScroll>
                <IonInfiniteScrollContent> */}
            <IonGrid>
                <IonRow>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Name</IonLabel>
                            <IonInput name="organizationName" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Phone</IonLabel>
                            <IonInput name="orgPhone" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Email</IonLabel>
                            <IonInput name="orgEmail" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Address</IonLabel>
                            <IonInput name="orgAddressFirstLine" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} City</IonLabel>
                            <IonInput name="orgCity" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} State</IonLabel>
                            <IonInput name="orgState" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Zip</IonLabel>
                            <IonInput name="orgZip" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">{name} Country</IonLabel>
                            <IonInput name="orgCountry" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="m-3 pt-3" size-sm>
                        {/* <IonItem> */}
                        {/* <IonItemDivider> */}
                        <IonLabel color="primary">
                            Point Of Contact Details
                        </IonLabel>
                        {/* </IonItemDivider> */}
                        {/* </IonItem> */}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">First Name</IonLabel>
                            <IonInput name="firstName" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Last Name</IonLabel>
                            <IonInput name="lastName" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Phone</IonLabel>
                            <IonInput name="phone" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Email</IonLabel>
                            <IonInput name="email" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Address</IonLabel>
                            <IonInput name="addressFirstLine" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">City</IonLabel>
                            <IonInput name="city" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">State</IonLabel>
                            <IonInput name="state" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Zip</IonLabel>
                            <IonInput name="zip" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm>
                        <IonItem>
                            <IonLabel position="floating">Country</IonLabel>
                            <IonInput name="country" onIonInput={handleInputChange}></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow className="d-flex justify-content-center mt-4">
                    <IonCol size="12" size-sm >
                        <IonButton shape="round" fill="outline" onClick={createNewOrganization}>Create {name}</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            {/* </IonInfiniteScrollContent>
            </IonInfiniteScroll> */}
        </IonContent>
    );
};

export default CreateOrganization;
