import { Col, Container, Row, Spinner } from "reactstrap";

const ComponentLoading: React.FC<{ show: boolean }> = ({ show }) => {
  return (
    <>{
      show ?
        <div className="d-flex justify-content-center my-5">
          <Spinner style={{ width: '6rem', height: '6rem' }} color="primary" />
        </div> : null
    }
    </>
  );
};

export default ComponentLoading;