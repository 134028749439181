import { IonButton, IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow } from '@ionic/react';
import useAxios from 'axios-hooks';
//import './ExploreContainer.css';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ADD_BUYER } from '../../redux/employee/emploeeTypes';
import { addBuyer } from '../../redux/employee/employeeActions';
import API from '../../utils/API';
import Toaster from '../../utils/Toaster';
import { OrganizationMessages } from '../../utils/ToasterMessages';
import BasicTable from '../common/tables/BasicTable';
import SearchingTable from './tables/SearchingTable';
interface ContainerProps {
    name: string;
    getBuyer: any;
}

const SelectBuyer: React.FC<ContainerProps> = ({ name, getBuyer }) => {
    const [searchText, setSearchText] = useState("");
    const buyerModal = useRef<HTMLIonModalElement>(null);
    const dispath = useDispatch();
    // const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [state, setState] = useState(Date.now());
    const getSearchText = (searchText: string) => { // the callback. Use a better name
        console.log(searchText);
        setCurrentPage(1);
        setSearchText(searchText);
        refetch();
    };

    const pageChanged = (pageNumber: number) => {
        console.log(pageNumber);
        setCurrentPage(pageNumber);
    }

    const selectedData = (data: any) => {
        console.log(data);
        getBuyer(data?.data);
        dispath({ type: ADD_BUYER, payload: data });
        buyerModal.current?.dismiss();
    }

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/client/searchClientByName', method: 'POST', data: {
            "req": {
                "name": searchText,
                page: currentPage,
                isSupplier: false,
                isBuyer: true,
                limit: 10
            }
        }
    });

    console.log(data);

    // const getAllSuppliers = () => {

    //     // API().post('/client/searchClientByName', {
    //     //     "req": {
    //     //         "name": searchText,
    //     //         page: currentPage,
    //     //         limit: 3
    //     //     }
    //     // }).then((data) => {
    //     //     console.log(data.data);
    //     //     setSuppliers(data.data.data);
    //     //     setTotalPages(getTotalPages(data.data.totalCount, data.data.limit));
    //     // }, e => {
    //     //     console.log(e);
    //     // });
    // }


    useEffect(() => {
        setState(Date.now());
    }, [name]);

    return (
        <>
            <div className="mt-4 text-decoration-none">
                <IonModal trigger="select-buyer-panel" ref={buyerModal}>
                    <IonContent>
                        {/* <IonInfiniteScroll>
        <IonInfiniteScrollContent> */}
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12" size-sm>

                                    <SearchingTable searchPlaceholder={"Search Buyers"} getSearchText={getSearchText}></SearchingTable>
                                    {data?.data.length && <>
                                        <BasicTable
                                            key={state}
                                            name={'View Projects'}
                                            enableSelection={true}
                                            searchPlaceholderText={'Search Buyers'}
                                            headers={[
                                                { key: 'Select', label: 'Select' },
                                                { key: 'name', label: 'name' },
                                                { key: 'email', label: 'email' },
                                                { key: 'address', label: 'address' },
                                                { key: 'city', label: 'city' },
                                            ]}
                                            tableRows={data?.data}
                                            limit={data?.limit}
                                            currentPageProp={currentPage}
                                            totalCount={data?.totalCount}
                                            pageChanged={pageChanged}
                                            isLoading={loading}
                                            hasError={error}
                                            selectedData={selectedData}
                                        ></BasicTable>
                                    </>}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        {/* </IonInfiniteScrollContent>
    </IonInfiniteScroll> */}
                    </IonContent>
                </IonModal>
                <IonChip color="primary" id="select-buyer-panel" style={{ width: '100%' }}>
                    <IonLabel className="d-flex justify-content-center">{name}</IonLabel>
                </IonChip>
            </div >
        </>
    );

};

export default SelectBuyer;
