import { IonBadge, IonButton, IonCard, IonCardHeader, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import CustomDate from '../common/CustomDate';
import API from '../../utils/API';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import SearchUser from '../common/SearchUser';
import AxiosRestCall from '../../hooks/apis/AxiosRestCall';
import ComponentLoading from '../common/ComponentLoading';
import { createOutline, remove, trashOutline } from 'ionicons/icons';
import { ToasterMessage } from '../../utils/ToasterMessages';
import Toaster from '../../utils/Toaster';
import useAxios from 'axios-hooks';
interface ContainerProps {
    name: string;
    selectedHoliday: any;
    reloadView: boolean;
}

const ViewHolidays: React.FC<ContainerProps> = ({ name, selectedHoliday, reloadView }) => {
    const [holidays, setHolidays] = useState([]);
    const [showToaster] = Toaster()
    const [reloadComponent, setReloadComponent] = useState(reloadView);
    const deleteHoliday = (holidayName: string) => {
        // console.log(holidayName);
        API().delete('/holidays/deleteHolidays/' + holidayName).then(
            (data => {
                showToaster(ToasterMessage.holidays.deletedSuccess, 'success', 'top');
            })
        ).catch((err) => {
            showToaster(ToasterMessage.holidays.deletedUnsuccessful, 'warning', 'top');
        }).finally(() => {
            setReloadComponent(!reloadComponent)
        });
    }

    const editHoliday = (holidayName: string) => {
        selectedHoliday(holidayName);
    }

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/holidays/getAllHolidays',
        method: 'GET',
    });

    useEffect(() => {
        // console.log("test");
        refetch();
    }, [reloadComponent, reloadView]);

    if (loading) {
        return (
            <>
                <IonCardHeader color="primary">
                    <IonCardTitle className=""> View Holidays List
                    </IonCardTitle>
                </IonCardHeader>
                <ComponentLoading show={true}></ComponentLoading>
            </>
        )
    }

    return (
        <>
            <IonCardHeader color="primary">
                <IonCardTitle className=""> View Holidays List
                </IonCardTitle>
            </IonCardHeader>
            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel color="primary">January</IonLabel>
                </IonItemDivider>
                {data.map((holiday: any, index: any) => {
                    // console.log(holiday);
                    return (
                        <IonItem key={holiday._id}>
                            <IonLabel className="w-25">{holiday.holidayDate}
                                {holiday.optional ? <IonBadge className="mb-2" color="warning">Optional</IonBadge> : null}
                            </IonLabel>
                            <IonLabel className="w-75">{holiday.holidayName}</IonLabel>
                            <IonIcon onClick={() => editHoliday(holiday.holidayName)} style={{ marginRight: '0.5rem' }} icon={createOutline}></IonIcon>
                            <IonIcon icon={trashOutline} onClick={() => deleteHoliday(holiday.holidayName)}></IonIcon>
                        </IonItem>
                    );
                })}
            </IonItemGroup>
        </>
    );
};

export default ViewHolidays;
