import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useLocation, useParams } from 'react-router';
import CreateBuyerSupplier from '../../components/buyerSupplier/CreateBuyerSupplier';
import CreateInterview from '../../components/interview/CreateInterview';
import LoginComponent from '../../components/common/LoginComponent';

const CreateInterviewPage: React.FC = () => {
  let orgPageTitle = 'Schedule Interview';
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{orgPageTitle}</IonTitle>
          <IonButtons slot='end'>
            <LoginComponent></LoginComponent>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">Create {orgPageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <CreateInterview></CreateInterview>
      </IonContent>
    </IonPage>
  );
};

export default CreateInterviewPage;
