import { useState } from 'react';
import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { closeOutline, checkmarkOutline, warningOutline } from 'ionicons/icons';

interface ModalProps {
    messageTitle: string;
    messageBody: string;
    ele: any;
    onConfirm: () => void;
}

const ModalWithProps: React.FC<ModalProps> = ({ messageTitle, messageBody, ele, onConfirm }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleDismissModal = () => {
        setIsModalOpen(false);
    };

    const handleYesModal = () => {
        onConfirm();
        setIsModalOpen(false);
    };

    return (
        <>
            <div style={{ fontSize: '1.5em' }} onClick={handleOpenModal}>{ele}</div>
            <IonModal style={{
                '--width': '400px',
                '--height': '300px',
            }} isOpen={isModalOpen} onDidDismiss={handleDismissModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{messageTitle}</IonTitle>
                        <IonButton slot="end" onClick={handleDismissModal}>
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                    </IonToolbar>
                </IonHeader>
                <IonContent
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <IonIcon className='mt-4' icon={warningOutline} style={{ fontSize: '5em' }} color="warning" />
                    <p style={{ marginTop: '16px' }}>{messageBody}</p>
                </IonContent>
                <IonFooter>
                    <IonButton expand="full" onClick={handleYesModal}>
                        <IonIcon slot="start" icon={checkmarkOutline} />
                        Yes
                    </IonButton>
                </IonFooter>
            </IonModal>
        </>
    );
};

export default ModalWithProps;
