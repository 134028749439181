import { Col, Container, Row, Spinner } from "reactstrap";
import CircleLoader from "react-spinners/CircleLoader";
import { IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";

const ComponentLoadingAI: React.FC<{ show: boolean }> = ({ show }) => {
  return (
    <>{
      show ?
        <IonContent>
          <IonGrid className="">
            <IonRow>
              <IonCol size="12">
                <div className="m-4 p-4">AI model is working on your request, it will take more time around 30 seconds, Please be patient</div>

              </IonCol>
              <IonCol offset="4" size="12">
                <CircleLoader
                  color="#3880ff"
                  size={200} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent> : null
    }
    </>
  );
};

export default ComponentLoadingAI;