export const Data = [
    {
        id: 1,
        title:"Feb 28- mar 6 2022",
        type:"Assignment",
        day: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        total: "40.00",
        company: "Indeed, inc",
        designation:"Developer",
        time: ["5.00", "6.00", "7.00", "8.00", "0.00"]
    }
]

