import { useIonToast } from "@ionic/react";

const Toaster = () => {
    const [present] = useIonToast();

    const showToaster = (message: string, alertType: string, position: 'top' | 'bottom' | 'middle') => {
        present({
            message: message,
            color: alertType,
            duration: 3000,
            position: position,
            keyboardClose: true
        });
    }

    return [showToaster];
};

export default Toaster;