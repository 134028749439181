/* eslint-disable import/no-anonymous-default-export */
import { configure } from 'axios-hooks'
import Axios from "axios";

export default () => {
  let history = null;
  // const baseURL = "https://frabjous-platypus-370c8d.netlify.app/.netlify/functions/server/hire/api/v1/";
  // const baseURL = "http://localhost:5000/.netlify/functions/server/hire/api/v1/";
  const baseURL = "https://dev-youarehired.onrender.com/.netlify/functions/server/hire/api/v1/";
  
  // const baseURL = "https://dev-api-youarehired.herokuapp.com/hire/api/v1/"
  // const baseURL = "https://frabjous-platypus-370c8d.netlify.app/.netlify/functions/server/hire/api/v1/"
  // console.log(baseURL);

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    "createdby": sessionStorage.getItem('userId'),
    "updatedby": sessionStorage.getItem('userId')
    // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYTgxOWJmZjVmZjBlNTdkMGE2YzEzYyIsImlhdCI6MTY4MDkzMzM2OH0.EvmUj8q7lSLXO7v49Ts8p6JY-y_12BvN7IBsHDHwVtQ'
  };

  if (sessionStorage.token) {
    headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
  }

  const axios = Axios.create({
    baseURL: baseURL,
    headers,
  });

  axios.interceptors.response.use(
    (response) =>
      new Promise((resolve) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === 403) {
        sessionStorage.removeItem("token");

        if (history) {
          history.push("/login");
        } else {
          window.location = "/login";
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return axios;
};
