import { IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory, useLocation, useParams } from 'react-router';
import { CustomRoutes } from '../../utils/CustomRoutes';
import ViewAllGlobalQuestions from '../../components/interview/ViewAllGlobalQuestions';
import ViewAllMyQuestions from '../../components/interview/ViewAllMyQuestions';
import LoginComponent from '../../components/common/LoginComponent';

const ViewAllMyQuestionsPage: React.FC = () => {

  let PageTitle = "My Questions"
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            {PageTitle}
          </IonTitle>
          <IonButtons slot='end'>
            <LoginComponent></LoginComponent>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{PageTitle}</IonTitle>
            {/* <IonButton>View Interviews</IonButton> */}
          </IonToolbar>
        </IonHeader>
        {/* <ViewAllMyQuestions></ViewAllMyQuestions> */}
        <ViewAllMyQuestions></ViewAllMyQuestions>
      </IonContent>
    </IonPage>
  );
};

export default ViewAllMyQuestionsPage;
