import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, useHistory } from 'react-router-dom';
import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Theme variables */
import './theme/variables.css';
import SuperAdminIndex from './pages/super-admin/SuperAdminIndex';
import CreateOrganizationPage from './pages/super-admin/CreateOrganizationPage';
import SetPassword from './components/auth/SetPassword';
import LoginUser from './components/auth/LoginUser';
import ForgotPassword from './components/auth/ForgotPassword';
import EmployeeIndex from './pages/employee/EmployeeIndex';
import HRIndex from './pages/hr/HRIndex';
import AdminIndex from './pages/admin/AdminIndex';
import CreateEmployeePage from './pages/commonPages/CreateEmployeePage';
import HolidayPage from './pages/org/HolidayPage';
import DepartmentsPage from './pages/org/DepartmentsPage';
import CreateProjectTaskPage from './pages/org/CreateProjectTaskPage';
import ViewProjectTaskPage from './pages/org/ViewProjectTaskPage';
import ViewTimesheetPage from './pages/timesheets/ViewTimesheets';
import CreateTimesheet from './pages/timesheets/CreateTimesheets';
import ApprovedTimesheets from './pages/timesheets/ApprovedTimesheets';
import RejectedTimesheets from './pages/timesheets/RejectedTimesheets';
import AdminLeavesPage from './pages/admin/AdminLeavesPage';
import EmployeeLeavesPage from './pages/org/EmployeeLeavesPage';
import AdminHolidayPage from './pages/admin/AdminHolidayPage';
import { configure, ConfigureOptions } from 'axios-hooks';
import API from './utils/API';
import SupplierBuyerPage from './pages/buyerSupplier/SupplierBuyerPage';
import ViewSuppliersPage from './pages/buyerSupplier/ViewSuppliersPage';
import ViewBuyersPage from './pages/buyerSupplier/ViewBuyersPage';
import { Provider } from 'react-redux';
import store from './redux/store';
import InterviewPage from './pages/interviews/CreateInterviewPage';
import ViewInterviewPage from './pages/interviews/ViewInterviewsPage';
import CreateInterviewPage from './pages/interviews/CreateInterviewPage';
import AttendInterview from './pages/interviews/AttendInterviewPage';
import ViewInterviewDashboard from './components/interview/ViewInterviewDashboard';
import { CustomRoutes } from './utils/CustomRoutes';
import WatchInterviewsPage from './pages/interviews/WatchInterviewsPage';
import CreateInterviewQuestionSetPage from './pages/interviews/CreateInterviewQuestionSetPage';
import AddQuestionToInterviewPage from './pages/interviews/AddQuestionToInterviewPage';
import RegisterUser from './components/auth/RegisterUser';
import JobsPage from './components/jobs/pages/JobsPage';
import CreateJobsPage from './components/jobs/pages/CreateJobPage';
import { useEffect } from 'react';
import VerificationEmail from './components/auth/VerificationEmail';
import SearchJobsComponent from './components/jobs/components/SearchJobsComponent';
import SearchJobsPage from './components/jobs/pages/SearchJobsPage';
import CreateTimeSheetPage from './pages/timesheets/CreateEmployeePage';
import ViewJobPage from './components/jobs/pages/ViewJobPage';
import AddQuestionPage from './pages/interviews/AddQuestionPage';
import ViewAllGlobalQuestionsPage from './pages/interviews/ViewAllGlobalQuestionsPage';
import ViewAllGlobalQuestionSetsPage from './pages/interviews/ViewAllGlobalQuestionSetsPage';
import MyQuestionSetsPage from './pages/interviews/MyQuestionSetsPage';
import ViewAllMyQuestionsPage from './pages/interviews/ViewAllMyQuestionsPage';
import EditQuestion from './components/interview/EditQuestion';
import EditQuestionPage from './pages/interviews/EditQuestionPage';
import ViewAllEmployeesPage from './pages/employee/ViewAllEmployeesPage';
import { LanguageTech } from './utils/LanguageTech';


const configureOptions: ConfigureOptions = {
  axios: API()
}

configure(configureOptions)
setupIonicReact();

const fetchData = () => {
  // Check if data is available in local storage
  try {
    let responseData = '';
    API().get('/technologies/getAllTechnologies').then(data => {
      console.log(data);
      responseData = data.data;
      LanguageTech.languages = data.data;
    });
    sessionStorage.setItem('apiData', JSON.stringify(responseData));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const App: React.FC = () => {

  useEffect(() => {
    const cachedData = sessionStorage.getItem('apiData');
    if (cachedData && cachedData == '') {
      LanguageTech.languages = JSON.parse(cachedData);
    } else {
      fetchData();
    }
  }, []);

  // useEffect(() => {
  //   if (!authorized) {
  //     history.push("/login");
  //   }
  // }, [authorized])
  // if (unAuthorized) {
  //   return (
  //     <IonApp>
  //       <IonReactRouter>
  //         <IonRouterOutlet id="main">
  //           <Route path="/login" component={LoginUser} exact={true} />
  //           <Route path="/register" component={RegisterUser} exact={true} />
  //           <Route path="/forgotPassword" component={ForgotPassword} exact={true} />
  //           <Route path="/setPassword/:email/:verificationId" component={SetPassword} exact={true} />
  //           <Route path="/verification" exact={true}> </Route>
  //           <Route path="*">
  //             <Redirect to="/login" exact={true} />
  //           </Route>
  //         </IonRouterOutlet>
  //       </IonReactRouter>
  //     </IonApp>
  //   )
  // }

  return (
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          <>
            <IonSplitPane contentId="main">
              <Menu />
              <IonRouterOutlet id="main">
                <Route exact={true} path="/register" component={RegisterUser} />
                <Route exact={true} path="/login" component={LoginUser} />
                <Route exact={true} path="/forgotPassword" component={ForgotPassword} />
                <Route exact={true} path="/setPassword/:email/:verificationId" component={SetPassword} />
                <Route exact={true} path="/verifyEmail/:email/:verificationId" component={VerificationEmail} />
                <Route exact={true} path="/verification" />

                <Route exact={true} path="/attend-interview/:id" component={AttendInterview} />

                <Route exact={true} path="/super-admin" component={SuperAdminIndex} />
                <Route exact={true} path={CustomRoutes.createOrganization} component={CreateOrganizationPage} />

                <Route exact={true} path="/admin" component={AdminIndex} />

                <Route exact={true} path="/admin/create-supplier" component={SupplierBuyerPage} />
                <Route exact={true} path="/admin/create-supplier/:id" component={SupplierBuyerPage} />
                <Route exact={true} path="/admin/create-buyer" component={SupplierBuyerPage} />
                <Route exact={true} path="/admin/create-buyer/:id" component={SupplierBuyerPage} />
                <Route exact={true} path="/admin/view-suppliers" component={ViewSuppliersPage} />
                <Route exact={true} path="/admin/view-buyers" component={ViewBuyersPage} />

                <Route exact={true} path={CustomRoutes.createProjectTask} component={CreateProjectTaskPage} />
                <Route exact={true} path="/admin/edit-project/:id" component={CreateProjectTaskPage} />
                <Route exact={true} path="/admin/view-projects" component={ViewProjectTaskPage} />

                <Route exact={true} path="/employee" component={EmployeeIndex} />
                <Route exact={true} path="/employee/timesheets" component={ViewTimesheetPage} />
                <Route exact={true} path={CustomRoutes.employeeCreateTimesheet} component={CreateTimeSheetPage} />
                <Route exact={true} path="/employee/timesheets/approved-sheet" component={ApprovedTimesheets} />
                <Route exact={true} path="/employee/timesheets/rejected-sheet" component={RejectedTimesheets} />

                <Route exact={true} path="/admin/dashboard-leaves" component={AdminLeavesPage} />
                <Route exact={true} path={CustomRoutes.dashboardHolidays} component={AdminHolidayPage} />

                <Route exact={true} path="/admin/interview" component={InterviewPage} />
                <Route exact={true} path={CustomRoutes.createInterview} component={CreateInterviewPage} />
                <Route exact={true} path={CustomRoutes.viewInterview} component={ViewInterviewPage} />
                <Route exact={true} path={CustomRoutes.createInterviewQuestionsSet} component={CreateInterviewQuestionSetPage} />
                <Route exact={true} path={CustomRoutes.createInterviewQuestionsSet + '/:id'} component={CreateInterviewQuestionSetPage} />
                <Route exact={true} path={CustomRoutes.viewInterviewDashboard} component={ViewInterviewDashboard} />
                <Route exact={true} path={CustomRoutes.watchFinishedInterview + '/:interviewID'} component={WatchInterviewsPage} />
                <Route exact={true} path={CustomRoutes.addQuestion} component={AddQuestionPage} />
                <Route exact={true} path="/admin/edit-my-question/:id" component={EditQuestionPage} />
                <Route exact={true} path={CustomRoutes.viewAllGlobalQuestions} component={ViewAllGlobalQuestionsPage} />
                <Route exact={true} path={CustomRoutes.viewAllMyQuestions} component={ViewAllMyQuestionsPage} />
                <Route exact={true} path={CustomRoutes.viewAllGlobalQuestionSets} component={ViewAllGlobalQuestionSetsPage} />
                <Route exact={true} path={CustomRoutes.myQuestionSets} component={MyQuestionSetsPage} />


                <Route exact={true} path={CustomRoutes.createJob} component={CreateJobsPage} />
                <Route exact={true} path={CustomRoutes.viewAllJobs} component={SearchJobsPage} />

                <Route exact={true} path="/hr" component={HRIndex} />

                <Route exact={true} path="/employee" component={EmployeeIndex} />
                <Route exact={true} path={CustomRoutes.createEmployee} component={CreateEmployeePage} />
                <Route exact={true} path="/admin/create-employee/:id" component={CreateEmployeePage} />
                <Route exact={true} path={CustomRoutes.viewAllEmployees} component={ViewAllEmployeesPage} />

                <Route exact={true} path="/org/dashboard-holidays" component={HolidayPage} />
                <Route exact={true} path="/org/dashboard-leaves" component={EmployeeLeavesPage} />
                <Route exact={true} path="/org/dashboard-departments" component={DepartmentsPage} />
                <Route exact={true} path={CustomRoutes.addQuestionsToInterview} component={AddQuestionToInterviewPage} />
                <Route exact={true} path={CustomRoutes.jobsPage()} component={JobsPage} />
                <Route exact={true} path={CustomRoutes.viewJob} component={ViewJobPage} />
              </IonRouterOutlet>
            </IonSplitPane>
          </>
        </IonReactRouter>
      </IonApp>
    </Provider >
  );
};

export default App;
