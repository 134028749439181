import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonText } from '@ionic/react';
import useAxios from 'axios-hooks';
import { METHODS } from 'http';
//import './ExploreContainer.css';
import { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { EDIT_INTERVIEW, DELETE_INTERVIEW } from '../../../redux/interview/interviewTypes';
import API from '../../../utils/API';
import { CustomRoutes } from '../../../utils/CustomRoutes';
import Toaster from '../../../utils/Toaster';
import { ToasterMessage } from '../../../utils/ToasterMessages';
import BasicCards from '../../common/tables/BasicCards';
import JobsCards from '../../common/tables/JobsCards';
import SearchingTable from '../../common/tables/SearchingTable';
import { useParams } from 'react-router-dom';
interface ContainerProps {
    name: string;
}

const ViewJobComponent: React.FC<ContainerProps> = ({ name }) => {
    console.log(name);
    const dispatch = useDispatch();
    const history = useHistory();
    const jobData: any = history.location.state;
    const [showToaster] = Toaster()
    const [job, setJob] = useState(jobData);
    const { jobId } = useParams<{ jobId: string }>();

    const [searchText, setSearchText] = useState("");
    // const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [state, setState] = useState(Date.now());
    const getSearchText = (searchText: string) => { // the callback. Use a better name
        console.log(searchText);
        setCurrentPage(1);
        setSearchText(searchText);
        refetch();
    };

    const pageChanged = (pageNumber: number) => {
        console.log(pageNumber);
        setCurrentPage(pageNumber);
    }

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/job/searchJobs', method: 'POST', data: {
            "req": {
                "searchText": searchText,
                "employmentType": "",
                "status": "",
                "skills": [],
                page: currentPage,
                limit: 10
            }
        }
    });

    const deleteInterview = (id: string) => {
        API().delete('/job/deleteJob', {
            data: {
                "req": {
                    "id": id
                }
            }
        }).then(
            (data => {
                showToaster(ToasterMessage.job.deletedSuccess, 'success', 'top');
                refetch();
            })
        ).catch((err) => {
            showToaster(ToasterMessage.job.deletedUnsuccessful, 'warning', 'top');
        });
    }


    const selectedRow = (data: any) => {
        if (data.type == 'edit') {
            dispatch({ type: EDIT_INTERVIEW, payload: data.data });
            history.push(CustomRoutes.createInterview);
        } else if (data.type == "cancel") {
            console.log(data.data._id);
            dispatch({ type: DELETE_INTERVIEW, payload: data.data });
            deleteInterview(data.data._id);
        }

        console.log(data);
    }

    useEffect(() => {
        setState(Date.now());
    }, [data?.totalCount]);

    useEffect(() => {
        // Fetch job data from an API or a local data source
        console.log(job);
        const fetchJob = async () => {
            try {
                // const response = await fetch(`https://my-api.com/jobs/${jobId}`);
                // const data = await response.json();
                // setJob(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchJob();
    }, [jobId]);

    if (!job) {
        return <p>Loading job data...</p>;
    }
    const getJobsCreatedNoOfDaysAgo = () => {
        let diffSecons = (new Date(job.createdAt).getUTCMilliseconds() - new Date().getUTCMilliseconds());
        return (diffSecons / (1000 * 60 * 60 * 24))
    }

    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol size="12" size-sm="12">
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>{job.jobTitle}
                                    <IonText > Created On {getJobsCreatedNoOfDaysAgo() > 1 ? Math.round(getJobsCreatedNoOfDaysAgo()) + "Ago" : "Today"}</IonText>
                                </IonCardTitle>
                                <IonCardSubtitle>{job.company}</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <p>Location: {job.jobLocation}</p>
                                <p>Max Salary: ${job.maxSalary}</p>
                                <p>Min Salary: ${job.minSalary}</p>

                                <p>Max Per Hour Rate: ${job.maxPerHourRate}</p>
                                <p>Min Per Hour Rate: ${job.minPerHourRate}</p>
                                <p>Job Type: {job.type}</p>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent >
    );
};

export default ViewJobComponent;
