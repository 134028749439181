import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory } from 'react-router';
import { CustomRoutes } from '../../../utils/CustomRoutes';
import CreateJob from '../components/CreateJobsComponent';

const CreateJobsPage: React.FC = () => {
    const history = useHistory();
    const goToViewInterviews = () => {
        history.push(CustomRoutes.viewInterview);
    }
    let orgPageTitle = "Create New Job"
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{orgPageTitle}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar color="primary">
                        <IonTitle size="large">Create {orgPageTitle}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <CreateJob name={''}></CreateJob>
            </IonContent>
        </IonPage>
    );
};

export default CreateJobsPage;
