import MergeTwoArrayFunc from "../../hooks/apis/MergeTwoArrayFunc";
import { EDIT_INTERVIEW, DELETE_INTERVIEW, WATCH_INTERVIEW, REMOVE_QUESTION, ADD_QUESTION, REMOVE_ALL, UPDATE_INTERVIEW, CLEAR_INTERVIEW, CHANGE_INPUT_FIELD, ON_TOOLS_CHANGE, SET_EXPIRE_DATE, ADD_MULTIPLE_QUESTIONS } from "./interviewTypes";

const initialState = {
    candidateName: '',
    candidateEmail: '',
    // candidateInterviewURl: '',
    // interviewerName: '',
    // interviewerEmail: '',
    // interviewerId: '',
    // isVirtual: true,
    // isPersonAssisted: false,
    languagesTools: [],
    jobRole: '',
    jobId: '',
    expiresAt: new Date(new Date().getTime() + 1000 * 3600 * 24 * 10),
    interviewTime: '60',
    jobDescription: '',
    interviewQuestions: [],
    // interviewQA: [],
    // interviewURL: ''
}

const interviewReducer = (state = { ...initialState }, action) => {
    console.log(action);
    switch (action.type) {
        case EDIT_INTERVIEW:
            return {
                ...action.payload
            }
        case DELETE_INTERVIEW:
            return {
                ...action.payload
            }
        case ADD_QUESTION:
            let tempSet = new Set(state.interviewQuestions);
            if (!tempSet.has(action.payload)) {
                return {
                    ...state,
                    interviewQuestions: [...state.interviewQuestions, action.payload]
                }
            } else {
                return state
            }
        case REMOVE_QUESTION:
            return {
                ...state,
                interviewQuestions: [...state.interviewQuestions.filter(
                    (question) => { return question?.question != action.payload?.question }
                )]
            }
        case REMOVE_ALL:
            return {
                ...state,
                interviewQuestions: []
            }
        case UPDATE_INTERVIEW:
            return {
                ...state,
                ...action.payload
            }
        case WATCH_INTERVIEW:
            return {
                ...action.payload
            }
        case CLEAR_INTERVIEW:
            return {
                ...initialState
            }
        case CHANGE_INPUT_FIELD:
            return {
                ...state,
                [action?.payload?.name]: action.payload.value

            }
        case ON_TOOLS_CHANGE:
            return {
                ...state,
                languagesTools: action.payload

            }
        case SET_EXPIRE_DATE:
            return {
                ...state,
                expiresAt: new Date(new Date().getTime() + 1000 * 3600 * 24 * 10),
            }
        case ADD_MULTIPLE_QUESTIONS:
            // let tempArray = MergeTwoArrayFunc(state.interviewQuestions, action.payload)
            // if (tempArray.length) {
            return {
                ...state,
                interviewQuestions: action.payload
            }
        // } else {
        //     return state
        // }
        default:
            return state
    }
};

export default interviewReducer;