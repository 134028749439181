import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonItemDivider, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import API from '../../utils/API';
import Toaster from '../../utils/Toaster';
import { ToasterMessage } from '../../utils/ToasterMessages';
import { Link } from 'react-router-dom';
import { CustomRoutes } from '../../utils/CustomRoutes';

const LoginUser: React.FC = () => {
  const { email, verificationId } = useParams<{ email: string, verificationId: string }>();
  const history = useHistory();
  const [showToaster] = Toaster();
  const [passwordData, setPasswordData] = useState({
    password: '',
    email: email,
  });

  const handleInputChange = (event: any) => {
    setPasswordData((values) => ({
      ...values,
      [event.target.name]: event?.target.value
    }));
  }

  const setPassword = () => {
    console.log(passwordData);
    if (passwordData.password === '') {
      showToaster(ToasterMessage.setPassword.emptyPassword, 'warning', 'top');
      return;
    }
    if (passwordData.email === '') {
      showToaster(ToasterMessage.setPassword.emptyEmail, 'warning', 'top');
      return;
    }
    if (passwordData.password.length < 8) {
      showToaster(ToasterMessage.setPassword.passwordLengthError, 'warning', 'top');
      return;
    }

    API().post('/enterprise/login', { "data": passwordData }).then((data) => {
      let d = new Date();
      d.setTime(d.getTime() + (60 * 60 * 1000));
      // setCookie('token', data.data.token, { path: '/', expires: d },);
      sessionStorage.setItem('token', data.data.token);
      console.log(sessionStorage.getItem('token'));
      sessionStorage.setItem('userId', data.data.userId);
      // sessionStorage.setItem("token", data.data.token);
      // setCookie('token', data.data.token, { path: '/', maxAge: 60 * 60, secure: true, sameSite:'strict' });
      history.push(CustomRoutes.viewInterview);
      window.location.reload();

      console.log(data);
    }).catch(e => {
      console.log(e.response.data.message);
      if (e?.response?.data?.message) {
        showToaster(e.response.data.message, 'warning', 'top');
      }
    });

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary" className="d-flex justify-content-center align-items-center">
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{email}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            {/* <IonCol size-lg="3" size-md="4" size-sm="6" size="12"></IonCol> */}
            <IonCol size-md="4" size-sm="6" size="12"></IonCol>
            <IonCol className="d-flex flex-column mt-5" size-md="4" size-sm="6" size="12">
              <IonItemDivider color="primary">
                <IonTitle className="my-3">Sign In</IonTitle>
              </IonItemDivider>
              <IonItem>
                <IonInput value={email} disabled></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">User Email</IonLabel>
                <IonInput onIonInput={handleInputChange} name="email"></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type='password' onIonInput={handleInputChange} name="password"></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel>testBetaUser@yopmail.com</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>123123123</IonLabel>
              </IonItem>
              <IonButton className="mt-5" onClick={setPassword} color="primary">Submit</IonButton>
              <IonButton className="mt-2" onClick={() => { history.push("/register"); }} color="primary">Register</IonButton>
              <Link style={{ textDecoration: 'none' }} className="mt-4 pl-4" to={`/forgotPassword`}><IonLabel>Forgot Password</IonLabel></Link>
            </IonCol>
            <IonCol size-md="4" size-sm="6" size="12"></IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default LoginUser;
