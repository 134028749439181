import { IonAvatar, IonButton, IonCard, IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonTitle } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { calendar, createOutline } from 'ionicons/icons';
import { Virtuoso } from 'react-virtuoso';
import useAxios from 'axios-hooks';
import { useDispatch } from 'react-redux';
import { ADD_BUYER, ADD_SUPPLIER } from '../../redux/employee/emploeeTypes';
import BasicTable from './tables/BasicTable';
import SearchingTable from './tables/SearchingTable';
interface ContainerProps {
    name: string;
    getSupplier: any;
}

const SelectSupplier: React.FC<ContainerProps> = ({ name, getSupplier }) => {
    const [searchText, setSearchText] = useState("");
    const supplierModal = useRef<HTMLIonModalElement>(null);
    const dispath = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [state, setState] = useState(Date.now());
    const getSearchText = (searchText: string) => { // the callback. Use a better name
        console.log(searchText);
        setCurrentPage(1);
        setSearchText(searchText);
        refetch();
    };

    const pageChanged = (pageNumber: number) => {
        console.log(pageNumber);
        setCurrentPage(pageNumber);
    }

    const selectedData = (data: any) => {
        console.log(data);
        getSupplier(data?.data);
        dispath({ type: ADD_SUPPLIER, payload: data });
        supplierModal.current?.dismiss();
    }

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/client/searchClientByName', method: 'POST', data: {
            "req": {
                "name": searchText,
                page: currentPage,
                isSupplier: true,
                isBuyer: false,
                limit: 10
            }
        }
    });

    useEffect(() => {
        setState(Date.now());
    }, [data?.totalCount]);

    return (
        <>
            <div className="mt-4 text-decoration-none">
                <IonModal trigger="select-supplier-panel" ref={supplierModal}>
                    <IonContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12" size-sm>

                                    <SearchingTable searchPlaceholder={"Search Suppliers/Vendor"} getSearchText={getSearchText}></SearchingTable>
                                    {data?.data.length && <>
                                        <BasicTable
                                            key={state}
                                            name={'View Projects'}
                                            enableSelection={true}
                                            searchPlaceholderText={'Search Suppliers/Vendor'}
                                            headers={[
                                                { key: 'Select', label: 'Select' },
                                                { key: 'name', label: 'name' },
                                                { key: 'email', label: 'email' },
                                                { key: 'address', label: 'address' },
                                                { key: 'city', label: 'city' },
                                            ]}
                                            tableRows={data?.data}
                                            limit={data?.limit}
                                            currentPageProp={currentPage}
                                            totalCount={data?.totalCount}
                                            pageChanged={pageChanged}
                                            isLoading={loading}
                                            hasError={error}
                                            selectedData={selectedData}
                                        ></BasicTable>
                                    </>}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonModal>
                <IonChip color="primary" id="select-supplier-panel" style={{ width: '100%' }}>
                    <IonLabel className="d-flex justify-content-center">{name}</IonLabel>
                </IonChip>
            </div >
        </>
    );
};

export default SelectSupplier;
