import { IonButton, IonCol, IonContent, IonDatetime, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonTextarea, IonToggle } from '@ionic/react';
import CustomDate from '../common/CustomDate';
import '../ExploreContainer.css';
import API from '../../utils/API';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import SearchUser from '../common/SearchUser';
import SelectBuyer from '../common/SelectBuyer';
import SelectCostCenter from '../common/SelectCostCenter';
import SelectSupplier from '../common/SelectSupplier';
import {
    IonList,
} from '@ionic/react';
import axios from 'axios';
import { options } from 'ionicons/icons';
import Select from 'react-select';
import { ProjectType } from '../../utils/LanguageTech';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { CustomRoutes } from '../../utils/CustomRoutes';
interface ContainerProps {
    name: string;
}

const CreateProjectTask: React.FC<ContainerProps> = ({ name }) => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [projectTask, setProjectTask] = useState({
        title: '',
        description: '',
        projectId: '',
        startDate: '',
        endDate: '',
        manager: null,
        team: [],
        status: 'Not Started',
        budget: 0,
        expenses: 0,
        client: '',
        buyerClientName: 'Select Client Or Buyer',
        notes: '',
        "createdby": sessionStorage.getItem('userId'),
        "updatedby": sessionStorage.getItem('userId'),
        documents: [],
    });

    console.log(id);
    useEffect(() => {
        if (id) {
            API().post('/projectTask/getProjectTaskByID', {
                req: {
                    id: id
                }
            }).then((data: any) => {
                setProjectTask(data.data);
                console.log(data.data);
            });
        }
    }, [id])
    // const [project, setProject] = useState({
    //     // projectTaskName: '',
    //     // projectTaskStartDate: '',
    //     // projectTaskEndDate: '',
    //     // isInhouse: '',
    //     // supplier: '',
    //     // buyer: '',
    //     // costCenter: '',
    //     // employeeId: '',
    //     title: '',
    //     description: '',
    //     startDate: '',
    //     endDate: '',
    //     manager: '',
    //     team: [],
    //     status: 'Not Started',
    //     budget: '',
    //     expenses: '',
    //     client: '',
    //     notes: '',
    //     documents: [],
    // });

    const getEmployee = (managerId: string) => { // the callback. Use a better name
        setProjectTask((values) => ({
            ...values,
            'managerId': managerId,
        }));
    };

    const getBuyer = (buyerId: any) => {
        console.log(buyerId);
        setProjectTask((values) => ({
            ...values,
            'client': buyerId._id,
            'buyerClientName': buyerId.name
        }));
        console.log(projectTask);
    };

    const getCostCenter = (costCenterId: string) => { // the callback. Use a better name
        setProjectTask((values) => ({
            ...values,
            'costCenterId': costCenterId,
        }));
    };

    const handleInputChange = (event: any) => {
        console.log(event);
        // if (event.target.name = 'startDate') {
        //     event.target.value = new Date(event.target.value).toLocaleDateString("MM-DD-YYYY").substring(0, 10);
        // }
        setProjectTask((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
        console.log(projectTask);
    };
    const handleDropDownChange = (event: any, name: any) => {
        setProjectTask((values) => ({
            ...values,
            [name]: event,
        }));
    };

    const createProjectTask = () => {
        console.log(projectTask);
        if (id) {
            API().put('/projectTask/updateProjectTask', {
                req: {
                    ...projectTask
                }
            }).then((data: any) => {
                setProjectTask(data.data);
                console.log(data.data);
            });
        } else {
            API().post('/projectTask/saveProjectTask', { "req": projectTask }).then((data) => {
                console.log(data);
                history.push(CustomRoutes.createProjectTask);
            }, e => {
                console.log(e);
            });
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await createProjectTask();
        // redirect to project task list page
    };
    return (
        <>
            <IonRow>
                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Project Name</IonLabel>
                        <IonInput name="title" value={projectTask.title} onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="stacked">Start Date</IonLabel>
                        <IonInput name="startDate" value={moment(projectTask.startDate).format('YYYY-MM-DD')} type='date' onIonChange={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="stacked">End Date</IonLabel>
                        <IonInput name="endDate" value={moment(projectTask.endDate).format('YYYY-MM-DD')} type='date' onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="12" size="12" size-sm="12">
                    <IonTextarea name="description" rows={3} value={projectTask.description} onIonChange={handleInputChange} placeholder="Project Description" />
                </IonCol>

                {/* <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Status</IonLabel>
                        <IonSelect value={project.status} onIonChange={handleInputChange("status")}>
                            <IonSelectOption value="Not Started">Not Started</IonSelectOption>
                            <IonSelectOption value="In Progress">In Progress</IonSelectOption>
                            <IonSelectOption value="On Hold">On Hold</IonSelectOption>
                            <IonSelectOption value="Completed">Completed</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </IonCol> */}
                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="stacked">Project ID</IonLabel>
                        <IonInput value={projectTask.projectId} name="title" disabled ></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="12">

                    <IonLabel position="stacked">Project Status</IonLabel>
                    <Select
                        className='pt-2'
                        options={ProjectType}
                        value={{ label: projectTask.status }}
                        onChange={(newValue: any) => handleDropDownChange(newValue.label, 'status')}
                    />
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Project Budget</IonLabel>
                        <IonInput name="budget" type="number" value={projectTask.budget} onIonChange={handleInputChange} />
                    </IonItem>
                </IonCol>
                <IonCol size-md="4" size="12" size-sm="12"></IonCol>
                <IonCol size-md="4" size="12" size-sm="12"></IonCol>

                <IonItem>
                    {/* <IonLabel position="floating">Manager</IonLabel>
                    <IonSelect value={formData.manager} onIonChange={handleInputChange("manager")}>
                        {employees.map((employee) => (
                            <IonSelectOption key={employee._id} value={employee._id}>
                                {employee.name}
                            </IonSelectOption>
                        ))}
                    </IonSelect> */}
                </IonItem>
                {/* <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem className="mt-3">
                        <IonLabel>Is Internal Project</IonLabel>
                        <IonToggle value="Is Internal Project" color="primary" />
                    </IonItem>
                </IonCol> */}
            </IonRow>
            <IonRow>
                <IonCol md-size="4" size="12" size-sm="4">
                    <SelectBuyer name={projectTask.buyerClientName} getBuyer={getBuyer} ></SelectBuyer>
                </IonCol>
                {/* 
                <IonCol md-size="4" size="12" size-sm="4">
                    <SelectCostCenter name={'Select Manager'} getCostCenter={getCostCenter}></SelectCostCenter>
                </IonCol> */}
                {/* <IonCol md-size="4" size="12" size-sm="4">
                    <SearchUser name={'Select Project Manager'} getEmployee={getEmployee} ></SearchUser>
                </IonCol> */}
            </IonRow>
            <IonRow>
                {/* <IonCol size-md="4" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel>Is Internal Project</IonLabel>
                        <IonToggle value="Is Internal Project" color="primary" />
                    </IonItem>
                </IonCol> */}
            </IonRow>
            <IonRow className="d-flex justify-content-center mt-4">
                <IonCol size="12" size-sm >
                    {
                        id ?
                            <IonButton shape="round" fill="outline" onClick={createProjectTask}>Update Project</IonButton>
                            : <IonButton shape="round" fill="outline" onClick={createProjectTask}>Create Project</IonButton>
                    }
                </IonCol>
            </IonRow>
        </>
    );
};

export default CreateProjectTask;
