
import { useHistory } from 'react-router';
import CreateOrganization from '../../components/CreateOrganization';
import { IonPage, IonInput, IonLabel, IonButton, IonItem, IonItemDivider, IonGrid, IonRow, IonCol, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonDatetime, IonIcon, IonCard, IonPicker, IonToggle, IonTextarea, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import { Link } from 'react-router-dom';
import { chatbubble, attach, text } from 'ionicons/icons'

import { useState, useEffect } from 'react';
import { types } from 'util';
import { fontWeight } from '@mui/system';

const CreateTimesheet: React.FC = () => {
  const [file, setFile] = useState();
  const [tempSelected, setTempSelected] = useState("");
  const [dropdownTasks, setDropDownTasks] = useState({
    weekTitle: "Feb 28 2022- Mar 6 2022",
    employerID: "",
    managerID: "",
    isApproved: false,
    isRejected: false,
    defaultProjectTaskId: "",
    defaultHours: {},
    comments: [],
    imageURL: String,
    tasks: [
      {
        name: "paypal",
        startDate: "01jan2022",
        endDate: "31dec2022",
        isDefault: true,
        selected: true,
        imageURL: "",
        hours: {
          "mon18jul22": "0.00",
          "tue19jul22": "0.00",
          "wed20jul22": "0.00",
          "thu21jul22": "0.00",
          "fri22jul22": "0.00",
          "sat23jul22": "0.00",
          "sun24jul22": "0.00",
        },
        approverID: "",
        isApproved: false,
        isRejected: false,
        comments: [],
        isSubmitted: false,
      },
      {
        name: "Sick Leaves",
        startDate: "",
        endDate: "",
        isDefault: false,
        selected: false,
        imageURL: "",
        hours: {
          "mon18jul22": "0.00",
          "tue19jul22": "0.00",
          "wed20jul22": "0.00",
          "thu21jul22": "0.00",
          "fri22jul22": "0.00",
          "sat23jul22": "0.00",
          "sun24jul22": "0.00",
        },
        approverID: "",
        isApproved: false,
        isRejected: false,
        comments: [],
        isSubmitted: false,
      },
      {
        "name": "indeed",
        "startDate": "01jan2022",
        "endDate": "31dec2022",
        "isDefault": false,
        "selected": false,
        "imageURL": "",
        hours: {
        },
        approverID: "",
        isApproved: false,
        isRejected: false,
        comments: [],
        isSubmitted: false,
      },
      {
        "name": "apple",
        "startDate": "01jan2022",
        "endDate": "31dec2022",
        "imageURL": "",
        "isDefault": false,
        "selected": false,
        hours: {
        },
        approverID: "",
        isApproved: false,
        isRejected: false,
        comments: [],
        isSubmitted: false,
      }
    ],
  });
  // Feb 28- mar 6 2022

  const remove = (taskName: string) => {
    let prevArray = [...dropdownTasks.tasks];
    prevArray.forEach(task => {
      if (task.name === taskName) {
        task.selected = false;
      }
    })
    setDropDownTasks((values: any) => ({
      ...values,
      "tasks": prevArray
    }));
  };
  const addTasks = () => {
    let prevArray = [...dropdownTasks.tasks];
    prevArray.forEach(task => {
      if (task.name === tempSelected) {
        task.selected = true;
        task.hours = dropdownTasks.tasks[0].hours;
      }
    })
    setDropDownTasks((values: any) => ({
      ...values,
      "tasks": prevArray
    }));
  }
  const history = useHistory();

  const routeChange = () => {
    let path = `/employee/timesheets`;
    history.push(path);
  };
  const onFileChange = (event: any) => {
    // Update the state 
    setFile(event.target.files[0]);
  };

  const handleInputChange = (event: any) => {
    setTempSelected(event.target.value);
    // setTimesheet((values: any) => ({
    //   ...values,
    //   [event.target.name]: event.target.value,
    // }));
  };

  const handleDropdownChange = (event: any) => {
    setDropDownTasks((values: any) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    console.log(dropdownTasks);
  }, [dropdownTasks]);

  const addDefaultHours = (e: any) => {
    return "";
  }

  return (
    <>
      <IonCard className="p-3">
        <IonRow>
          <IonCol size='3'>Previous Week</IonCol>
          <IonCol size='6'>
            <IonTitle>{dropdownTasks.weekTitle}</IonTitle></IonCol>
          <IonCol size='3' className='float-right'>Next Week</IonCol>
        </IonRow>
      </IonCard>

      <IonCard className="p-3">
        <IonRow>
          <IonCol md-size="10" size="12" size-sm="10">
            <IonItem className="pt-3">
              <IonLabel>Select Task</IonLabel>
              <IonSelect okText="Select" name="selectTask" cancelText="Dismiss" onIonChange={handleInputChange}>
                {dropdownTasks.tasks.map((task, index) => {
                  return (
                    !task.selected && <IonSelectOption value={task.name}>{task.name}</IonSelectOption>
                  )
                })}
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol md-size="2" className='pt-3' size="12" size-sm="2">
            <IonButton className='mt-3' color='primary' onClick={addTasks} >Add Task</IonButton>
          </IonCol>
        </IonRow>
      </IonCard>

      {dropdownTasks.tasks.map((task, index) => {
        return (
          task.selected &&
          < IonCard className="p-3" key={index} >
            {/* <IonRow>
              <IonCol size='4'>{task.name}</IonCol>
              <IonCol size='4'>{task.startDate}</IonCol>
              <IonCol size='4'>{task.endDate}</IonCol>
            </IonRow> */}
            <IonRow >
              <IonCol size-sm="3" size-xs="12" className='ml-2' key={index}>
                <div className='mt-2'>
                  <IonText style={{ fontSize: '1.5em' }} color="primary" className="ion-text-uppercase mt-4">{task.name}</IonText>
                  <br></br>
                  <IonText>{task.startDate} - {task.endDate}</IonText>
                </div>

              </IonCol>
              {Object.keys(task.hours).map((day, index) => {
                return (
                  <IonCol size-sm="1" size-xs="12" className='ml-2' key={index}>
                    <IonItem>
                      <IonLabel position="floating">{day.substring(0, 3)}</IonLabel>
                      {/* <IonInput name={day} value={{task.hours[index]}} ></IonInput> */}
                      <IonInput name={day} value={0.00} ></IonInput>
                    </IonItem>
                  </IonCol>
                )
              })}
              {/* <IonCol size-sm="1.5" size-xs="12" className='ml-2'>
                    <IonItem>
                      <IonLabel position="floating">Mon</IonLabel>
                      <IonInput name="mon" ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size-sm='1.5' size-xs='12' className='ml-2'>
                    <IonItem>
                      <IonLabel position="floating">Tue</IonLabel>
                      <IonInput name="tue" ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size-sm='1.5' size-xs='12' className='ml-2'>
                    <IonItem>
                      <IonLabel position="floating">Wed</IonLabel>
                      <IonInput name="wed" ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size-sm='1.5' size-xs='12' className='ml-2'>
                    <IonItem>
                      <IonLabel position="floating">Thu</IonLabel>
                      <IonInput name="thu" ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size-sm='1.5' size-xs='12' className='ml-2'>
                    <IonItem>
                      <IonLabel position="floating">Fri</IonLabel>
                      <IonInput name="fri" ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size-sm='1.5' size-xs='12' className='ml-2'>
                    <IonItem>
                      <IonLabel position="floating">Sat</IonLabel>
                      <IonInput name="sat" ></IonInput>
                    </IonItem>
                  </IonCol>

                  <IonCol size-sm='1.5' size-xs='12' className='ml-2'>
                    <IonItem>
                      <IonLabel position="floating">Sun</IonLabel>
                      <IonInput name="sun" ></IonInput>
                    </IonItem>
                  </IonCol> */}
              <IonCol size-sm='1.5' size-xs='12' className='ml-2'>
                <IonItem>
                  <IonLabel position="floating">Total</IonLabel>
                  <IonInput name={task.name + "TotalHours"} value={0.00} ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <div className='mt-3'>
                <input type="file" value={""} />
              </div>
              <IonTextarea rows={2} cols={10} placeholder="Please enter your comments ..." value={""} ></IonTextarea>

              {/* <IonInput itemType='impp'></IonInput> */}
              {/* <IonButton className='mt-3' color='primary' onClick={onFileChange}>
                <IonIcon icon={attach} />
              </IonButton> */}
              <IonButton className='mt-3' color='primary' onClick={routeChange}>Save</IonButton>
              {!task.isDefault && <IonButton className='mt-3' color='primary' onClick={() => remove(task.name)}>Remove</IonButton>}
            </IonRow>
          </IonCard>
        )
      })}

      <IonButton className='mt-3 mr-4 ion-text-center ion-float-right pr-4' color='primary' onClick={routeChange}>Submit Timesheet</IonButton>
    </>

  );
};

export default CreateTimesheet;
