import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonItemDivider, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import API from '../../utils/API';
import Toaster from '../../utils/Toaster';
import { ToasterMessage } from '../../utils/ToasterMessages';
import { Link } from 'react-router-dom';

const RegisterUser: React.FC = () => {
  const history = useHistory();
  const [showToaster] = Toaster();
  const [passwordData, setPasswordData] = useState({
    fullName: '',
    email: '',
    orgName: '',
    password: '',
    phone: '',
  });

  const handleInputChange = (event: any) => {
    setPasswordData((values) => ({
      ...values,
      [event.target.name]: event?.target.value
    }));
  }

  const validateSubmit = () => {
    console.log(passwordData);
    if (passwordData.password === '') {
      showToaster(ToasterMessage.setPassword.emptyPassword, 'warning', 'top');
      return;
    }
    if (passwordData.password === '') {
      showToaster(ToasterMessage.setPassword.emptyPassword, 'warning', 'top');
      return;
    }
    if (passwordData.password === '') {
      showToaster(ToasterMessage.setPassword.emptyPassword, 'warning', 'top');
      return;
    }
    if (passwordData.email === '') {
      showToaster(ToasterMessage.setPassword.emptyEmail, 'warning', 'top');
      return;
    }
    if (passwordData.password.length < 8) {
      showToaster(ToasterMessage.setPassword.passwordLengthError, 'warning', 'top');
      return;
    }

    API().post('/enterprise/register', { "data": passwordData }).then((data) => {
      // setCookie('token', data.data.token, { path: '/' });
      // setCookie('token', data.data.token, { path: '/', maxAge: 60 * 60, secure: true, sameSite:'strict' });
      
      showToaster(ToasterMessage.register.verifyEmail, 'success', 'top');
      setTimeout(() => {
        history.push("/login");
      }, 2000)
      console.log(data);
    }).catch(e => {
      console.log(e.response.data.message);
      if(e?.response?.data?.message){
        showToaster(e.response.data.message, 'warning', 'top');
      }
    });

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary" className="d-flex justify-content-center align-items-center">
          <IonTitle>Request Beta Access</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
        </IonHeader>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            {/* <IonCol size-lg="3" size-md="4" size-sm="6" size="12"></IonCol> */}
            <IonCol size-md="4" size-sm="6" size="12"></IonCol>
            <IonCol className="d-flex flex-column mt-5" size-md="4" size-sm="6" size="12">
              <IonItemDivider color="primary">
                <IonTitle className="my-3">Register</IonTitle>
              </IonItemDivider>
              <IonItem>
                <IonLabel position="floating">User Full Name</IonLabel>
                <IonInput onIonInput={handleInputChange} name="fullName"></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">User Email</IonLabel>
                <IonInput onIonInput={handleInputChange} name="email"></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">User Mobile Number</IonLabel>
                <IonInput onIonInput={handleInputChange} name="phone"></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Organization Name</IonLabel>
                <IonInput onIonInput={handleInputChange} name="orgName"></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type='password' onIonInput={handleInputChange} name="password"></IonInput>
              </IonItem>
              <IonButton className="mt-5" onClick={validateSubmit} color="primary">Submit</IonButton>
              <Link style={{ textDecoration: 'none' }} className="mt-2"  to={`/login`} color="primary">Go To Login</Link>
              <Link style={{ textDecoration: 'none' }} className="mt-2 pl-4" to={`/forgotPassword`}><IonLabel>Forgot Password</IonLabel></Link>
            </IonCol>
            <IonCol size-md="4" size-sm="6" size="12"></IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default RegisterUser;
