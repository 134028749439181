import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ViewInterviewV2 from '../../components/interview/ViewInterviewV2';
import LoginComponent from '../../components/common/LoginComponent';

const ViewInterviewPage: React.FC = () => {
  let orgPageTitle = "View Interviews"
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{orgPageTitle}</IonTitle>
          <IonButtons slot='end'>
            <LoginComponent></LoginComponent>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{orgPageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ViewInterviewV2 name={'View Interviews'}></ViewInterviewV2>
      </IonContent>
    </IonPage>
  );
};

export default ViewInterviewPage;
