import { IonAccordion, IonAccordionGroup, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonDatetime, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import './../ExploreContainer.css';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import API from '../../utils/API';
import CustomDate from '../common/CustomDate';
import SearchUser from '../common/SearchUser';
import SelectBuyer from '../common/SelectBuyer';
import SelectCostCenter from '../common/SelectCostCenter';
import SelectProject from '../common/SelectProject';
import SelectSupplier from '../common/SelectSupplier';
import FileUpload from '../common/FileUpload';
import moment from 'moment';
import { add } from 'ionicons/icons';
import { CustomRoutes } from '../../utils/CustomRoutes';
interface ContainerProps {
    name: string;
}

const EducationDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [educations, setEducations] = useState([
        { degree: '', fieldOfStudy: '', school: '', startDate: '', endDate: '', address: '', city: '', country: '', zip: '', description: '', gpa: '' }]);

    const handleAddEducation = () => {
        setEducations([...educations, { degree: '', fieldOfStudy: '', school: '', startDate: '', endDate: '', address: '', city: '', country: '', zip: '', description: '', gpa: '' }]);
    };

    const removeEducation = (indexToBeRemoved: number) => {
        const updatedEducation = educations.filter((item, index) => index !== indexToBeRemoved);
        setEducations(updatedEducation);
    }

    const handleInputChange: any = (event: any, index: any) => {
        console.log(event);
        console.log(index);
        const { name, value } = event.target;
        const newEducations: any = [...educations];
        newEducations[index][name] = value;
        setEducations(newEducations);
    };

    const saveEducation = () => {
        console.log(educations);
        API().post('/education/saveEducation', { "req": educations }).then((data: any) => {
            console.log(data);
            // setEducation((values: any) => ({
            //     ...values,
            //     id: data.data._id
            // }));
            history.push(`/admin/create-education/${data.data.data._id}`);
        }, e => {
            console.log(e);
        });
    }

    useEffect(() => {
        (async () => {
            if (id) {
                const { data, status, statusText, headers, request, config } = await API().post('/education/getEducationByID', { "req": educations });
                console.log(data);
                console.log(data + "1" + status + "1" + statusText + "1" + headers + "1" + request + "1" + config);
                // await setEducation((values: any) => ({
                //     ...values,
                //     ...data
                // }));
            }
        })();
    }, [id])

    return (<>
        <IonGrid>
            {educations.map((education, index) => (
                <IonCard>
                    <IonCardHeader>
                        <IonRow>
                            <IonCol size-sm="9">
                                {/* <IonItem> */}
                                {/* <IonItemDivider> */}
                                <IonCardTitle className='pt-2' color="primary">
                                    Education Details
                                </IonCardTitle>
                                {/* </IonItemDivider> */}
                                {/* </IonItem> */}
                            </IonCol>
                            <IonCol size-sm="3">
                                <IonButton shape="round" fill="outline" onClick={() => removeEducation(index)}>Remove</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>

                    <IonRow className='m-2'>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Degree</IonLabel>
                                <IonInput
                                    name="degree"
                                    value={education?.degree}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Field of Study</IonLabel>
                                <IonInput
                                    name="fieldOfStudy"
                                    value={education?.fieldOfStudy}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">School</IonLabel>
                                <IonInput
                                    name="school"
                                    value={education?.school}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="stacked">Start Date</IonLabel>
                                <IonInput
                                    name="startDate"
                                    type="date"
                                    value={education?.startDate}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="stacked">End Date</IonLabel>
                                <IonInput
                                    name="endDate"
                                    type="date"
                                    value={education?.endDate}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                    required
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Address</IonLabel>
                                <IonInput
                                    name="address"
                                    value={education?.address}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">City</IonLabel>
                                <IonInput
                                    name="city"
                                    value={education?.city}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Country</IonLabel>
                                <IonInput
                                    name="country"
                                    value={education?.country}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Zip</IonLabel>
                                <IonInput
                                    name="zip"
                                    value={education?.zip}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">Description</IonLabel>
                                <IonInput
                                    name="description"
                                    value={education?.description}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size-md="4" size="12" size-sm="6">
                            <IonItem>
                                <IonLabel position="floating">GPA</IonLabel>
                                <IonInput
                                    name="gpa"
                                    value={education?.gpa}
                                    onIonInput={(event) => handleInputChange(event, index)}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonCard>
            ))}
            <IonRow>
                <IonCol>
                    <IonButton shape="round" fill="outline" onClick={() => saveEducation()}>Save Education</IonButton>
                </IonCol>
                <IonCol push="4" className='mb-4 pb-4'>
                    <IonFab onClick={() => handleAddEducation()}>
                        <IonFabButton>
                            <IonIcon icon={add}></IonIcon>
                        </IonFabButton>
                    </IonFab>
                </IonCol>
            </IonRow>
        </IonGrid>

    </>);
};

export default EducationDetails;
