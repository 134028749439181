export const CustomRoutes = {
    createEmployee: '/admin/create-employee',
    viewAllEmployees: '/admin/view-all-employees',
    viewEmployeeDashboard: '/admin/view-employee-dashboard',
    viewEmployee: '/admin/view-employee',
    dashboardHolidays: '/admin/dashboard-holidays',
    dashboardDepartments: '/admin/dashboard-departments',
    dashboardLeaves: '/admin/dashboard-leaves',
    createSupplier: '/admin/create-supplier',
    viewSupplier: '/admin/view-suppliers',
    createBuyer: '/admin/create-buyer',
    viewBuyer: '/admin/view-buyers',
    adminDashboardLeaves: '/admin/dashboard-leaves',
    adminDashboardHolidays: '/admin/dashboard-holidays',
    createProjectTask: '/admin/create-project',
    viewProjectTaskByID: '/admin/view-project',
    viewProjectTask: '/admin/view-projects',
    createDepartment: '/admin/create-department',
    createCostCenter: '/admin/create-cost-center',
    createInterview: '/admin/create-interview',
    viewInterview: '/admin/view-interview',
    createInterviewQuestionsSet: '/admin/create-interview-questions-set',
    viewInterviewDashboard: '/admin/view-interview-dashboard',
    // watchFinishedInterview: (interviewID) => { return `/admin/watch-finished-interview/${interviewID}` },
    watchFinishedInterview: '/admin/watch-finished-interview',
    addQuestion:'/admin/create-my-question',
    editQuestion:'/admin/edit-my-question',
    viewAllGlobalQuestions:'/admin/view-all-global-questions',
    viewAllMyQuestions:'/admin/view-all-my-questions',
    viewAllGlobalQuestionSets:'/admin/view-all-question-sets',
    myQuestionSets:'/admin/my-question-sets',
    createJob: '/admin/create-job',
    viewJob: `/admin/view-job`,
    viewAllJobs: '/admin/view-all-job',
    viewMyJobs: '/admin/view-my-jobs',

    addQuestionsToInterview: '/admin/add-questions-to-interview',
    employeeCreateTimesheet: '/employee/timesheets/create-time-sheet',
    createOrganization: '/super-admin/create-new-organization',

    jobsPage: () => {
        return '/jobs/create-new-job'
    }
}
