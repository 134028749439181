import { IonButton, IonButtons, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import API from '../../utils/API';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import ComponentLoading from '../common/ComponentLoading';
import Toaster from '../../utils/Toaster';
import { ToasterMessage } from '../../utils/ToasterMessages';
import ViewQuestions from './ViewQuestions';
import CreatableSelect from 'react-select/creatable';
import { LanguageTech, difficultyLevel } from '../../utils/LanguageTech';
import { useHistory, useParams } from 'react-router';
import { language } from 'ionicons/icons';
import { AnyNsRecord } from 'dns';
interface ContainerProps {
}

const modelStyle = {
    width: '100px !important',
    height: '100% !important'
}

const EditQuestion: React.FC<ContainerProps> = ({ }) => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [primaryLang, setPrimaryLang] = useState<any>({});
    const [defaultDifficulty, setDefaultDifficulty] = useState<any>({});
    // const [difficulty, setDifficulty] = useState();
    // const [question, setQuestion] = useState();
    // const [answer, setAnswer] = useState();

    const [question, setQuestion] = useState<any>({
        _id: id,
        question: "",
        answer: "",
        primaryLang: "",
        difficulty: "",
    });
    // const [interview, setInterview] = useState(question);
    const history = useHistory();

    const [showToaster] = Toaster();
    const handleInputChange = (event: any) => {
        setQuestion((values: any) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));

        console.log(event.target.value);
    };

    const onToolsChange = (event: any) => {
        if (!event) {
            return;
        }
        setQuestion((values: any) => ({
            ...values,
            primaryLang: event.label
        }))
    };
    const onDifficultyChange = (event: any) => {
        if (!event) {
            return;
        }
        setQuestion((values: any) => ({
            ...values,
            difficulty: event.label
        }))
    };


    const addInterviewQuestions = () => {
        // interview.optional = optionalChecked;
        setLoading(true);
        API().put('/interviewQuestions/updateInterviewQuestions', {
            "req": question
        }).then(
            (data => {
                showToaster(ToasterMessage.interviewQuestion.createdInterviewQuestionSuccess, 'success', 'top');
                history.goBack();
            })
        ).catch((err) => {
            console.log(err);
            showToaster(err.response || ToasterMessage.interviewQuestion.createdInterviewQuestionFailure, 'warning', 'top');
        }).finally(() => {
            setLoading(false);
            // setOptionalChecked(false);
            // updateRefreshView();
        });
    };

    useEffect(() => {
        API().post('/interviewQuestions/getInterviewQuestionsByID', {
            req: { id: id }
        }).then((data => {
            setQuestion({
                _id: id,
                question: data.data.question,
                answer: data.data.answer,
                primaryLang: data.data.primaryLang,
                difficulty: data.data.difficulty,
            });

            const primLang = LanguageTech.languages.find(lang => lang.value == data.data.primaryLang);
            const diff = difficultyLevel.levels.find(lang => lang.value == data.data.difficulty);
            onToolsChange(primLang);
            setPrimaryLang(primLang);
            onDifficultyChange(diff);
            setDefaultDifficulty(diff)
            console.log(data);
        })
        ).catch((err) => {
            showToaster(err.response || ToasterMessage.interviewQuestion.createdInterviewQuestionFailure, 'warning', 'top');
        }).finally(() => {
            setLoading(false);
        });
    }, [id]);

    if (loading) {
        return (
            <>
                <IonCardHeader color="primary">
                    <IonCardTitle className="">Update Your Question</IonCardTitle>
                </IonCardHeader>
                <ComponentLoading show={true}></ComponentLoading>
            </>
        )
    }

    return (
        <IonContent>
            <IonRow className='p-4'>
                <IonCol size-md="12" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Interview Question</IonLabel>
                        <IonTextarea value={question.question} name="question" onIonInput={handleInputChange} rows={2} placeholder="Please add your interview Question here ..."></IonTextarea>
                    </IonItem>
                </IonCol>
                <IonCol size-md="12" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Answer</IonLabel>
                        <IonTextarea value={question.answer} name="answer" onIonInput={handleInputChange} rows={4} placeholder="Please add your answer here ..."></IonTextarea>
                    </IonItem>
                </IonCol>

                <IonCol size-md="6" size="12" size-sm="12" className='mt-4' style={{ "zIndex": "100" }}>
                    <IonLabel position="floating">Skills</IonLabel>
                    {console.log(primaryLang)}
                    <CreatableSelect
                        placeholder="Select Tools and Technologies"
                        options={LanguageTech.languages}
                        // defaultValue={LanguageTech.languages.find(language => language.value.toLowerCase() == question.primaryLang.toLowerCase())}
                        // defaultInputValue={LanguageTech.languages.find(language => language.value === primaryLang)}
                        defaultValue={primaryLang}
                        value={primaryLang}
                        isMulti={false}
                        onChange={onToolsChange}
                    />
                </IonCol>

                <IonCol size-md="6" size="12" size-sm="12" className='mt-4' style={{ "zIndex": "100" }}>
                    <IonLabel position="floating">Difficulty Level</IonLabel>
                    <CreatableSelect
                        placeholder="Select Tools and Technologies"
                        options={[...difficultyLevel.levels]}
                        isMulti={false}
                        value={defaultDifficulty}
                        defaultValue={defaultDifficulty}
                        onChange={onDifficultyChange}
                    />
                </IonCol>
                {/* <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel>Need to interview by person?</IonLabel>
                        <IonToggle checked={optionalChecked} name="optional" onClick={() => setOptionalChecked(!optionalChecked)} color="primary" />
                    </IonItem>
                </IonCol>
                <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Interviewer Name </IonLabel>
                        <IonInput value={interview.interviewerName} name="interviewerName" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size-md="6" size="12" size-sm="12">
                    <IonItem>
                        <IonLabel position="floating">Interviewer Email </IonLabel>
                        <IonInput value={interview.interviewerEmail} name="interviewerEmail" onIonInput={handleInputChange}></IonInput>
                    </IonItem>
                </IonCol> */}
            </IonRow>

            <IonRow className="d-flex justify-content-center mt-4">
                <IonCol size="12" size-sm >
                    <IonButton shape="round" fill="outline" id="select-add-questions-panel" onClick={addInterviewQuestions} >Save Interview Question</IonButton>
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol size='12' size-sm>

                </IonCol>
            </IonRow>
        </IonContent >
    );
};

export default EditQuestion;
