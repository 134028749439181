import { IonButton, IonCol, IonGrid, IonIcon, IonLabel, IonRadio, IonRow, IonToolbar } from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import ComponentError from '../ComponentError';
import ComponentLoading from '../ComponentLoading';
import SearchingTable from './SearchingTable';

interface ContainerProps {
    name: string;
    headers: Array<any>;
    tableRows: Array<Array<any>>;
    searchPlaceholderText?: string;
    isLoading?: boolean;
    limit: number;
    currentPageProp: number;
    totalCount: number;
    pageChanged: any;
    getSearchText?: any;
    hasError?: any;
    selectedData?: any;
    enableSelection?: boolean;
}

const ionMediumColor = '#92949c';

const BasicTable: React.FC<ContainerProps> = ({ name, headers, tableRows, searchPlaceholderText, getSearchText, totalCount, limit, isLoading, currentPageProp, hasError, pageChanged, enableSelection, selectedData }): any => {

    const [totalPages, setTotalPages] = useState(Math.ceil(totalCount / limit));
    const [currentPage, setCurrentPage] = useState(currentPageProp);

    const changePage = (clickedPage: any) => {
        console.log("first" + clickedPage);
        console.log(clickedPage);
        setCurrentPage(clickedPage);
    }

    const getTotalPages = (totalRecords: number, limit: number) => {
        // console.log(Math.ceil((totalRecords / limit) + (totalRecords % limit)));
        setTotalPages(Math.ceil(totalRecords / limit));
        return totalPages;
    }

    const clickFirst = async (event: any) => {
        console.log("first");
        await setCurrentPage(1);
    };

    const clickLast = async (event: any) => {
        console.log("last");
        await setCurrentPage(totalPages);
    };

    const clickNext = async (event: any) => {
        console.log("next");
        await setCurrentPage(currentPage + 2);

    };

    const clickPrevious = async (event: any) => {
        console.log("previous");
        await setCurrentPage(currentPage - 2);

    };

    const selectData = (data:any) => {
        selectedData(data);
    }

    useEffect(() => {
        pageChanged(currentPage);
        console.log(currentPage);
    }, [currentPage]);

    if (isLoading) {
        return (
            <ComponentLoading key={12} show={true}></ComponentLoading>
        )
    }
    if (hasError) {
        return (
            <ComponentError key={13} show={true}></ComponentError>
        )
    }
    return (
        <IonGrid className="p-2">
            {/* <IonRow className="mb-2 mt-2 ml-4 ion-text-center">
                <IonTitle color="primary">{name}</IonTitle>
            </IonRow> */}
            {/* <SearchingTable searchPlaceholder={"Search Suppliers"} getSearchText={getSearchText}></SearchingTable> */}

            <IonToolbar color="primary">
                <IonRow>
                    {headers.map((header, index) => {
                        return (
                            <IonCol key={index} className="m-2">
                                <div className='ion-text-uppercase'>{header.key}</div>
                            </IonCol>
                        );
                    })}
                </IonRow>
            </IonToolbar>
            <div style={{ borderLeft: `1px solid ${ionMediumColor}`, borderRight: `1px solid ${ionMediumColor}` }}>
                {tableRows && tableRows.map((tableRow: any, index) => {
                    return (
                        <IonRow key={tableRow._id} className="custom-table-row" style={{ borderBottom: `1px solid ${ionMediumColor}` }}>
                            {headers.map((header, index) => {
                                return (
                                    <IonCol key={index} className="m-1">
                                        {/* {console.log(tableRow[header.label] + "")} */}
                                        {header.label == 'Select' ?
                                            <IonButton color="primary" size="small" onClick={() => selectData({ "data": tableRow, "type": header.key })}>{header.key}</IonButton>
                                            :
                                            Array.isArray(tableRow[header.label]) ?
                                                tableRow[header.label].map((itemObj: any) => {
                                                    return (
                                                        !!itemObj.value && itemObj.value
                                                    )
                                                })
                                                : String(tableRow[header.label])}
                                    </IonCol>
                                );
                            })}
                        </IonRow>
                    );
                })}
            </div>
            <>
                {tableRows && (limit > 0) && <IonGrid className="my-4">
                    <IonRow className="mb-2 ion-text-center" style={{ border: `1px solid ${ionMediumColor}` }}>
                        {
                            currentPage - 2 > 1 && (
                                <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={clickPrevious}>
                                    <IonIcon icon={chevronBack} color="primary"></IonIcon>
                                    {/* <IonLabel className="mx-1" color="primary">Previous</IonLabel> */}
                                </IonCol>
                            )
                        }
                        {
                            currentPage - 2 > 1 &&
                            (
                                <IonCol className="p-2" style={{ borderLeft: `1px solid ${ionMediumColor}`, borderRight: `1px solid ${ionMediumColor}` }} onClick={clickFirst}>
                                    <IonLabel className="mx-1" color="primary">1</IonLabel>
                                </IonCol>
                            )
                        }

                        {
                            currentPage - 2 >= 1 && (
                                <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage - 2)}>
                                    <IonLabel className="mx-1" color="primary">{currentPage - 2}</IonLabel>
                                </IonCol>
                            )
                        }
                        {
                            currentPage - 1 >= 1 && (
                                <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage - 1)}>
                                    <IonLabel className="mx-1" color="primary">{currentPage - 1}</IonLabel>
                                </IonCol>
                            )

                        }
                        <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage)}>
                            <IonLabel className="mx-1" color="">{currentPage}</IonLabel>
                        </IonCol>
                        {
                            currentPage + 1 <= totalPages && (
                                <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage + 1)}>
                                    <IonLabel className="mx-1" color="primary">{currentPage + 1}</IonLabel>
                                </IonCol>
                            )
                        }
                        {
                            currentPage + 2 <= totalPages && (
                                <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage + 2)}>
                                    <IonLabel className="mx-1" color="primary">{currentPage + 2}</IonLabel>
                                </IonCol>
                            )
                        }

                        {
                            currentPage + 2 < totalPages &&
                            (
                                <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={clickLast}>
                                    {/* <IonLabel className="mx-1" color="primary">Last</IonLabel> */}
                                    <IonLabel className="mx-1" color="primary">{totalPages}</IonLabel>
                                    {/* <IonB/adge className="mb-2" color="warning">{totalPages}</IonBadge> */}
                                </IonCol>
                            )
                        }
                        {
                            currentPage + 2 < totalPages && (
                                <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={clickNext}>
                                    <IonIcon icon={chevronForward} color="primary"></IonIcon>
                                </IonCol>
                            )
                        }
                    </IonRow>
                </IonGrid >
                }</>
        </IonGrid >
    );
};

export default BasicTable;
