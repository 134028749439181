import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonFabList, IonGrid, IonIcon, IonLabel, IonRadio, IonRow, IonToolbar } from '@ionic/react';
import { checkmarkOutline, chevronBack, chevronForward, chevronForwardCircle, chevronUpCircle, clipboard, colorPalette, createOutline, document, eyeOffOutline, filter, globe, pencilOutline, trashOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { watchInterview } from '../../../redux/interview/interviewActions';
import { WATCH_INTERVIEW } from '../../../redux/interview/interviewTypes';
import { CustomRoutes } from '../../../utils/CustomRoutes';
import ComponentError from '../ComponentError';
import ComponentLoading from '../ComponentLoading';
import SearchingTable from './SearchingTable';
import { ToasterMessage } from '../../../utils/ToasterMessages';
import { mail } from 'ionicons/icons';
import Toaster from '../../../utils/Toaster';
import API from '../../../utils/API';

interface ContainerProps {
    name: string;
    headers: Array<any>;
    tableRows: Array<Array<any>>;
    searchPlaceholderText?: string;
    isLoading?: boolean;
    limit: number;
    currentPageProp: number;
    totalCount: number;
    pageChanged: any;
    getSearchText?: any;
    hasError?: any;
    selectedData?: any;
    enableSelection?: boolean;
}

const ionMediumColor = '#92949c';

const BasicCards: React.FC<ContainerProps> = ({ name, headers, tableRows, searchPlaceholderText, getSearchText, totalCount, limit, isLoading, currentPageProp, hasError, pageChanged, enableSelection, selectedData }): any => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [totalPages, setTotalPages] = useState(Math.ceil(totalCount / limit));
    const [currentPage, setCurrentPage] = useState(currentPageProp);
    const [showToaster] = Toaster();

    const changePage = (clickedPage: any) => {
        console.log("first" + clickedPage);
        console.log(clickedPage);
        setCurrentPage(clickedPage);
    }

    const getTotalPages = (totalRecords: number, limit: number) => {
        // console.log(Math.ceil((totalRecords / limit) + (totalRecords % limit)));
        setTotalPages(Math.ceil(totalRecords / limit));
        return totalPages;
    }

    const clickFirst = async (event: any) => {
        console.log("first");
        await setCurrentPage(1);
    };

    const clickLast = async (event: any) => {
        console.log("last");
        await setCurrentPage(totalPages);
    };

    const clickNext = async (event: any) => {
        console.log("next");
        await setCurrentPage(currentPage + 2);

    };

    const clickPrevious = async (event: any) => {
        console.log("previous");
        await setCurrentPage(currentPage - 2);

    };

    const selectData = (data: any) => {
        selectedData(data);
    }

    const clickWatchInterviews = (interview: any) => {
        history.push(CustomRoutes.watchFinishedInterview + "/" + interview._id);
    }

    const copyInterviewURlClipboard = (interviewURl) => {
        navigator.clipboard.writeText(interviewURl).then(data => {
            showToaster(ToasterMessage.interview.copiedURL, 'success', 'top');
        })
    }

    const resendEmail = (id) => {
        API().post('/interview/reSendInterviewEmail', {
            req: {
                id: id
            }
        }).then(data => {
            console.log(data);
        });
    }

    useEffect(() => {
        pageChanged(currentPage);
        console.log(currentPage);
    }, [currentPage]);

    if (isLoading) {
        return (
            <ComponentLoading key={12} show={true}></ComponentLoading>
        )
    }
    if (hasError) {
        return (
            <ComponentError key={13} show={true}></ComponentError>
        )
    }
    return (
        <IonGrid className="p-2">
            <IonRow>
                {/* <IonRow className="mb-2 mt-2 ml-4 ion-text-center">
                <IonTitle color="primary">{name}</IonTitle>
            </IonRow> */}
                {/* <SearchingTable searchPlaceholder={"Search Suppliers"} getSearchText={getSearchText}></SearchingTable> */}

                {/* <IonToolbar color="primary">
                <IonRow>
                    {headers.map((header, index) => {
                        return (
                            <IonCol key={index} className="m-2">
                                <div className='ion-text-uppercase'>{header.key}</div>
                            </IonCol>
                        );
                    })}
                </IonRow>
            </IonToolbar> */}
                {/* <div style={{ borderLeft: `1px solid ${ionMediumColor}`, borderRight: `1px solid ${ionMediumColor}` }}> */}
                {/* <IonRow className="custom-table-row" style={{ borderBottom: `1px solid ${ionMediumColor}` }}> */}

                {tableRows && tableRows.map((tableRow: any, index) => {
                    return (

                        <IonCol key={tableRow._id} sizeLg='4' size-md="6" size-sm="12">
                            <IonCard key={tableRow._id} >
                                <IonCardHeader >
                                    {(tableRow["status"] !== ToasterMessage.interviewStatus.NOT_YET_STARTED) &&
                                        <IonButton shape='round' className='btm-sm' fill='outline' onClick={() => clickWatchInterviews(tableRow)}>
                                            <IonIcon slot="end" icon={eyeOffOutline} />
                                            Watch
                                        </IonButton>}
                                    {(tableRow["status"] === ToasterMessage.interviewStatus.NOT_YET_STARTED) &&
                                        <IonButton shape='round' className='btm-sm' fill='outline' onClick={() => selectData({ "data": tableRow, "type": "edit" })}>
                                            <IonIcon slot="end" icon={pencilOutline} />
                                            Edit
                                        </IonButton>}
                                </IonCardHeader>
                                <IonCardContent className=''>
                                    <IonCardSubtitle>{tableRow["candidateName"]}</IonCardSubtitle>
                                    <IonCardTitle>{tableRow["candidateEmail"]}</IonCardTitle>
                                    <IonCardSubtitle>{tableRow["jobRole"]}</IonCardSubtitle>
                                    <IonCardSubtitle>{tableRow["jobId"]}</IonCardSubtitle>

                                </IonCardContent>
                                <IonChip className='mb-4' outline={true} color={`${ToasterMessage.interviewStatusColor[tableRow["status"]]}`}>
                                    <IonLabel>{tableRow["status"]}</IonLabel>
                                </IonChip>
                                <IonChip className='mb-4' outline={true}>
                                    <IonLabel>{new Date(tableRow["createdAt"]).toLocaleString()}</IonLabel>
                                </IonChip>
                                <IonContent className='mt-4'>
                                    <IonFab slot="fixed" vertical="bottom" horizontal="end">
                                        <IonFabButton size="small">
                                            <IonIcon size="small" icon={chevronUpCircle}></IonIcon>
                                        </IonFabButton>
                                        <IonFabList side="top">
                                            <IonFabButton onClick={() => copyInterviewURlClipboard(tableRow["interviewURL"])}>
                                                <IonIcon icon={clipboard}></IonIcon>
                                            </IonFabButton>
                                            <IonFabButton onClick={() => resendEmail(tableRow["_id"])}>
                                                <IonIcon icon={mail}></IonIcon>
                                            </IonFabButton>
                                        </IonFabList>
                                        <IonFabList side="start">
                                            <IonFabButton size='small' onClick={() => selectData({ "data": tableRow, "type": "cancel" })} color="danger">
                                                <IonIcon icon={trashOutline} style={{ fontSize: "1em" }}>Delete</IonIcon>
                                            </IonFabButton>
                                        </IonFabList>
                                    </IonFab>
                                </IonContent>
                            </IonCard>
                        </IonCol>
                    );
                })}
            </IonRow>
            {/* </IonRow> */}
            {/* </div> */}
            <>
                {tableRows && (limit > 0) &&
                    <IonGrid className="my-4">
                        <IonRow className="mb-2 ion-text-center" style={{ border: `1px solid ${ionMediumColor}` }}>
                            {
                                currentPage - 2 > 1 && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={clickPrevious}>
                                        <IonIcon icon={chevronBack} color="primary"></IonIcon>
                                        {/* <IonLabel className="mx-1" color="primary">Previous</IonLabel> */}
                                    </IonCol>
                                )
                            }
                            {
                                currentPage - 2 > 1 &&
                                (
                                    <IonCol className="p-2" style={{ borderLeft: `1px solid ${ionMediumColor}`, borderRight: `1px solid ${ionMediumColor}` }} onClick={clickFirst}>
                                        <IonLabel className="mx-1" color="primary">1</IonLabel>
                                    </IonCol>
                                )
                            }

                            {
                                currentPage - 2 >= 1 && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage - 2)}>
                                        <IonLabel className="mx-1" color="primary">{currentPage - 2}</IonLabel>
                                    </IonCol>
                                )
                            }
                            {
                                currentPage - 1 >= 1 && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage - 1)}>
                                        <IonLabel className="mx-1" color="primary">{currentPage - 1}</IonLabel>
                                    </IonCol>
                                )

                            }
                            <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage)}>
                                <IonLabel className="mx-1" color="">{currentPage}</IonLabel>
                            </IonCol>
                            {
                                currentPage + 1 <= totalPages && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage + 1)}>
                                        <IonLabel className="mx-1" color="primary">{currentPage + 1}</IonLabel>
                                    </IonCol>
                                )
                            }
                            {
                                currentPage + 2 <= totalPages && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage + 2)}>
                                        <IonLabel className="mx-1" color="primary">{currentPage + 2}</IonLabel>
                                    </IonCol>
                                )
                            }

                            {
                                currentPage + 2 < totalPages &&
                                (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={clickLast}>
                                        {/* <IonLabel className="mx-1" color="primary">Last</IonLabel> */}
                                        <IonLabel className="mx-1" color="primary">{totalPages}</IonLabel>
                                        {/* <IonB/adge className="mb-2" color="warning">{totalPages}</IonBadge> */}
                                    </IonCol>
                                )
                            }
                            {
                                currentPage + 2 < totalPages && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={clickNext}>
                                        <IonIcon icon={chevronForward} color="primary"></IonIcon>
                                    </IonCol>
                                )
                            }
                        </IonRow>
                    </IonGrid >
                }</>
        </IonGrid >
    );
};

export default BasicCards;
