import {
  IonAvatar,
  IonChip,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, arrowDown, arrowForward, bookmarkOutline, clipboard, clipboardOutline, heartOutline, heartSharp, help, information, informationCircleOutline, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, personCircle, settingsOutline, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';
import { useEffect, useState } from 'react';
import { CustomRoutes } from '../utils/CustomRoutes';
import { list } from 'ionicons/icons';
import { people } from 'ionicons/icons';
import { airplane } from 'ionicons/icons';
import { hourglass } from 'ionicons/icons';
import { hourglassOutline } from 'ionicons/icons';
import { stopwatch } from 'ionicons/icons';
import { briefcase } from 'ionicons/icons';
import { partlySunny } from 'ionicons/icons';
import { rainy } from 'ionicons/icons';
import { checkmark } from 'ionicons/icons';
import { speedometer } from 'ionicons/icons';
import { cart } from 'ionicons/icons';
import { trendingUp } from 'ionicons/icons';
import { info } from 'console';
import { ReactComponent as Logo } from '../utils/svg/logo-color.svg';
import logoColor from '../utils/png/logo-no-background.png';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Dashboard',
    url: '/page/Inbox',
    iosIcon: speedometer,
    mdIcon: speedometer
  },
  {
    title: 'Employees',
    url: CustomRoutes.viewAllEmployees,
    iosIcon: people,
    mdIcon: people
  },
  {
    title: 'Projects',
    url: CustomRoutes.viewProjectTask,
    iosIcon: briefcase,
    mdIcon: briefcase
  },
  {
    title: 'Suppliers',
    url: CustomRoutes.viewSupplier,
    iosIcon: trendingUp,
    mdIcon: trendingUp
  },
  {
    title: 'Buyer',
    url: CustomRoutes.viewBuyer,
    iosIcon: cart,
    mdIcon: cart
  },
  {
    title: 'Timesheets',
    url: CustomRoutes.employeeCreateTimesheet,
    iosIcon: stopwatch,
    mdIcon: stopwatch
  },

  {
    title: 'Holidays',
    url: CustomRoutes.adminDashboardHolidays,
    iosIcon: rainy,
    mdIcon: rainy
  },
  {
    title: 'Leaves Portal',
    url: '/page/leaves',
    iosIcon: airplane,
    mdIcon: airplane
  }
];
const unAuthorizedRoutes = [
  "login", "forgotPassword", "register", "setPassword", "verification", "attend-interview", "verifyEmail"
]
const Menu: React.FC = () => {
  let appType = process.env.REACT_APP_APP_TYPE ? process.env.REACT_APP_APP_TYPE + "".toString() : "interviews,timesheets,admin";
  console.log(appType);
  const location = useLocation();
  const token = sessionStorage.getItem('token');
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleMySets, setIsVisibleMysets] = useState(false);
  const [isVisibleGlobal, setIsVisibleGlobal] = useState(false);
  // const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  let hasToken = token ? true : false;
  let unAuthorizedPage = false;
  console.log(location.pathname);
  unAuthorizedRoutes.forEach(route => {
    if (!unAuthorizedPage) {
      unAuthorizedPage = location.pathname.includes(route);
    }
  });

  const goHome = () => {
    history.push('/');
  }

  if (!hasToken && !unAuthorizedPage) {
    history.push('/login');
  }

  useEffect(() => {
    if (location.pathname.includes(CustomRoutes.addQuestion) ||
      location.pathname.includes(CustomRoutes.viewAllMyQuestions) ||
      location.pathname.includes(CustomRoutes.myQuestionSets) ||
      location.pathname.includes(CustomRoutes.createInterviewQuestionsSet)) {
      setIsVisibleMysets(true);
    }
    if (location.pathname.split('/')[2] == 'create-interview' ||
      location.pathname.split('/')[2] == 'view-interview') {
      setIsVisible(true);
    }

  }, []);

  return (
    <>
      {unAuthorizedPage ?
        <></> :
        <IonMenu contentId="main" type="overlay">
          <IonContent>
            <IonList className='pt-0 mt-2 ml-4' id="inbox-list">
              {/* <IonChip className="">
            <IonAvatar>
              <img src="https://ionicframework.com/docs/demos/api/avatar/avatar.svg" />
            </IonAvatar>
            <IonLabel>Vivekananda Cheerla</IonLabel>
          </IonChip> */}
              <IonItem className="">
                {/* <IonImg src={Logo}></IonImg> */}
                <img style={{ maxWidth: '70%', maxHeight: '45px', cursor: 'pointer' }} onClick={() => goHome()} className='ml-4 pl-4 mt-0 mb-2' src={logoColor} alt="My Icon" />
                {/* <IonAvatar slot="start">
                  <img src="https://ionicframework.com/docs/demos/api/avatar/avatar.svg" />
                </IonAvatar>
                <IonLabel>
                  <h3>Vivekananda Cheerla</h3>
                  <p>Employer ID  <IonChip color="primary">vivche9966</IonChip></p>
                  <LogoColor></LogoColor>
                </IonLabel> */}
              </IonItem>
              {appType.includes('timesheets') && appPages.map((appPage, index) => {
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon color='primary' slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })}

              {/* start interview sub menu */}
              {appType.includes('interviews') &&
                <>
                  <IonItem
                    lines='none'
                    button
                    onClick={() => {
                      setIsVisible(true);
                      if (isVisible === true) {
                        setIsVisible(false);
                      }
                    }}
                  >
                    <IonIcon color='primary' slot="start" icon={clipboard}></IonIcon>
                    <IonLabel>Interviews</IonLabel>
                    <IonIcon color='primary'
                      slot="end"
                      icon={isVisible ? arrowDown : arrowForward}
                    ></IonIcon>
                  </IonItem>
                  <IonMenuToggle autoHide={false}>
                    <IonList className='py-0 px-4'>
                      <IonItem
                        lines='none'
                        hidden={!isVisible}
                        button
                        routerLink="/admin/create-interview"
                      >

                        <IonLabel>Schedule Interview</IonLabel>
                      </IonItem>
                      <IonItem
                        lines='none'
                        hidden={!isVisible}
                        button
                        routerLink="/admin/view-interview"
                      >

                        <IonLabel>View Interviews</IonLabel>
                      </IonItem>
                    </IonList>
                  </IonMenuToggle>

                  {/* end interview sub menu */}


                  {/* start question Sets sub menu */}
                  <IonItem
                    lines='none'
                    button
                    onClick={() => {
                      setIsVisibleMysets(true);
                      if (isVisibleMySets === true) {
                        setIsVisibleMysets(false);
                      }
                    }}
                  >
                    <IonIcon color='primary' slot="start" icon={list}></IonIcon>
                    <IonLabel>My Question Sets</IonLabel>
                    <IonIcon color='primary'
                      slot="end"
                      icon={isVisibleMySets ? arrowDown : arrowForward}
                    ></IonIcon>
                  </IonItem>
                  <IonMenuToggle autoHide={false}>
                    <IonList className='py-0 px-4'>
                      {/* <IonItem
                        lines='none'
                        hidden={!isVisibleMySets}
                        routerLink={CustomRoutes.addQuestion}
                      >
                        <IonLabel>Create Question</IonLabel>
                      </IonItem>

                      <IonItem
                        lines="none"
                        hidden={!isVisibleMySets}
                        button
                        routerLink={CustomRoutes.createInterviewQuestionsSet}
                      >
                        <IonLabel>Create Questions Set</IonLabel>
                      </IonItem> */}

                      <IonItem
                        lines="none"
                        hidden={!isVisibleMySets}
                        button
                        routerLink={CustomRoutes.myQuestionSets}
                      >
                        <IonLabel>My Questions Set</IonLabel>
                      </IonItem>
                      {/* <IonItem
                    hidden={!isVisibleMySets}
                    button
                    routerLink={CustomRoutes.viewAllGlobalQuestions}
                  >
                    <IonLabel>All Questions</IonLabel>
                  </IonItem> */}
                      <IonItem
                        lines="none"
                        hidden={!isVisibleMySets}
                        routerLink={CustomRoutes.viewAllMyQuestions}
                      >
                        <IonLabel>My Questions</IonLabel>
                      </IonItem>
                    </IonList>
                  </IonMenuToggle>
                  {/* end question Sets sub menu */}

                  <IonItem className={location.pathname === CustomRoutes.viewAllGlobalQuestions ? 'selected' : ''} routerLink={CustomRoutes.viewAllGlobalQuestions} routerDirection="none" lines="none" detail={false}>
                    <IonIcon color='primary' slot="start" ios={information} md={information} />
                    <IonLabel>{'Global Questions'}</IonLabel>
                  </IonItem>
                </>}

              {/* start question Sets sub menu */}
              {/* <IonItem
                lines="none"
                button
                onClick={() => {
                  setIsVisibleGlobal(true);
                  if (isVisibleGlobal === true) {
                    setIsVisibleGlobal(false);
                  }
                }}
              >
                <IonIcon color='primary' slot="start" icon={informationCircleOutline}></IonIcon>
                <IonLabel>Global Sets</IonLabel>
                <IonIcon color='primary'
                  slot="end"
                  icon={isVisibleGlobal ? arrowDown : arrowForward}
                ></IonIcon>
              </IonItem>
              <IonMenuToggle autoHide={false}>
                <IonList className='py-0 px-4'>
                  <IonItem lines="none"
                    hidden={!isVisibleGlobal}
                    button
                    routerLink={CustomRoutes.viewAllGlobalQuestions}
                  >
                    <IonLabel>All Questions</IonLabel>
                  </IonItem>
                  <IonItem
                    lines="none"
                    hidden={!isVisibleGlobal}
                    routerLink={CustomRoutes.viewAllGlobalQuestionSets}
                  >
                    <IonLabel>Question Sets</IonLabel>
                  </IonItem>
                </IonList>
              </IonMenuToggle> */}
              {/* end question Sets sub menu */}


            </IonList>

            {appType.includes('admin') && <IonList id="labels-list">
              <IonListHeader>Labels</IonListHeader>
              {/* <IonItem lines="none">
                <IonIcon color='primary' slot="start" color="primary" icon={personCircle} />
                <Link to={`/employee`}><IonLabel>Employee</IonLabel></Link>
              </IonItem>
              <IonItem lines="none">
                <IonIcon color='primary' slot="start" color="primary" icon={bookmarkOutline} />
                <Link to={`/hr`}><IonLabel>HR</IonLabel></Link>
              </IonItem> */}
              <IonItem lines="none">
                <IonIcon color='primary' slot="start" icon={settingsOutline} />
                <Link to={`/admin`}><IonLabel>Admin</IonLabel></Link>
              </IonItem>

              {/* <IonItem lines="none">
            <IonIcon color='primary' slot="start" color="primary" icon={settingsOutline} />
            <Link to={`/super-admin`}><IonLabel>Super Admin</IonLabel></Link>
          </IonItem> */}
            </IonList>
            }
          </IonContent>
        </IonMenu>
      }
    </>
  );
};

export default Menu;
