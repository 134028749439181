import { IonCard, IonChip, IonContent, IonDatetime, IonItemDivider, IonLabel, IonModal, IonRow, IonTitle } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

interface ContainerProps {
    name: string;
    setDate?: string | null;
    getDate: any;
}

const CustomDate: React.FC<ContainerProps> = ({ name, setDate, getDate }) => {
    console.log(setDate);
    const [popoverDate, setPopoverDate] = useState(name);
    const dateRef = useRef();
    const changeDate = (value: any) => {
        console.log(value);
        setPopoverDate(formatDate(value));
        getDate(formatDate(value));
    }
    const formatDate = (value: string) => {
        // https://date-fns.org/docs/format
        return format(parseISO(value), 'yyyy-MM-dd');
        //1994-12-15T13:47:20Z
    };

    useEffect(() => {
        // let date = new Date().toISOString();
        if (setDate) {
            // let date = new Date(setDate.toString()).toISOString();
            setPopoverDate(setDate);
            // getDate(setDate);
        }
    }, [setDate])

    return (
        <>
            <div className="mt-4 text-decoration-none">
                <IonModal trigger="open-modal">
                    <IonContent>
                        <IonRow>
                            <IonItemDivider color="primary">
                                <IonTitle className="my-3">{name}</IonTitle>
                            </IonItemDivider>
                        </IonRow>
                        <IonCard className="d-flex justify-content-center">
                            <IonDatetime presentation='date'
                                showDefaultButtons
                                // value={popoverDate}
                                doneText={'SELECT DATE'}
                                onIonChange={ev => changeDate(ev.detail.value!)}
                            ></IonDatetime>
                        </IonCard>
                    </IonContent>
                </IonModal>
                <IonChip color="primary" id="open-modal" style={{ width: '100%' }}>
                    <IonLabel className="d-flex justify-content-center">{popoverDate}</IonLabel>
                </IonChip>
            </div>
        </>
    );
};

export default CustomDate;
