import { IonButton, IonCol, IonContent, IonDatetime, IonFab, IonFabButton, IonGrid, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonText } from '@ionic/react';
import useAxios from 'axios-hooks';
import { METHODS } from 'http';
//import './ExploreContainer.css';
import { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import API from '../../utils/API';
import Toaster from '../../utils/Toaster';
import { OrganizationMessages } from '../../utils/ToasterMessages';
import BasicTable from '../common/tables/BasicTable';
import CustomPagination from '../common/tables/CustomPagenation';
import SearchingTable from '../common/tables/SearchingTable';
import { useHistory } from 'react-router';
import { add, createOutline } from 'ionicons/icons';
import ComponentError from '../common/ComponentError';
import ComponentLoading from '../common/ComponentLoading';
import { CustomRoutes } from '../../utils/CustomRoutes';
import GridTable from '@nadavshaar/react-grid-table';
interface ContainerProps {
    name: string;
}

const ViewSuppliersV2: React.FC<ContainerProps> = ({ name }) => {
    const [searchText, setSearchText] = useState("");
    const history = useHistory();
    // const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [state, setState] = useState(Date.now());
    const getSearchText = (searchText: string) => { // the callback. Use a better name
        console.log(searchText);
        setCurrentPage(1);
        setSearchText(searchText);
        refetch();
    };

    const handleEditClick = (data: any) => {
        history.push(`/admin/create-supplier/${data._id}`)
    }

    const pageChanged = (pageNumber: number) => {
        console.log(pageNumber);
        setCurrentPage(pageNumber);
    }

    // const [{ data, loading, error }, refetch] = useAxios({
    //     url: '/client/searchClientByName', method: 'POST', data: {
    //         "req": {
    //             "name": searchText,
    //             page: currentPage,
    //             isSupplier: true,
    //             // isBuyer: true,
    //         }
    //     }
    // });

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/client/getAllSuppliers', method: 'GET'
    });

    console.log(data);
    const Username = ({ tableManager, value, field, data, column, colIndex, rowIndex }: any) => {
        return (
            <>
                <IonRow>
                    <IonCol>
                        <div className='rgt-text-truncate' style={{ marginLeft: 10, cursor: 'pointer' }}>{value}</div>
                    </IonCol>
                    <IonCol>
                        <IonIcon color='primary' size='medium' style={{ fontSize: '1.5rem', cursor: 'pointer' }} icon={createOutline} onClick={() => handleEditClick(data)} />
                    </IonCol>
                </IonRow>
            </>
        )
    };

    const columns = [
        {
            id: 1,
            field: 'name',
            label: 'Name',
            cellRenderer: Username
        },
        // { id: 'delete', name: 'del', render: ({ row }: any) => <button onClick={() => handleDeleteClick(row)}>Delete</button> },
        {
            id: 2,
            field: 'email',
            label: 'Email',
        },
        {
            id: 3,
            field: 'phone',
            label: 'Phone',
        },
        {
            id: 4,
            field: 'city',
            label: 'City',
            // getValue: ({ value }: any) => value.x + value.y
        }
    ];

    useEffect(() => {
        setState(Date.now());
    }, [data?.totalCount]);

    if (loading) {
        return (
            <ComponentLoading key={12} show={true}></ComponentLoading>
        )
    }
    if (error) {
        return (
            <ComponentError key={13} show={true}></ComponentError>
        )
    }

    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol offset="9" className='mb-4 pb-4'>
                        <IonFab style={{ 'display': 'flex' }} onClick={() => { history.push(CustomRoutes.createSupplier) }}>
                            <IonText style={{ 'display': 'flex', marginTop: '1em', marginRight: '1em' }} color={'primary'}>Add Supplier</IonText>
                            <IonFabButton>
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </IonFab>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className='mt-4'>
                        <GridTable columns={columns} rows={data}></GridTable></IonCol>
                </IonRow>
            </IonGrid>
        </IonContent >
    );
};

export default ViewSuppliersV2;
