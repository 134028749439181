import { IonAvatar, IonButton, IonCard, IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonTitle } from '@ionic/react';
import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import ViewProjectTask from '../ProjectTask/ViewProjectTask';
interface ContainerProps {
    name: string;
    getProject: any;
}

const SelectProject: React.FC<ContainerProps> = ({ name, getProject }) => {
    const [popoverDate, setPopoverDate] = useState(name);
    const changeDate = (value: string) => {
        setPopoverDate(formatDate(value));
        getProject(popoverDate);
    }
    const formatDate = (value: string) => {
        return format(parseISO(value), 'MMM dd yyyy');
    };

    const [searchUser, setSearchUser] = useState({
        firstName: '',
        lastName: '',
        employeeID: '',
    });

    const handleInputChange = (event: any) => {
        setSearchUser((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    return (
        <>
            <div className="mt-4 text-decoration-none">
                <IonModal trigger="select-project-panel">
                    <IonContent>
                        <IonGrid>
                            <ViewProjectTask name={'View ALL Projects'}></ViewProjectTask>
                        </IonGrid>
                    </IonContent>
                </IonModal>
                <IonChip color="primary" id="select-project-panel" style={{ width: '100%' }}>
                    <IonLabel className="d-flex justify-content-center">{popoverDate}</IonLabel>
                </IonChip>
            </div>
        </>
    );
};

export default SelectProject;
