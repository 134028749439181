import { ADD_BUYER, ADD_SUPPLIER, UPDATE_BUYER } from "./emploeeTypes"

const employeeReducer = (state = {}, action) => {
    console.log(action);
    switch (action.type) {
        case ADD_BUYER:
            return {
                ...state,
                buyer: action.payload
            }
        case ADD_SUPPLIER:
            return {
                ...state,
                supplier: action.payload
            }
        case UPDATE_BUYER:
            return {
                ...state,
                buyer: action.payload
            }
        default:
            return state
    }
};

export default employeeReducer;