import { IonBackButton, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { createOutline, pin, walk, warning, wifi, wine } from 'ionicons/icons';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CreateDepartments from '../../components/CreateDepartments';
import CreateHolidays from '../../components/holidays/CreateHolidays';
import CreateProjectTask from '../../components/ProjectTask/CreateProjectTask';
import ExploreContainer from '../../components/ExploreContainer';
import { CustomRoutes } from '../../utils/CustomRoutes';

const CreateProjectTaskPage: React.FC = () => {
  const { name } = useParams<{ name: string; }>();
  const history = useHistory();

  const routeChange = (path: string) => {
    history.push(path);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-md="12" size-sm="12" size-xs="12" size='12'>
              <IonCard>
                <CreateProjectTask name="Create Departments"></CreateProjectTask>
              </IonCard>
            </IonCol>
            <IonCol size-md="12" size-sm="12" size-xs="12" size='12'>
             
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default CreateProjectTaskPage;