import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonLabel, IonRadio, IonRow, IonToolbar } from '@ionic/react';
import { chevronBack, chevronForward, createOutline, trashOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { watchInterview } from '../../../redux/interview/interviewActions';
import { WATCH_INTERVIEW } from '../../../redux/interview/interviewTypes';
import { CustomRoutes } from '../../../utils/CustomRoutes';
import ComponentError from '../ComponentError';
import ComponentLoading from '../ComponentLoading';
import SearchingTable from './SearchingTable';

interface ContainerProps {
    name: string;
    headers: Array<any>;
    tableRows: Array<Array<any>>;
    searchPlaceholderText?: string;
    isLoading?: boolean;
    limit: number;
    currentPageProp: number;
    totalCount: number;
    pageChanged: any;
    getSearchText?: any;
    hasError?: any;
    selectedData?: any;
    enableSelection?: boolean;
}

const ionMediumColor = '#92949c';

const JobsCards: React.FC<ContainerProps> = ({ name, headers, tableRows, searchPlaceholderText, getSearchText, totalCount, limit, isLoading, currentPageProp, hasError, pageChanged, enableSelection, selectedData }): any => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [totalPages, setTotalPages] = useState(Math.ceil(totalCount / limit));
    const [currentPage, setCurrentPage] = useState(currentPageProp);

    const changePage = (clickedPage: any) => {
        console.log("first" + clickedPage);
        console.log(clickedPage);
        setCurrentPage(clickedPage);
    }

    const getTotalPages = (totalRecords: number, limit: number) => {
        // console.log(Math.ceil((totalRecords / limit) + (totalRecords % limit)));
        setTotalPages(Math.ceil(totalRecords / limit));
        return totalPages;
    }

    const clickFirst = async (event: any) => {
        console.log("first");
        await setCurrentPage(1);
    };

    const clickLast = async (event: any) => {
        console.log("last");
        await setCurrentPage(totalPages);
    };

    const clickNext = async (event: any) => {
        console.log("next");
        await setCurrentPage(currentPage + 2);

    };

    const clickPrevious = async (event: any) => {
        console.log("previous");
        await setCurrentPage(currentPage - 2);

    };

    const selectData = (data: any) => {
        selectedData(data);
    }

    const clickWatchInterviews = (interview: any) => {
        dispatch({
            type: WATCH_INTERVIEW,
            payload: interview
        })
        history.push(CustomRoutes.watchFinishedInterview + "/" + interview._id);
    }

    const goToViewJobPage = (jobData: any) => {
        history.push({
            pathname: CustomRoutes.viewJob,
            search: `jobId=${jobData._id}`,
            state: jobData
        });
    }

    useEffect(() => {
        pageChanged(currentPage);
        console.log(currentPage);
    }, [currentPage]);

    if (isLoading) {
        return (
            <ComponentLoading key={12} show={true}></ComponentLoading>
        )
    }
    if (hasError) {
        return (
            <ComponentError key={13} show={true}></ComponentError>
        )
    }
    return (
        <IonGrid className="p-2">
            <IonRow>
                {/* <IonRow className="mb-2 mt-2 ml-4 ion-text-center">
                <IonTitle color="primary">{name}</IonTitle>
            </IonRow> */}
                {/* <SearchingTable searchPlaceholder={"Search Suppliers"} getSearchText={getSearchText}></SearchingTable> */}

                {/* <IonToolbar color="primary">
                <IonRow>
                    {headers.map((header, index) => {
                        return (
                            <IonCol key={index} className="m-2">
                                <div className='ion-text-uppercase'>{header.key}</div>
                            </IonCol>
                        );
                    })}
                </IonRow>
            </IonToolbar> */}
                {/* <div style={{ borderLeft: `1px solid ${ionMediumColor}`, borderRight: `1px solid ${ionMediumColor}` }}> */}
                {/* <IonRow className="custom-table-row" style={{ borderBottom: `1px solid ${ionMediumColor}` }}> */}

                {tableRows && tableRows.map((tableRow: any, index) => {
                    return (
                        <IonCard key={tableRow._id} onClick={() => { goToViewJobPage(tableRow) }} >
                            <IonCardContent>
                                {/* <IonCardHeader>
                                    {(tableRow["status"] === "ended") ?
                                        <IonButton className='btm-sm' onClick={() => clickWatchInterviews(tableRow)}>Watch Interview</IonButton> :
                                        <IonButton className='btm-sm' onClick={() => selectData({ "data": tableRow, "type": "edit" })}>Edit Job</IonButton>}
                                    <div className='ion-float-right'> */}
                                {/* <IonIcon icon={createOutline} color="primary" size="large" >Edit</IonIcon> */}
                                {/* <IonIcon icon={trashOutline} color="primary" size="large" onClick={() => selectData({ "data": tableRow, "type": "cancel" })}>Delete</IonIcon>
                                    </div>
                                </IonCardHeader> */}
                                <IonCardContent>
                                    <IonCardTitle color='primary'>
                                        <span className='ion-text-wrap'>
                                            {tableRow["jobTitle"]}
                                        </span>
                                        <span className='ion-float-right ion-padding-start'>
                                            <IonIcon icon={createOutline} color="primary" size="small" >Edit</IonIcon>
                                            <IonIcon icon={trashOutline} color="primary" size="small" onClick={() => selectData({ "data": tableRow, "type": "cancel" })}>Delete</IonIcon>
                                        </span>
                                    </IonCardTitle>
                                    <IonCardSubtitle>{tableRow["jobLocation"]}</IonCardSubtitle>
                                    <IonCardSubtitle>{tableRow["salary"] && tableRow["salary"]} {tableRow["perHourBillingRate"] && "| " + tableRow["perHourBillingRate"]}</IonCardSubtitle>
                                    <IonCardSubtitle>{new Date(tableRow["createdAt"]).toLocaleString()}</IonCardSubtitle>
                                </IonCardContent>
                            </IonCardContent>
                        </IonCard>
                    );
                })}
            </IonRow>
            {/* </IonRow> */}
            {/* </div> */}
            <>
                {tableRows && (limit > 0) &&
                    <IonGrid className="my-4">
                        <IonRow className="mb-2 ion-text-center" style={{ border: `1px solid ${ionMediumColor}` }}>
                            {
                                currentPage - 2 > 1 && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={clickPrevious}>
                                        <IonIcon icon={chevronBack} color="primary"></IonIcon>
                                        {/* <IonLabel className="mx-1" color="primary">Previous</IonLabel> */}
                                    </IonCol>
                                )
                            }
                            {
                                currentPage - 2 > 1 &&
                                (
                                    <IonCol className="p-2" style={{ borderLeft: `1px solid ${ionMediumColor}`, borderRight: `1px solid ${ionMediumColor}` }} onClick={clickFirst}>
                                        <IonLabel className="mx-1" color="primary">1</IonLabel>
                                    </IonCol>
                                )
                            }

                            {
                                currentPage - 2 >= 1 && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage - 2)}>
                                        <IonLabel className="mx-1" color="primary">{currentPage - 2}</IonLabel>
                                    </IonCol>
                                )
                            }
                            {
                                currentPage - 1 >= 1 && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage - 1)}>
                                        <IonLabel className="mx-1" color="primary">{currentPage - 1}</IonLabel>
                                    </IonCol>
                                )

                            }
                            <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage)}>
                                <IonLabel className="mx-1" color="">{currentPage}</IonLabel>
                            </IonCol>
                            {
                                currentPage + 1 <= totalPages && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage + 1)}>
                                        <IonLabel className="mx-1" color="primary">{currentPage + 1}</IonLabel>
                                    </IonCol>
                                )
                            }
                            {
                                currentPage + 2 <= totalPages && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={() => changePage(currentPage + 2)}>
                                        <IonLabel className="mx-1" color="primary">{currentPage + 2}</IonLabel>
                                    </IonCol>
                                )
                            }

                            {
                                currentPage + 2 < totalPages &&
                                (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={clickLast}>
                                        {/* <IonLabel className="mx-1" color="primary">Last</IonLabel> */}
                                        <IonLabel className="mx-1" color="primary">{totalPages}</IonLabel>
                                        {/* <IonB/adge className="mb-2" color="warning">{totalPages}</IonBadge> */}
                                    </IonCol>
                                )
                            }
                            {
                                currentPage + 2 < totalPages && (
                                    <IonCol className="p-2" style={{ borderRight: `1px solid ${ionMediumColor}` }} onClick={clickNext}>
                                        <IonIcon icon={chevronForward} color="primary"></IonIcon>
                                    </IonCol>
                                )
                            }
                        </IonRow>
                    </IonGrid >
                }</>
        </IonGrid >
    );
};

export default JobsCards;