
import { useHistory } from 'react-router';
import { IonPage, IonLabel, IonButton, IonItem, IonRow, IonCol, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonIcon, IonCard, IonToggle } from '@ionic/react';
import { Data } from './DummyData';
import { useState } from 'react';


const ApprovedTimesheets: React.FC = () => {


    const [details, setDetails] = useState(Data);

    const history = useHistory();

    const routeChange = () => {
        let path = `/employee/timesheets`;
        history.push(path);
    }



    return (

        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonButtons slot='end' >
                        <IonButton color='primary' onClick={routeChange}>TIMESHEET</IonButton>

                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {details.map((detail) => (
                    <IonToolbar color="primary">
                        <IonTitle>{detail.title}</IonTitle>
                        <IonCard>

                            <IonRow>
                                <IonCol size='3'>
                                    <div>{detail.type}</div>
                                </IonCol>
                                <IonCol size='1'>
                                    <div>{detail.day[0]}</div>
                                </IonCol>
                                <IonCol size='1'>
                                    <div>{detail.day[1]}</div>
                                </IonCol>
                                <IonCol size='1'>
                                    <div>{detail.day[2]}</div>
                                </IonCol>
                                <IonCol size='1'>
                                    <div>{detail.day[3]}</div>
                                </IonCol>
                                <IonCol size='1'>
                                    <div>{detail.day[4]}</div>
                                </IonCol>
                                <IonCol size='1'>
                                    <div>{detail.day[5]}</div>
                                </IonCol>
                                <IonCol size='1' >
                                    <div>{detail.day[6]}</div>
                                </IonCol>
                                <IonCol size='1'>
                                    <div>Total</div>
                                </IonCol>

                                <IonCol size='3' className='mt-3'>
                                    {detail.id} {detail.company}
                                    <IonRow>{detail.designation}</IonRow>
                                </IonCol>
                                <IonCol className='mt-4' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-4' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-4' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-4' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-4' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-4' size='1'>
                                    <div>{detail.time[4]}</div>
                                </IonCol>
                                <IonCol className='mt-4' size='1'>
                                    <div>{detail.time[4]}</div>
                                </IonCol>
                                <IonCol className='mt-4' size='1' >
                                    <div>{detail.total}</div>
                                </IonCol>

                                <IonCol size='3' className='mt-3'>TOTAL</IonCol>
                                <IonCol className='mt-3' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-3' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-3' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-3' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-3' size='1'>
                                    <div>{detail.time[3]}</div>
                                </IonCol>
                                <IonCol className='mt-3' size='1'>
                                    <div>{detail.time[4]}</div>
                                </IonCol>
                                <IonCol className='mt-3' size='1'>
                                    <div>{detail.time[4]}</div>
                                </IonCol>
                                <IonCol className='mt-3' size='1' >
                                    <div>{detail.total}</div>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                
                                <IonItem className='mt-3'>
                                    
                                    <IonLabel color='success'>APPROVED</IonLabel>
                                </IonItem>
                               
                            </IonRow>
                        </IonCard>
                    </IonToolbar>

                ))}
            </IonContent>

        </IonPage>
    );
};

export default ApprovedTimesheets;
