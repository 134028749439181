import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import ViewInterview from '../../components/interview/ViewInterview';
import ViewInterviewV2 from '../../components/interview/ViewInterviewV2';
import useAxios from 'axios-hooks';
import LoginComponent from '../../components/common/LoginComponent';

const AttendInterview: React.FC = () => {
    let orgPageTitle = "Attend Interview"
    const { id } = useParams<{ id: string; }>();

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/interview/getInterviewByID', method: 'POST', data: {
            "req": {
                "_id": id,
            }
        }
    });
    console.log(data);

    useEffect(() => {
        console.log(data);
    }, []);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>{orgPageTitle}</IonTitle>
                    <IonButtons slot='end'>
                        <LoginComponent></LoginComponent>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar color="primary">
                        <IonTitle size="large">{orgPageTitle}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol md-size="6" size="12" size-sm="6">

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default AttendInterview;
