import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonReorder, IonReorderGroup, IonRow, IonText, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import ComponentLoading from '../common/ComponentLoading';
import { reorderFourOutline } from 'ionicons/icons';
import Toaster from '../../utils/Toaster';
import useAxios from 'axios-hooks';
import CreatableSelect from 'react-select/creatable';
import { LanguageTech } from '../../utils/LanguageTech';
import { useHistory, useParams } from 'react-router';
import { CustomRoutes } from '../../utils/CustomRoutes';
import API from '../../utils/API';
import { url } from 'inspector';
interface ContainerProps {
    name: string;
    selectedHoliday: any;
    reloadView: boolean;
}


const CreateInterviewQuestionsSet: React.FC<{}> = () => {
    let orgPageTitle = "Add Questions To Interview";
    const { id } = useParams<{ id: string }>();
    console.log(id);
    const history = useHistory();
    const [showToaster] = Toaster();
    const [interviewSet, setInterviewSet] = useState<any>({
        interviewQuestionsSetName: '',
        jobDescription: '',
        interviewQuestions: [],
        languagesTools: [],
        "createdby": sessionStorage.getItem('userId'),
        "updatedby": sessionStorage.getItem('userId'),
    });
    const [fetchedInterviewQuestions, setFetchedInterviewQuestions] = useState({
        questions: []
    });

    const [interviewQuestionsStore, setinterviewQuestionsStore] = useState({
        questions: []
    });

    const handleInputChange = (event: any) => {
        setInterviewSet((values: any) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const SaveInterviewQuestions = async () => {
        // let inteviewQuestionSetIds = interviewSet.interviewQuestions.map((question: any) => { return question._id.toString() });
        API().post('/interviewQuestionsSet/saveInterviewQuestionsSet', {
            req: {
                ...interviewSet
            }
        }).then((data: any) => {
            history.push(CustomRoutes.myQuestionSets);
        }).catch((e: any) => {
        })

    }

    const removeAll = () => {
        setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...values.questions, ...interviewSet.interviewQuestions]
        }));
        setInterviewSet((values: any) => ({
            ...values,
            interviewQuestions: []
        }))
    }

    const selectQuestion = (interviewQuestion: any) => {
        setInterviewSet((values: any) => ({
            ...values,
            interviewQuestions: [...values.interviewQuestions, interviewQuestion]
        }));
        setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...values.questions.filter(
                (question: any) => { return question._id != interviewQuestion._id }
            )]
        }));
    }

    const removeQuestion = (interviewQuestion: any) => {
        setInterviewSet((values: any) => ({
            ...values,
            interviewQuestions: [...values.interviewQuestions.filter(
                (question: any) => { return question._id != interviewQuestion._id }
            )]
        }));
        setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...values.questions, interviewQuestion]
        }));
    }

    const reorderQuestions = (event: any) => {
        event.detail.complete();
    }

    const onInterviewSetToolsChange = (event: any) => {
        setInterviewSet((values: any) => ({
            ...values,
            languagesTools: [...event]
        }));
    };

    const onToolsChange = (event: any) => {
        let toolsArray = event.map((item: any) => { return item.value.toLowerCase(); });
        let selectedInterviewQuestionsID = interviewSet.interviewQuestions.map((item: any) => { return item._id });
        if (toolsArray.length) {
            setFetchedInterviewQuestions((values) => ({
                ...values,
                questions: interviewQuestionsStore.questions
                    .filter(
                        (question: any) => {
                            console.log(question.primaryLang.toLowerCase());
                            return toolsArray.includes(question.primaryLang.toLowerCase())
                        })
                    .filter(
                        (question: any) => { return !selectedInterviewQuestionsID.includes(question._id) }
                    )
            }))
        } else {
            setFetchedInterviewQuestions((values) => ({
                ...values,
                questions: interviewQuestionsStore.questions
            }))
        }
    };

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/interviewQuestions/getAllInterviewQuestions',
        method: 'GET',
    });

    useEffect(() => {
        (async () => {
            if (id) {
                const { data, status, statusText, headers, request, config } = await API().post('/interviewQuestionsSet/getInterviewQuestionsSetByID', {
                    "req": {
                        id: id
                    }
                });
                await setInterviewSet((values: any) => ({
                    ...values,
                    ...data,
                }));
            }
        })();
    }, [id])

    useEffect(() => {
        data && setFetchedInterviewQuestions((values: any) => ({
            ...values,
            questions: [...data]
        }));
        data && setinterviewQuestionsStore((values: any) => ({
            ...values,
            questions: [...data]
        }));
    }, [data]);

    if (loading) {
        return (
            <ComponentLoading show={true}></ComponentLoading>
        )
    }

    return (
        <IonContent fullscreen>
            <IonHeader collapse="condense">
                <IonToolbar color="primary">
                    <IonTitle size="large">{orgPageTitle}</IonTitle>
                    <IonTitle>Done</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonItemGroup>
                <IonRow class='mt-1'>
                    <IonCol class='col-lg-6'>
                        <IonItem className='mb-4'>
                            <IonLabel position="floating">Interview Questions Set Name</IonLabel>
                            <IonInput name={"interviewQuestionsSetName"} onIonInput={handleInputChange} type="text" value={interviewSet?.interviewQuestionsSetName} ></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol class='col-lg-6'>
                        <IonLabel className='ml-2 pl-4'>Select Skills Questions Set</IonLabel>
                        <CreatableSelect
                            styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            placeholder="Select Tools and Technologies"
                            options={LanguageTech.languages}
                            value={interviewSet.languagesTools}
                            isMulti={true}
                            onChange={onInterviewSetToolsChange}
                        />
                    </IonCol>
                    <IonCol class='col-lg-12'>
                        <IonTextarea style={{ "border": "1px solid #adb5bd" }} onIonInput={handleInputChange} value={interviewSet?.jobDescription} name={"jobDescription"} rows={3} placeholder="Please add your job description here ..."></IonTextarea>
                        <IonButton expand="block" shape="round" className='mt-3' fill="outline" color='primary' onClick={SaveInterviewQuestions}>Create Interview Questions Set</IonButton>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonToolbar className='xs'>
                            <IonButtons slot="start">
                                <IonButton color='primary' onClick={removeAll}>Remove ALL</IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <hr className='m-0'></hr>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol class='col-lg-6'>
                        <IonReorderGroup onIonItemReorder={reorderQuestions} disabled={false}>
                            {interviewSet?.interviewQuestions.length > 0 && interviewSet.interviewQuestions.map((interviewQuestion: any, index: any) => {
                                return (
                                    <IonItem key={interviewQuestion._id}>
                                        {/* <IonLabel className="w-25">{holiday.holidayDate}
                                            {holiday.optional ? <IonBadge className="mb-2" color="warning">Optional</IonBadge> : null}
                                        </IonLabel> */}
                                        <IonReorder slot="start">
                                            <IonIcon icon={reorderFourOutline} />
                                        </IonReorder>
                                        <IonLabel className="w-75"> <IonText> {interviewQuestion.question}</IonText></IonLabel>
                                        <IonButton color='warning' onClick={() => removeQuestion(interviewQuestion)} style={{ marginRight: '0.5rem' }}>Remove</IonButton>


                                        {/* <IonIcon icon={trashOutline} onClick={() => deleteHoliday(holiday.holidayName)}></IonIcon> */}
                                    </IonItem>
                                );
                            })}
                        </IonReorderGroup>
                    </IonCol>

                    <IonCol class='col-lg-6'>
                        <div className=''>Filter Based on Skills</div>
                        <CreatableSelect
                            className='py-2'
                            styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            placeholder="Select Tools and Technologies"
                            options={[...LanguageTech.languages]}
                            isMulti={true}
                            // defaultValue={[...interview.languagesTools]}
                            onChange={onToolsChange}
                        />
                        {fetchedInterviewQuestions?.questions && fetchedInterviewQuestions.questions.map((interviewQuestion: any, index: any) => {
                            return (
                                <IonItem key={interviewQuestion._id}>
                                    {/* <IonLabel className="w-25">{holiday.holidayDate}
                                            {holiday.optional ? <IonBadge className="mb-2" color="warning">Optional</IonBadge> : null}
                                        </IonLabel> */}
                                    <IonButton onClick={() => selectQuestion(interviewQuestion)} style={{ marginRight: '0.5rem' }}>Pick</IonButton>

                                    <IonLabel className="w-75">{interviewQuestion.question}</IonLabel>
                                    {/* <IonIcon icon={trashOutline} onClick={() => deleteHoliday(holiday.holidayName)}></IonIcon> */}
                                </IonItem>
                            );
                        })}
                    </IonCol>
                </IonRow>
            </IonItemGroup>
        </IonContent >
    );
};

export default CreateInterviewQuestionsSet;
