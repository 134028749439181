import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useLocation, useParams } from 'react-router';
import CreateOrganization from '../../components/CreateOrganization';

const CreateOrganizationPage: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  let orgPageTitle = '';
  console.log(name);
  const location = useLocation();
  console.log(location.pathname);
  if (location.pathname.includes("create-supplier")) {
    orgPageTitle = "Supplier";
  } else if (location.pathname.includes("create-buyer")) {
    orgPageTitle = "Buyer";
  } else {
    orgPageTitle = "organization";
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>My {orgPageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">Create {orgPageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <CreateOrganization name={orgPageTitle} />
      </IonContent>
    </IonPage>
  );
};

export default CreateOrganizationPage;
