import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { createOutline, pin, walk, warning, wifi, wine } from 'ionicons/icons';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ExploreContainer from '../../components/ExploreContainer';
import { CustomRoutes } from '../../utils/CustomRoutes';

const SuperAdminIndex: React.FC = () => {
  const { name } = useParams<{ name: string; }>();
  const history = useHistory();

  const routeChange = (path: string) => {
    history.push(path);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-md="4" size-sm="6" size-xs="12">
              <IonCard onClick={() => { routeChange(CustomRoutes.createEmployee) }}>
                <IonCardContent className="d-flex justify-content-center my-5 mx-3">
                  <IonIcon className="w-25" size='large' color="primary" icon={createOutline} slot="start" />
                  <IonLabel className="w-75 pt-1" style={{ fontSize: '18px' }} color="primary"> Create Employees</IonLabel>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size-md="4" size-sm="6" size-xs="12">
              <IonCard onClick={() => { routeChange(CustomRoutes.dashboardHolidays) }}>
                <IonCardContent className="d-flex justify-content-center my-5 mx-3">
                  <IonIcon className="w-25" size='large' color="primary" icon={createOutline} slot="start" />
                  <IonLabel className="w-75 pt-1" style={{ fontSize: '18px' }} color="primary"> Create Holidays</IonLabel>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size-md="4" size-sm="6" size-xs="12">
              <IonCard onClick={() => { routeChange(CustomRoutes.dashboardHolidays) }}>
                <IonCardContent className="d-flex justify-content-center my-5 mx-3">
                  <IonIcon className="w-25" size='large' color="primary" icon={createOutline} slot="start" />
                  <IonLabel className="w-75 pt-1" style={{ fontSize: '18px' }} color="primary"> Create Project Task</IonLabel>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size-xs="12">ion-col</IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default SuperAdminIndex;