import { IonChip, IonIcon, IonLabel, IonText, IonTitle } from "@ionic/react";
import { logOutOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { Col, Container, Row, Spinner } from "reactstrap";

const LoginComponent: React.FC = () => {
  const history = useHistory();
  const handleLogout = () => {
    sessionStorage.clear();
    history.push('/login');
  };
  return (
    <>
      <IonIcon
        icon={logOutOutline}
        onClick={handleLogout}
        style={{ fontSize: '24px', cursor: 'pointer' }}
      />
      <IonLabel
        style={{ marginRight: '1em', cursor: 'pointer' }}
        onClick={handleLogout}>
        Log Out
      </IonLabel>
    </>
  );
};

export default LoginComponent;