import { IonAvatar, IonButton, IonCard, IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonRow, IonTitle } from '@ionic/react';
import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { calendar, createOutline } from 'ionicons/icons';
import { Virtuoso } from 'react-virtuoso';
interface ContainerProps {
    name: string;
    getCostCenter: any;
}

const SelectCostCenter: React.FC<ContainerProps> = ({ name, getCostCenter }) => {
    const [popoverDate, setPopoverDate] = useState(name);
    const changeDate = (value: string) => {
        setPopoverDate(formatDate(value));
        getCostCenter(popoverDate);
    }
    const formatDate = (value: string) => {
        return format(parseISO(value), 'MMM dd yyyy');
    };

    const [searchUser, setSearchUser] = useState({
        firstName: '',
        lastName: '',
        employeeID: '',
    });

    const handleInputChange = (event: any) => {
        setSearchUser((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    return (
        <>
            <div className="mt-4 text-decoration-none">
                <IonModal trigger="select-cost-center-panel">
                    <IonContent>
                        <IonGrid>
                            <IonRow>
                                <IonItemDivider color="primary">
                                    <IonTitle className="my-3">{name}</IonTitle>
                                </IonItemDivider>
                            </IonRow>
                            <IonRow>
                                <IonCol size="4">
                                    <IonItem>
                                        <IonLabel position="floating">First Name</IonLabel>
                                        <IonInput name="firstName" onIonInput={handleInputChange}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="4">
                                    <IonItem>
                                        <IonLabel position="floating">Last Name</IonLabel>
                                        <IonInput name="firstName" onIonInput={handleInputChange}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="4">
                                    <IonItem>
                                        <IonLabel position="floating">User ID</IonLabel>
                                        <IonInput name="firstName" onIonInput={handleInputChange}></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <Virtuoso
                                    style={{ height: '100%' }}
                                    totalCount={100}
                                    itemContent={(index) => {
                                        return (
                                            <div style={{ height: '56px' }}>
                                                <IonItem>
                                                    <IonAvatar slot="start">
                                                        <img src="https://picsum.photos/seed/picsum/40/40" />
                                                    </IonAvatar>
                                                    <IonLabel>{index}</IonLabel>
                                                </IonItem>
                                            </div>
                                        );
                                    }}
                                />
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonModal>
                <IonChip color="primary" id="select-cost-center-panel" style={{ width: '100%' }}>
                    <IonLabel className="d-flex justify-content-center">{popoverDate}</IonLabel>
                </IonChip>
            </div>
        </>
    );
};

export default SelectCostCenter;
