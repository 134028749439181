import { IonButton, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonRow } from '@ionic/react';
//import './ExploreContainer.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ReactPlayer from 'react-player'
import useAxios from 'axios-hooks';
import ComponentLoading from '../common/ComponentLoading';
import API from '../../utils/API';
import { ToasterMessage } from '../../utils/ToasterMessages';
import Toaster from '../../utils/Toaster';
import { CustomRoutes } from '../../utils/CustomRoutes';
interface ContainerProps {
    name: string;
}

const WatchInterviews: React.FC<ContainerProps> = ({ name }) => {
    const [interviewData, setInterviewData] = useState<any>();
    const history = useHistory();
    const [showToaster] = Toaster();
    const params: any = useParams();
    const interviewID = params?.interviewID;
    console.log(interviewID);
    const [state, setState] = useState(Date.now());

    const [{ data, loading, error }, refetch] = useAxios({
        url: '/interview/getInterviewByID', method: 'POST', data: {
            "req": {
                "id": interviewID
            }
        }
    });

    const changeInterviewStatus = (status) => {
        API().post('/interview/updateInterviewStatus', {
            req: {
                id: interviewID,
                status: status
            }
        }).then((data) => {
            showToaster(ToasterMessage.interview.changedStatusSuccess + " " + status, 'success', 'top');
            history.push(CustomRoutes.viewInterview);
        })
    }
    useEffect(() => {
        if (data) {
            setInterviewData(data);
        }
        setState(Date.now());
    }, [data]);

    if (loading) {
        return (<ComponentLoading show></ComponentLoading>)
    }

    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol size-sm="12" size-xs="12">
                        <IonCard>

                            <IonCardContent>
                                <IonCardTitle>
                                    Name: {interviewData?.candidateName}
                                    <IonButton disabled={interviewData?.status === ToasterMessage.interviewStatus.SHORTLISTED} color='warning' onClick={() => changeInterviewStatus('shortlisted')} className='ion-float-right'>ShortList</IonButton>
                                    <IonButton disabled={interviewData?.status === ToasterMessage.interviewStatus.REJECTED} color='danger' onClick={() => changeInterviewStatus('rejected')} className='ion-float-right'>Reject</IonButton>
                                    <IonButton disabled={interviewData?.status === ToasterMessage.interviewStatus.APPROVED} color='success' onClick={() => changeInterviewStatus('approved')} className='ion-float-right'>Approve</IonButton>
                                </IonCardTitle>
                                <IonCardSubtitle>
                                    Email: {interviewData?.candidateEmail}
                                </IonCardSubtitle>
                                <IonCardSubtitle>
                                    Role: {interviewData?.jobRole}
                                </IonCardSubtitle>
                                <IonCardSubtitle>
                                    Job Description: {interviewData?.jobDescription}
                                </IonCardSubtitle>
                            </IonCardContent>

                        </IonCard>
                        <IonRow>
                            {
                                interviewData && interviewData?.interviewQuestions.map((interviewQuestion: any) => {
                                    return (
                                        <IonCol key={interviewQuestion._id} sizeLg="4" sizeMd='6' sizeSm='12' sizeXs='12'>
                                            <IonCard >
                                                <IonCardContent>
                                                    <IonHeader style={{ height: '80px' }}>
                                                        <IonCardTitle>
                                                            {interviewQuestion.question}
                                                        </IonCardTitle>
                                                    </IonHeader>
                                                    <ReactPlayer pip={true} width="100%" controls={true} url={interviewQuestion.videoURL} />
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    )
                                })
                            }
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent >
    );
};

export default WatchInterviews;
