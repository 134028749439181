import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import CreateOrganization from '../../components/CreateOrganization';
import ViewAllEmployees from '../../components/employees/ViewAllEmployees';

const ViewAllEmployeesPage: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Create Employee</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ViewAllEmployees name={'View All Employees'}></ViewAllEmployees>
      </IonContent>
    </IonPage>
  );
};

export default ViewAllEmployeesPage;
