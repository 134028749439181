import { IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory, useLocation, useParams } from 'react-router';
import ViewInterview from '../../components/interview/ViewInterview';
import ViewInterviewV2 from '../../components/interview/ViewInterviewV2';
import WatchInterviews from '../../components/interview/WatchInterviews';
import { CustomRoutes } from '../../utils/CustomRoutes';
import AddQuestion from '../../components/interview/AddQuestion';
import ViewAllGlobalQuestionSets from '../../components/interview/ViewAllGlobalQuestionsSets';
import LoginComponent from '../../components/common/LoginComponent';

const ViewAllGlobalQuestionSetsPage: React.FC = () => {
  let orgPageTitle = "All Global Questions Sets"
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            {orgPageTitle}
          </IonTitle>
          <IonButtons slot='end'>
            <LoginComponent></LoginComponent>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{orgPageTitle}</IonTitle>
            {/* <IonButton>View Interviews</IonButton> */}
          </IonToolbar>
        </IonHeader>
        <ViewAllGlobalQuestionSets></ViewAllGlobalQuestionSets>
      </IonContent>
    </IonPage>
  );
};

export default ViewAllGlobalQuestionSetsPage;
