import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { createOutline, pin, walk, warning, wifi, wine } from 'ionicons/icons';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ExploreContainer from '../../components/ExploreContainer';

const SuperAdminIndex: React.FC = () => {
  const { name } = useParams<{ name: string; }>();
  const history = useHistory();
  
  const routeChange = () =>{ 
    let path = `/super-admin/create-new-organization`; 
    history.push(path);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard onClick={routeChange}>
                <IonItem className="d-flex justify-content-center my-5 mx-5">
                  <IonIcon color="primary" icon={createOutline} slot="start" />
                  <IonLabel color="primary">Create New Organization</IonLabel>
                </IonItem>
              </IonCard>
            </IonCol>
            <IonCol>ion-col</IonCol>
            <IonCol>ion-col</IonCol>
            <IonCol>ion-col</IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default SuperAdminIndex;